import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LoadingBlueLarge } from 'merchant-portal-components';

const Header = styled.h1`
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 15px;
  margin-top: 25px;
`;
const SubTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
`;

const Icon = styled.i`
  margin: 0;
  margin-left: 10px;
  color: #2440ae;
  font-size: 25px;
`;

export const ChargeBackOverview = () => {
  return (
    <>
      <Header>Chargeback FAQs</Header>
      <Title>What is a chargeback?</Title>
      <SubTitle>
        A chargeback occurs when a customer disputes a transaction with their
        credit card issuer (Visa, Mastercard, etc.). The issuer reviews the
        claim and may reverse the charge if the dispute is found valid.
      </SubTitle>
      <Title>Why do chargebacks happen?</Title>
      <SubTitle>
        Chargebacks can occur for various reasons, including fraudulent
        transactions, billing errors, or customer dissatisfaction. Each network
        has specific guidelines that outline when a chargeback is valid.
      </SubTitle>
      <Title>What is the merchant’s responsibility?</Title>
      <SubTitle>
        Merchants are responsible for managing disputes by providing supporting
        documentation to contest invalid chargebacks. If you need help
        understanding the process, visit our{' '}
        <a
          href='https://kb.smooth.tech/knowledge/understanding-chargebacks-a-comprehensive-guide-for-merchants'
          target='_blank'
          rel='noopener noreferrer'
        >
          Knowledge Base
        </a>{' '}
        for detailed guidance.
      </SubTitle>
      <Title>How can Smooth Commerce help?</Title>
      <SubTitle>
        Smooth Commerce can integrate transaction logs and user profiles with
        chargeback data to help merchants manage disputes effectively. If your
        payment acquirer supports chargeback data integration, reach out to them
        or contact our team for more information.
      </SubTitle>
    </>
  );
};
