import {
  UPDATE_MULTI_POS_SELECTED_CATEGORY,
  UPDATE_MULTI_POS_SELECTED_ITEM,
  GET_MULTI_POS_ITEMS,
  UPDATE_MULTI_POS_TYPES,
  UPDATE_SELECTED_POS_TYPE_TAB,
  GET_MULTI_POS_MAPPING_FIELDS,
  GET_MULTI_POS_MAPPING_PRODUCT,
  GET_MULTI_POS_MAPPING_ITEM,
  GET_MULTI_POS_MAPPING_COMBO,
  GET_MULTI_POS_BUSINESSES,
  UPDATE_MULTI_POS_REQUEST_PARAMS,
  UPDATE_MULTI_POS_DETAIL_TAB,
  UPDATE_IS_EDITING_MULTI_POS,
  GET_MULTI_POS_MAPPING_SUBITEM,
  RESET_CATEGORY_OPTION,
  RESET_CATEGORY_SUBITEM,
} from './multiPOSTypes';

const currentPOSTypes =
  localStorage.getItem('multiPOSTypes') &&
  Object.keys(JSON.parse(localStorage.getItem('multiPOSTypes')));

const initialState = {
  posItems: [],
  posSubItems: [],
  posTypes: null,
  posBusinesses: [],
  selectedCategory: null,
  selectedPOSType: currentPOSTypes?.[0] ?? '',
  selectedDetailTab: 0,
  selectedItem: null,
  selectedSubitem: null,
  selectedPOSMappingFields: [],
  defaultPOSMappingsForm: null,
  overridePOSMappingsForm: null,
  posMappingFields: [],
  originalPOSMappingOverride: [],
  originalPOSMappingOverride: [],
  isLoading: false,
  isFormDirty: false,
  isOverrideDirty: false,
  requestParams: {
    page_number: 1,
    page_size: 25,
    search_string: '',
    has_next_page: false,
  },
  isEditing: false,
  isKeepModalOpen: false,
  pendingAction: null,
};

export function multiPOSReducer(state = initialState, action) {
  switch (action.type) {
    case 'IS_LOADING_MULTI_POS_SELECTED_ITEM':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case 'RESET_MULTI_POS_STATE':
      const types = JSON.parse(localStorage.getItem('multiPOSTypes'));
      const selectedPOSType =
        types &&
        Object.entries(types).find(([, value]) => value.is_default)?.[0];
      return {
        ...initialState,
        selectedPOSType,
      };
    case 'UPDATE_FORM_IS_DIRTY':
      return {
        ...state,
        isFormDirty: action.isFormDirty,
      };
    case 'SET_PENDING_ACTION':
      return {
        ...state,
        pendingAction: action.pendingAction,
      };
    case 'UPDATE_KEEP_MODAL_OPEN':
      return {
        ...state,
        isKeepModalOpen: action.isKeepModalOpen,
      };
    case RESET_CATEGORY_SUBITEM:
      return {
        ...state,
        posSubItems: [],
        selectedSubitem: null,
      };
    case RESET_CATEGORY_OPTION:
      return {
        ...state,
        posItems: [],
        posSubItems: [],
        posBusinesses: [],
        selectedDetailTab: 0,
        selectedItem: null,
        selectedSubitem: null,
        selectedPOSMappingFields: [],
        defaultPOSMappingsForm: null,
        overridePOSMappingsForm: null,
        posMappingFields: [],
        originalPOSMappingOverride: [],
        originalPOSMappingOverride: [],
        isLoading: false,
        isFormDirty: false,
        isOverrideDirty: false,
        requestParams: {
          page_number: 1,
          page_size: 25,
          search_string: '',
          has_next_page: false,
        },
        isEditing: false,
      };
    case UPDATE_MULTI_POS_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.selectedCategory,
      };
    case UPDATE_IS_EDITING_MULTI_POS:
      return {
        ...state,
        isEditing: action.isEditing,
      };
    case UPDATE_MULTI_POS_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case GET_MULTI_POS_ITEMS:
      return {
        ...state,
        posItems: action.posItems,
      };
    case UPDATE_MULTI_POS_DETAIL_TAB:
      return {
        ...state,
        selectedDetailTab: action.selectedDetailTab,
      };
    case UPDATE_MULTI_POS_TYPES:
      return {
        ...state,
        posTypes: action.posTypes,
      };
    case GET_MULTI_POS_BUSINESSES:
      return {
        ...state,
        posBusinesses: action.posBusinesses,
      };
    case UPDATE_SELECTED_POS_TYPE_TAB:
      return {
        ...state,
        selectedPOSType: action.selectedPOSType,
      };
    case GET_MULTI_POS_MAPPING_FIELDS:
      return {
        ...state,
        selectedPOSMappingFields: action.selectedPOSMappingFields,
      };
    case UPDATE_MULTI_POS_REQUEST_PARAMS:
      return {
        ...state,
        requestParams: action.requestParams,
      };
    case GET_MULTI_POS_MAPPING_SUBITEM:
      return {
        ...state,
        posSubItems: action.posSubItems,
      };
    case GET_MULTI_POS_MAPPING_PRODUCT:
      return {
        ...state,
        defaultPOSMappingsForm: action.defaultPOSMappingsForm,
        overridePOSMappingsForm: action.overridePOSMappingsForm,
        originalPOSMappingOverride: action.originalPOSMappingOverride,
      };
    case GET_MULTI_POS_MAPPING_COMBO:
      return {
        ...state,
        defaultPOSMappingsForm: action.defaultPOSMappingsForm,
        overridePOSMappingsForm: action.overridePOSMappingsForm,
        originalPOSMappingOverride: action.originalPOSMappingOverride,
      };
    case GET_MULTI_POS_MAPPING_ITEM:
      return {
        ...state,
        defaultPOSMappingsForm: action.defaultPOSMappingsForm,
        overridePOSMappingsForm: action.overridePOSMappingsForm,
        originalPOSMappingOverride: action.originalPOSMappingOverride,
        selectedSubitem: action.selectedSubitem,
      };

    default:
      return state;
  }
}
