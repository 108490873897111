import {
  CLEAR_ALL_ORDER_SUMMARIES,
  GET_ALL_ORDER_SUMMARY_SUCCESSFUL,
  UPDATE_SUMMARY_REPORT_REQUEST_PARAMS,
  HIDE_TEMPORARY_SUMMARY_REPORT_BANNER,
} from './summaryReportTypes';

export default function (
  state = {
    allOrderSummaries: null,
    isFetchingSettlements: false,
    response: null,
    temporaryShowSummaryReportBanner: true,
    requestParams: {
      page_number: 1,
      page_size: '25',
      sorting_option: 'title-asc',
    },
  },
  action
) {
  switch (action.type) {
    case HIDE_TEMPORARY_SUMMARY_REPORT_BANNER:
      return {
        ...state,
        temporaryShowSummaryReportBanner:
          action.temporaryShowSummaryReportBanner,
      };
    case 'UPDATE_SUMMARY_REPORT_FILTER_REQUEST_PARAMS':
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case CLEAR_ALL_ORDER_SUMMARIES:
      return { ...state, allOrderSummaries: action.allOrderSummaries };
    case GET_ALL_ORDER_SUMMARY_SUCCESSFUL:
      return { ...state, allOrderSummaries: action.allOrderSummaries };
    case UPDATE_SUMMARY_REPORT_REQUEST_PARAMS:
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
