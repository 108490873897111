import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Select } from 'merchant-portal-components';
import { renderMultiMappingOptions } from '../utils/constants';
import {
  updateSelectedMultiPOSCategory,
  getMultiPOSMappingFields,
  getMultiPOSProducts,
  getMultiPOSCombos,
  getMultiPOSOffers,
  getMultiPOSCoupons,
  getMultiPOSItems,
  updateSelectedMultiPOSItem,
  restOptions,
  handlePendingAction,
} from '../multiPOSActions';
import { useMultiPOS } from '../../../../hooks/use-multi-pos';
const CategorySelectComponent = ({
  selectedCategory,
  updateSelectedMultiPOSCategory,
  getMultiPOSProducts,
  getMultiPOSItems,
  getMultiPOSCombos,
  getMultiPOSOffers,
  getMultiPOSCoupons,
  getMultiPOSMappingFields,
  restOptions,
  // handlePendingAction,
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const { handleCategorySelection } = useMultiPOS();

  return (
    <Select
      open={isSelectOpen}
      options={renderMultiMappingOptions()}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      onOpen={(e) => {
        e.stopPropagation();
        setIsSelectOpen(true);
      }}
      onChange={(option) => {
        handleCategorySelection(
          option,
          updateSelectedMultiPOSCategory,
          getMultiPOSMappingFields,
          getMultiPOSProducts,
          getMultiPOSItems,
          getMultiPOSCombos,
          getMultiPOSOffers,
          getMultiPOSCoupons,
          restOptions
        );
      }}
      value={selectedCategory}
      valueKey='id'
      required
      placeholder='Select category'
    />
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
});
export const CategorySelect = connect(mapStateToProps, {
  updateSelectedMultiPOSCategory,
  getMultiPOSMappingFields,
  getMultiPOSProducts,
  getMultiPOSItems,
  getMultiPOSCombos,
  getMultiPOSOffers,
  getMultiPOSCoupons,
  updateSelectedMultiPOSItem,
  restOptions,
  handlePendingAction,
})(CategorySelectComponent);
