import api from '../../api';
import {
  CLEAR_ALL_ORDER_SUMMARIES,
  GET_ALL_ORDER_SUMMARY_SUCCESSFUL,
  HIDE_TEMPORARY_SUMMARY_REPORT_BANNER,
} from './summaryReportTypes';

import { createAlert } from '../Alert/actions';
import { formatErrorMessage } from '../../utils/formatErrorMessage';

export function isTemporarySummaryReportBannerDisplayed(statue) {
  return {
    type: HIDE_TEMPORARY_SUMMARY_REPORT_BANNER,
    temporaryShowSummaryReportBanner: statue,
  };
}
export function updateRequestParams(requestParams) {
  return {
    type: 'UPDATE_SUMMARY_REPORT_REQUEST_PARAMS',
    payload: requestParams,
  };
}
export function clearAllOrderSummaries() {
  return {
    type: CLEAR_ALL_ORDER_SUMMARIES,
    allOrderSummaries: null,
  };
}
export function getOrderSummaries(
  startdate,
  endDate,
  businessIDs,
  sources,
  order_types,
  payment_types
) {
  const modifiedSources = sources.map((source) =>
    source === 'generic' ? 'scan' : source
  );
  return function (dispatch) {
    return api
      .post(`/report/transaction-summary`, {
        business_ids: businessIDs,
        start_date: startdate,
        end_date: endDate,
        sources: modifiedSources,
        order_types: order_types,
        payment_types: payment_types,
      })
      .then((response) => {
        dispatch({
          type: GET_ALL_ORDER_SUMMARY_SUCCESSFUL,
          allOrderSummaries: response.data.data,
        });
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message:
              error?.response?.data?.errors?.message ||
              'An error occurred while trying to transation summary report.',
            message:
              formatErrorMessage(error) ||
              'An error occurred while trying to transation summary report.',
            dangerouslySetInnerHTML: true,
          })
        );
      });
  };
}
