import React, { useEffect, memo, useCallback, useState, useRef } from 'react';
import Box from '@material-ui/core//Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import SlideOne from '../../SlideOne/SlideOne';
import SlideTwo from '../../SlideTwo/SlideTwo.js';
import SlideThree from '../../SlideThree/SlideThree.js';
import SlideFour from '../../SlideFour/SlideFour';
import { productAvailableAllTime } from '../../utils/helper.js';
import { isProductAvailableAllTimeFunction } from '../../utils/helper.js';
import moment from 'moment';
import zIndex from '@material-ui/core/styles/zIndex.js';
const StepContainer = styled.div`
  display: block;
  max-width: 740px;
  margin: 0 auto;

  .stepperMain {
    background: none !important;
  }

  .MuiStepIcon-root {
    color: transparent;
    display: block;
    border: 1px solid #000;
    border-radius: 100px;
    width: 45px;
    height: 45px;
  }
  .MuiStepIcon-text {
    fill: #000;
  }
  .MuiStepIcon-active .MuiStepIcon-text {
    fill: #fff;
  }
  .MuiStepConnector-alternativeLabel {
    top: 22px;
    left: calc(-50% + 24px);
    right: calc(50% + 24px);
    position: absolute;
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
  .btnNext {
    width: 160px;
    height: 48px;
    padding: 14px 15px 14px 15px;
    border-radius: 28px;
    background: #2440ae !important;
    color: #fff;
  }
  .btnNext.Mui-disabled {
    background: #ccc !important;
  }
  .noBack {
    background: none !important;
    color: #191818;
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize;
  }
`;

const steps = [
  'Basic Information',
  'Requirements and Rewards',
  'Audience and Visibility',
  'Availability and Activation',
];

const MainStepper = ({
  role,
  values,
  setFieldValue,
  initialValues,
  handleChange,
  customerList,
  offerAvailabilities,
  segmentList,
  businessList,
  offerEngineSource,
  offerEngineProducts,
  dirty,
  errors,
  touched,
  handleBlur,
  conditionValue,
  createOffer,
  offerPromoCode,
  getPromos,
  updatePromoRequestParams,
  requestParamsPromo,
  history,
  allProductVariants,
  orderTypesList,
  loyaltyTiers,
}) => {
  const topViewRef = useRef(null);
  useEffect(() => {
    if (offerPromoCode?.length > 0) {
      setFieldValue('offerPromoCode', offerPromoCode);
    }
  }, [offerPromoCode]);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [isConsumerVisible, setIsConsumerVisible] = React.useState(false);
  const [isSegmentVisible, setIsSegmentVisible] = React.useState(false);

  useEffect(() => {
    topViewRef.current.scrollIntoView({ behavior: 'smooth', top: -100 });
  }, [activeStep]);

  const [audienceType, setAudienceType] = React.useState(
    values.offer_details.segments.length > 0
      ? 'segments'
      : values.customerList.length > 0
      ? 'customers'
      : 'all'
  );
  const [isMobileRequire, setisMobileRequire] = React.useState(
    values.conditionValue.filter((e) => e.source === 'mobile').length > 0
      ? true
      : false
  );
  const [isWebRequire, setisWebRequire] = React.useState(
    values.conditionValue.filter((e) => e.source === 'web').length > 0
      ? true
      : false
  );
  const [isScanRequire, setisScanRequire] = React.useState(
    values.conditionValue.filter((e) => !['web', 'mobile'].includes(e.source))
      .length > 0
      ? true
      : false
  );

  useEffect(() => {
    console.log('values', values);
    if (values?.offer_details?.offer_id) {
      getPromos(requestParamsPromo, values.offer_details.offer_id);
      updatePromoRequestParams(requestParamsPromo);
    }
  }, []);
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((_, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    console.log('step', step);
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setFieldValue('offer_details.title', '');
    setFieldValue('offer_details.subtitle', '');
    setFieldValue('offer_details.label', '');
    setFieldValue('offer_details.description', '');
    setFieldValue('offer_details.image_url', '');
  };
  const updateNextButtonStatus = useCallback(() => {
    let isDisabled = true;

    if (activeStep === 0) {
      const hasError = [
        'title',
        'subtitle',
        'label',
        'description',
        'image_url',
      ].some(
        (field) =>
          errors?.offer_details &&
          Object.keys(errors?.offer_details).includes(field)
      );
      const isEmpty =
        !values?.offer_details?.title ||
        !values?.offer_details?.label ||
        !values?.offer_details?.image_url;
      isDisabled = hasError || isEmpty;
    } else if (activeStep === 1) {
      const hasError = ['conditionValue', 'template_name'].some(
        (field) =>
          errors &&
          (Object.keys(errors).includes(field) ||
            (errors?.offer_details &&
              Object.keys(errors?.offer_details).includes(field)))
      );
      let freeItemError = false;
      if (values.offer_details?.template_name === 'free-item') {
        values.conditionValue.forEach((condition) => {
          if (condition?.reward?.products?.length === 0) {
            freeItemError = true;
          }
        });
      }
      // find if pos list is empty
      let posRequirementError = false;
      const posSourceIndex = values.conditionValue?.findIndex(
        (condition) => !['web', 'mobile'].includes(condition.source)
      );
      if (posSourceIndex >= 0) {
        const posProductIndex = values.conditionValue[
          posSourceIndex
        ]?.requirements.findIndex((reqObj) => 'product' in reqObj);
        if (posProductIndex >= 0) {
          posRequirementError =
            values.conditionValue[posSourceIndex]?.requirements?.length > 0 &&
            values.conditionValue[posSourceIndex]?.requirements[posProductIndex]
              ?.product?.products?.length === 0;
        }
      }

      // find if we need to add tiers
      let loyaltyError = false;
      const tierIndex =
        values.conditionValue[0]?.requirements?.length > 0
          ? values.conditionValue[0]?.requirements?.findIndex(
              (x) => x['tier'] != undefined
            )
          : 0;
      if (
        tierIndex &&
        values.conditionValue[0].requirements?.filter((req) => 'tire' in req) &&
        values.conditionValue[0].requirements[tierIndex]?.tier.length === 0
      ) {
        loyaltyError = true;
      } else {
        loyaltyError === false;
      }
      isDisabled =
        hasError || freeItemError || posRequirementError || loyaltyError;
    } else if (activeStep === 2) {
      const hasError = ['segments', 'customerList'].some(
        (field) => errors && Object.keys(errors).includes(field)
      );
      isDisabled = hasError;
    } else {
      const hasError = ['start_date', 'end_date'].some(
        (field) =>
          errors &&
          (Object.keys(errors).includes(field) ||
            (errors?.offer_details &&
              Object.keys(errors?.offer_details).includes(field)))
      );
      let endDateIsBeforeStartDate = false;
      if (values.offer_details.start_date && values.offer_details.end_date) {
        endDateIsBeforeStartDate = moment(
          values.offer_details.end_date
        ).isBefore(values.offer_details.start_date);
      }
      isDisabled = hasError || endDateIsBeforeStartDate;
    }
    setIsNextButtonDisabled(isDisabled);
  }, [activeStep, values, errors]);
  useEffect(() => {
    updateNextButtonStatus();
  }, [updateNextButtonStatus]);
  const handleSubmitOffer = (values) => {
    let newOffer = { ...values };
    const newConditions = values.conditionValue.reduce((acc, cur) => {
      let newConditionvalue = { ...cur };
      if (
        cur.source !== 'web' &&
        cur.source !== 'mobile' &&
        cur.requirements?.length > 0 &&
        cur.requirements[0]?.product?.products?.length > 0
      ) {
        newConditionvalue.requirements[0].product.products =
          cur.requirements[0].product?.products.map((product) => {
            return { product_id: String(product?.product_id).trim() };
          });
      }
      if (
        cur.source !== 'web' &&
        cur.source !== 'mobile' &&
        cur.reward?.products?.length > 0
      ) {
        newConditionvalue.reward.products = cur.reward?.products.map(
          (product) => {
            return { product_id: String(product?.product_id).trim() };
          }
        );
      }
      acc.push(newConditionvalue);
      return acc;
    }, []);
    newOffer.conditionValue = newConditions;
    createOffer(newOffer);
  };
  return (
    <StepContainer>
      <Box sx={{ width: '100%' }} ref={topViewRef}>
        <Stepper
          className='stepperMain'
          alternativeLabel
          activeStep={activeStep}
        >
          {steps.map((label, index) => (
            <Step key={label} onClick={() => setActiveStep(index)}>
              <StepButton
                color='inherit'
                sx={{ zIndex: 1000, cursor: 'pointer' }}
                completed={false}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && (
                <SlideOne
                  values={values}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  handleChange={handleChange}
                  dirty={dirty}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                />
              )}
              {activeStep === 2 && (
                <SlideThree
                  role={role}
                  values={values}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  isSegmentVisible={isSegmentVisible}
                  setIsSegmentVisible={setIsSegmentVisible}
                  isConsumerVisible={isConsumerVisible}
                  setIsConsumerVisible={setIsConsumerVisible}
                  customerList={customerList}
                  segmentList={segmentList}
                  businessList={businessList}
                  audienceType={audienceType}
                  setAudienceType={setAudienceType}
                  history={history}
                  errors={errors}
                  orderTypesList={orderTypesList}
                />
              )}
              {activeStep === 1 && (
                <SlideTwo
                  values={values}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  isSegmentVisible={isSegmentVisible}
                  setIsSegmentVisible={setIsSegmentVisible}
                  isConsumerVisible={isConsumerVisible}
                  setIsConsumerVisible={setIsConsumerVisible}
                  isMobileRequire={isMobileRequire}
                  setisMobileRequire={setisMobileRequire}
                  isWebRequire={isWebRequire}
                  setisWebRequire={setisWebRequire}
                  isScanRequire={isScanRequire}
                  setisScanRequire={setisScanRequire}
                  offerEngineSource={offerEngineSource}
                  offerEngineProducts={offerEngineProducts}
                  conditionValue={conditionValue}
                  allProductVariants={allProductVariants}
                  errors={errors}
                  loyaltyTiers={loyaltyTiers}
                />
              )}
              {activeStep === 3 && (
                <SlideFour
                  role={role}
                  values={values}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  isSegmentVisible={isSegmentVisible}
                  setIsSegmentVisible={setIsSegmentVisible}
                  isConsumerVisible={isConsumerVisible}
                  setIsConsumerVisible={setIsConsumerVisible}
                  offerAvailabilities={offerAvailabilities}
                  offerPromoCode={offerPromoCode}
                  getPromos={getPromos}
                  updatePromoRequestParams={updatePromoRequestParams}
                  requestParamsPromo={requestParamsPromo}
                  errors={errors}
                />
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                {activeStep === 0 && (
                  <Button
                    className='noBack'
                    onClick={handleReset}
                    sx={{ mr: 1 }}
                    type='reset'
                  >
                    Cancel
                  </Button>
                )}
                {activeStep !== 0 && (
                  <Button
                    className='noBack'
                    color='inherit'
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                )}
                <Box sx={{ flex: '1 1 auto' }} />
                {activeStep !== 3 && (
                  <Button
                    className='btnNext'
                    onClick={handleNext}
                    sx={{ mr: 1 }}
                    disabled={isNextButtonDisabled}
                  >
                    Next
                  </Button>
                )}
                {activeStep == 3 && (
                  <Button
                    className='btnNext'
                    onClick={() => handleSubmitOffer(values)}
                    sx={{ mr: 1 }}
                    disabled={isNextButtonDisabled}
                  >
                    {values.offer_details.offer_id ? 'Save' : 'Create Offer'}
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
    </StepContainer>
  );
};
export default memo(MainStepper);
