import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Field, Formik, FieldArray } from 'formik';
import { Box, Popover, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { SaveStickyBar } from './SaveStickyBar';
import {
  addMultiPOSMapping,
  addMultiPOSMappingOverride,
  updateDirty,
  updateIsEditingMultiPOS,
  updateMultiPOSMapping,
  updateMultiPOSMappingOverride,
} from '../multiPOSActions';
import { getFirstColumnTitle, getPOSPayloadType } from '../utils/constants';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import { LoadingBlueLarge } from 'merchant-portal-components';

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `220px repeat(${props.len},1fr) auto`};
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 15px;
`;
const TableRow = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `220px repeat(${props.len},1fr) auto`};
  grid-gap: 20px;
  align-items: flex-start;
  margin-bottom: 10px;
`;
const TableTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
`;
const Subtitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
`;
const POSField = styled.span`
  padding: 20px;
`;
const EditIcon = styled.i`
  cursor: pointer;
`;
const ItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 7px 12px;
`;
const EditTextWrapper = styled.span`
  margin-left: 8px;
`;
const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  font-size: 18px;
`;
const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const MappingsFormComponent = ({
  isLoading,
  initialValues,
  selectedPOSMappingFields,
  selectedPOSType,
  isAdding,
  isUpdating,
  isDefault,
  selectedItem,
  selectedBusinessIds,
  selectedBusinessId,
  overrideAction,
  setScreen,
  addMultiPOSMapping,
  updateMultiPOSMapping,
  addMultiPOSMappingOverride,
  updateMultiPOSMappingOverride,
  isEditing,
  updateIsEditingMultiPOS,
  selectedCategory,
  posItems,
  allVariants,
  selectedSubitem,
  selectedDetailTab,
  updateDirty,
}) => {
  console.log('initialValues', initialValues);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const fieldsHeader = useMemo(() => {
    return [
      { label: getFirstColumnTitle(selectedCategory.name), optional: true },
      ...(Array.isArray(selectedPOSMappingFields?.[selectedPOSType])
        ? selectedPOSMappingFields[selectedPOSType].map((field) => ({
            label: field.label,
            optional: field.optional,
          }))
        : []),
    ];
  }, [selectedPOSMappingFields, selectedPOSType]);

  const len = fieldsHeader.length - 1;
  const variantName = useCallback(
    (variantId) => {
      let variant = '';
      const { name } = selectedCategory;
      if (
        name?.toLowerCase().includes('product') ||
        name?.toLowerCase().includes('item')
      ) {
        const selectedVariant = allVariants.find(
          (variant) => variant.id === variantId
        );
        variant = selectedVariant?.name ?? '';
      } else if (
        name?.toLowerCase().includes('combo') ||
        name?.toLowerCase().includes('offer') ||
        name?.toLowerCase().includes('credit')
      ) {
        const selectedVariant = posItems.find(
          (posItem) => posItem.id === variantId
        );
        variant = selectedVariant?.name ?? '';
      }
      return variant;
    },

    [posItems, allVariants]
  );

  const validateField = (value, field) => {
    if (field.optional === false && !value) {
      return `${field.label} is required`;
    }
    return undefined;
  };

  const handleDiscard = useCallback((resetForm) => {
    resetForm();
  }, []);

  const handleSave = useCallback(
    (values) => {
      const posId = values[0]?.id || null;
      if (isDefault) {
        if (posId) {
          updateMultiPOSMapping(
            values,
            getPOSPayloadType(selectedCategory.name),
            selectedItem.id,
            selectedPOSType,
            selectedSubitem?.id
          );
        } else {
          addMultiPOSMapping(
            values,
            getPOSPayloadType(selectedCategory.name),
            selectedItem.id,
            selectedPOSType,
            selectedSubitem?.id
          );
        }
      } else {
        if (overrideAction === 'add') {
          addMultiPOSMappingOverride(
            values,
            selectedBusinessIds,
            getPOSPayloadType(selectedCategory.name),
            selectedItem.id,
            selectedPOSType,
            setScreen,
            selectedSubitem?.id
          );
        } else {
          updateMultiPOSMappingOverride(
            values,
            selectedBusinessId,
            getPOSPayloadType(selectedCategory.name),
            selectedItem.id,
            selectedPOSType,
            selectedSubitem?.id
          );
        }
      }
    },
    [
      isAdding,
      isDefault,
      isUpdating,
      selectedItem,
      selectedPOSType,
      selectedSubitem,
    ]
  );

  useEffect(() => {
    const ids = initialValues.map((item) => item.id);
    if (ids.length > 0 && ids.every((id) => id === '')) {
      updateIsEditingMultiPOS(true);
    } else {
      updateIsEditingMultiPOS(false);
    }
  }, [initialValues]);

  if (initialValues.length === 0)
    return (
      <MessageWrapper>
        No variants are associated with the selected menu item.
      </MessageWrapper>
    );
  return (
    <>
      <TableHeader len={len}>
        {fieldsHeader?.map((header, index) => (
          <TableTitle key={`${header?.label}-${index}`}>
            {`${header.label} ${!header.optional ? '*' : ''}`}
          </TableTitle>
        ))}
        {(selectedDetailTab !== 2 ||
          (selectedDetailTab === 2 && overrideAction === 'add')) && (
          <>
            <EditIcon
              className='fa fa-ellipsis-v'
              onMouseEnter={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              fullWidth
            >
              <ItemWrapper
                onClick={() => {
                  updateIsEditingMultiPOS(!isEditing);
                  setAnchorEl(null);
                }}
                onMouseLeave={() => setAnchorEl(null)}
              >
                <EditIcon className='fa fa-pencil' />
                <EditTextWrapper>Edit</EditTextWrapper>
              </ItemWrapper>
            </Popover>
          </>
        )}
      </TableHeader>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <LoadingBlueLarge />
        </Box>
      ) : (
        <Formik
          initialValues={initialValues.map((pos) => {
            if (pos.is_addon === '') {
              return {
                ...pos,
                is_addon: false,
              };
            } else {
              return pos;
            }
          })}
          enableReinitialize={true}
        >
          {({ values, errors, resetForm, isValid, dirty, touched }) => (
            <Form>
              {updateDirty(dirty)}
              <FieldArray
                name=''
                render={(arrayHelpers) => (
                  <>
                    {arrayHelpers.form.values?.map((item, index) => (
                      <TableRow key={item.id || index} len={len}>
                        {Object.entries(item)?.map(([key, value], rowIndex) =>
                          rowIndex === 0 ? (
                            <FieldWrapper>
                              <Subtitle key={key}>
                                {variantName(value)}
                              </Subtitle>
                            </FieldWrapper>
                          ) : rowIndex ===
                            Object.entries(item).length - 1 ? null : (
                            <>
                              {!isEditing ? (
                                <SwitchWrapper>
                                  {value === true || value === false ? (
                                    <SmallToggleSwitch
                                      disabled={true}
                                      checked={value}
                                    />
                                  ) : (
                                    <POSField>{value}</POSField>
                                  )}
                                </SwitchWrapper>
                              ) : (
                                <>
                                  {value === true ||
                                  value === false ||
                                  key === 'is_addon' ? (
                                    <SwitchWrapper>
                                      <SmallToggleSwitch
                                        checked={value}
                                        onChange={(e) =>
                                          arrayHelpers.form.setFieldValue(
                                            `[${index}].${key}`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </SwitchWrapper>
                                  ) : (
                                    <>
                                      <Field
                                        name={`[${index}].${key}`}
                                        validate={(value) =>
                                          validateField(value, {
                                            key,
                                            ...selectedPOSMappingFields[
                                              selectedPOSType
                                            ].find(
                                              (field) => field.key === key
                                            ),
                                          })
                                        }
                                      >
                                        {({ field }) => (
                                          <TextField
                                            {...field}
                                            variant='outlined'
                                            fullWidth
                                            error={errors?.[index]?.[key]}
                                            helperText={errors?.[index]?.[key]}
                                          />
                                        )}
                                      </Field>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )
                        )}
                      </TableRow>
                    ))}
                  </>
                )}
              />
              {console.log('values', values)}
              <>
                {dirty && isValid && (
                  <SaveStickyBar
                    handleDiscard={() => handleDiscard(resetForm)}
                    handleSave={() => handleSave(values)}
                  />
                )}
              </>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
  isLoading: state.multiPOSReducer.isLoading,
  selectedDetailTab: state.multiPOSReducer.selectedDetailTab,
  selectedItem: state.multiPOSReducer.selectedItem,
  selectedSubitem: state.multiPOSReducer.selectedSubitem,
  selectedPOSMappingFields: state.multiPOSReducer.selectedPOSMappingFields,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  isEditing: state.multiPOSReducer.isEditing,
  posItems: state.multiPOSReducer.posItems,
  allVariants: state.variantsReducer.allVariants,
});

export const MappingForm = connect(mapStateToProps, {
  addMultiPOSMapping,
  updateMultiPOSMapping,
  addMultiPOSMappingOverride,
  updateMultiPOSMappingOverride,
  updateIsEditingMultiPOS,
  updateDirty,
})(React.memo(MappingsFormComponent));
