import {
  UPDATE_MULTI_POS_SELECTED_CATEGORY,
  UPDATE_MULTI_POS_SELECTED_ITEM,
  GET_MULTI_POS_ITEMS,
  UPDATE_SELECTED_POS_TYPE_TAB,
  GET_MULTI_POS_MAPPING_FIELDS,
  GET_MULTI_POS_MAPPING_PRODUCT,
  GET_MULTI_POS_MAPPING_ITEM,
  GET_MULTI_POS_MAPPING_COMBO,
  GET_MULTI_POS_BUSINESSES,
  UPDATE_MULTI_POS_REQUEST_PARAMS,
  UPDATE_MULTI_POS_DETAIL_TAB,
  UPDATE_IS_EDITING_MULTI_POS,
  GET_MULTI_POS_MAPPING_SUBITEM,
  RESET_CATEGORY_OPTION,
  RESET_CATEGORY_SUBITEM,
} from './multiPOSTypes';
import { api } from '../../../api';
import {
  buildPOSMappingForm,
  buildProductPOSMappingForm,
} from './utils/buildPOSMappingForm';
import { getPurePayload } from '../../../utils/purePayload';
import { createAlert } from '../../Alert/actions';
import { formatErrorMessage } from '../../../utils/formatErrorMessage';
import {
  getUpdatePayload,
  getAddUpdateURL,
  getAddPayload,
  getAddOverridePayload,
  getOverrideAddUpdateURL,
  getOverrideDeleteURL,
  getLocationsURL,
} from './utils/constants';

export function resetMultiPOSState() {
  return function (dispatch) {
    dispatch({
      type: 'RESET_MULTI_POS_STATE',
    });
  };
}
export function updateDirty(isFormDirty) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_FORM_IS_DIRTY',
      isFormDirty,
    });
  };
}

export function updateContinueModalOpen(isOpen) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_KEEP_MODAL_OPEN',
      isKeepModalOpen: isOpen,
    });
  };
}

export function handlePendingAction(newAction) {
  return function (dispatch, getState) {
    const { isEditing, pendingAction, isFormDirty } =
      getState().multiPOSReducer;
    if (isEditing && isFormDirty && !pendingAction) {
      dispatch({
        type: 'SET_PENDING_ACTION',
        pendingAction: newAction,
      });
      dispatch(updateContinueModalOpen(true));
    } else {
      newAction?.();
      dispatch({
        type: 'SET_PENDING_ACTION',
        pendingAction: null,
      });
      dispatch(updateContinueModalOpen(false));
    }
  };
}

export function handleConfirmContinueModal(confirm) {
  return function (dispatch, getState) {
    const { pendingAction } = getState().multiPOSReducer;
    if (confirm && pendingAction) {
      pendingAction();
      dispatch(updateIsEditingMultiPOS(false));
      dispatch(updateContinueModalOpen(false));
      return;
    }
    dispatch(handlePendingAction(null));
    dispatch({
      type: 'SET_PENDING_ACTION',
      pendingAction: null,
    });
  };
}

export function restSubItems() {
  return function (dispatch) {
    dispatch({
      type: RESET_CATEGORY_SUBITEM,
    });
  };
}

export function restOptions() {
  return function (dispatch) {
    dispatch({
      type: RESET_CATEGORY_OPTION,
    });
  };
}
export function updateMultiPOSRequestParams(newRequestParams) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_MULTI_POS_REQUEST_PARAMS,
      requestParams: newRequestParams,
    });
  };
}

export function updateIsEditingMultiPOS(isEditing) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_IS_EDITING_MULTI_POS,
      isEditing,
    });
  };
}

export function updateMultiPOSTab(selectedDetailTab) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_MULTI_POS_DETAIL_TAB,
      selectedDetailTab,
    });
  };
}

export function updateSelectedMultiPOSCategory(selectedCategory) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_MULTI_POS_SELECTED_CATEGORY,
      selectedCategory: selectedCategory,
    });
  };
}

export function updateSelectedMultiPOSItem(selectedItem) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_MULTI_POS_SELECTED_ITEM,
      selectedItem: selectedItem,
    });
  };
}

export function updateSelectedPOSType(posType) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_SELECTED_POS_TYPE_TAB,
      selectedPOSType: posType,
    });
  };
}

export function getMultiPOSMappingFields(categoryType) {
  return function (dispatch) {
    return api
      .get(
        `/pos-mapping-fields/app/${categoryType}?page_number=${1}&page_size=${25}`
      )
      .then((response) => {
        dispatch({
          type: GET_MULTI_POS_MAPPING_FIELDS,
          selectedPOSMappingFields: response.data.data,
        });
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message:
              formatErrorMessage(error) ||
              'An error occurred trying to get POS fields',
            dangerouslySetInnerHTML: true,
          })
        );
      });
  };
}

export function getMultiPOSBusinesses(
  posType,
  categoryType,
  categoryId,
  itemId
) {
  return function (dispatch) {
    return api
      .get(getLocationsURL(posType, categoryType, categoryId, itemId))
      .then((response) => {
        dispatch({
          type: GET_MULTI_POS_BUSINESSES,
          posBusinesses: response.data.data,
        });
      })
      .catch();
  };
}

export function getMultiPOSProducts() {
  return function (dispatch) {
    const url = `/menu/products?include=prices&page_number=${1}&page_size=${500}`;

    return api
      .get(url)
      .then((response) => {
        const allProducts = response.data.data.map((product) => {
          return {
            id: product.id,
            name: product.name,
          };
        });

        dispatch({
          type: GET_MULTI_POS_ITEMS,
          posItems: allProducts,
        });
      })
      .catch();
  };
}

export function getMultiPOSProduct(productId) {
  return function (dispatch, getState) {
    let url = `/menu/products/${productId}?include=prices,pos_mappings_v2,pos_mappings_override_v2`;
    dispatch({ type: 'IS_LOADING_MULTI_POS_SELECTED_ITEM', isLoading: true });
    return api
      .get(url)
      .then((response) => {
        const { selectedPOSMappingFields } = getState().multiPOSReducer;
        const { prices, pos_mappings_v2, pos_mappings_override_v2 } =
          response.data.data;
        const defaultMappings = buildProductPOSMappingForm(
          selectedPOSMappingFields,
          pos_mappings_v2,
          prices
        );
        const overrideMappings = buildProductPOSMappingForm(
          selectedPOSMappingFields,
          pos_mappings_override_v2,
          prices
        );
        dispatch({
          type: GET_MULTI_POS_MAPPING_PRODUCT,
          defaultPOSMappingsForm: defaultMappings,
          overridePOSMappingsForm: overrideMappings,
          originalPOSMappingOverride: pos_mappings_override_v2,
        });
        dispatch({
          type: 'IS_LOADING_MULTI_POS_SELECTED_ITEM',
          isLoading: false,
        });
      })
      .catch();
  };
}

export function getMultiPOSItems() {
  return function (dispatch) {
    const url = `menu/options?page_number=${1}&page_size=${500}`;
    return api
      .get(url)
      .then((response) => {
        const items = response.data.data.map((option) => {
          return {
            id: option.id,
            name: option.name,
          };
        });

        dispatch({
          type: GET_MULTI_POS_ITEMS,
          posItems: items,
        });
      })
      .catch();
  };
}

export function getMultiPOSItem(optionId) {
  return function (dispatch) {
    const url = `/menu/options/${optionId}/items?page_number=${1}&page_size=${500}&include=pos_mappings_v2,pos_mappings_override_v2`;

    const getPOSMappingStatus = (item) => {
      let isNotMapped = true;
      Object.entries(item.pos_mappings_v2).forEach(([_, value]) => {
        if (value.length > 0) {
          isNotMapped = false;
          return;
        }
      });
      Object.entries(item.pos_mappings_override_v2).forEach(([_, value]) => {
        if (value.length > 0) {
          isNotMapped = false;
          return;
        }
      });
      return isNotMapped
        ? `${item.name} - ${item.id} (Not Mapped)`
        : `${item.name} - ${item.id}`;
    };

    return api
      .get(url)
      .then((response) => {
        const modifiedPosSubitems = response.data.data.map((item) => ({
          id: item.id,
          name: getPOSMappingStatus(item),
          item,
        }));
        dispatch({
          type: GET_MULTI_POS_MAPPING_SUBITEM,
          posSubItems: modifiedPosSubitems,
        });
      })
      .catch();
  };
}

export function getMultiPOSSubItem(optionId, selectedSubitem) {
  return function (dispatch, getState) {
    const urlVariant = `/menu/options/${optionId}?include=variants,items`;
    dispatch({ type: 'IS_LOADING_MULTI_POS_SELECTED_ITEM', isLoading: true });
    return api
      .get(urlVariant)
      .then((response) => {
        const { selectedPOSMappingFields } = getState().multiPOSReducer;
        const { pos_mappings_v2, pos_mappings_override_v2 } =
          selectedSubitem || {};
        const selectedItem = response.data.data.items.find(
          (i) => i.id === selectedSubitem?.id
        );

        const modifiedPrices = selectedItem.prices.map((price) => {
          if (price.product_variant_id) {
            return price;
          } else {
            return {
              ...price,
              product_variant_id: price.variant_id,
            };
          }
        });

        const defaultMappings = buildProductPOSMappingForm(
          selectedPOSMappingFields,
          pos_mappings_v2,
          modifiedPrices
        );
        const overrideMappings = buildProductPOSMappingForm(
          selectedPOSMappingFields,
          pos_mappings_override_v2,
          modifiedPrices
        );

        dispatch({
          type: GET_MULTI_POS_MAPPING_ITEM,
          defaultPOSMappingsForm: defaultMappings,
          overridePOSMappingsForm: overrideMappings,
          originalPOSMappingOverride: pos_mappings_override_v2,
          selectedSubitem: selectedItem,
        });
        dispatch({
          type: 'IS_LOADING_MULTI_POS_SELECTED_ITEM',
          isLoading: false,
        });
      })
      .catch();
  };
}

export function getMultiPOSCombos() {
  return function (dispatch) {
    const url = `/combos?page_number=${1}&page_size=${500}`;
    return api
      .get(url)
      .then((response) => {
        const combos = response.data.data.map((combo) => {
          return {
            id: combo.id,
            name: combo.name,
          };
        });

        dispatch({
          type: GET_MULTI_POS_ITEMS,
          posItems: combos,
        });
      })
      .catch();
  };
}

export function getMultiPOSCombo(comboId) {
  return function (dispatch, getState) {
    let url = `/combos/${comboId}?include=pos_mappings_v2,pos_mappings_override_v2`;
    dispatch({ type: 'IS_LOADING_MULTI_POS_SELECTED_ITEM', isLoading: true });
    return api
      .get(url)
      .then((response) => {
        const { posTypes, selectedPOSMappingFields } =
          getState().multiPOSReducer;
        const { prices, pos_mappings_v2, pos_mappings_override_v2 } =
          response.data.data;
        const storedPOSTypes =
          posTypes?.length > 0
            ? posTypes
            : JSON.parse(localStorage.getItem('multiPOSTypes'));
        const defaultMappings = buildPOSMappingForm(
          storedPOSTypes,
          selectedPOSMappingFields,
          pos_mappings_v2,
          comboId
        );
        const overrideMappings = buildPOSMappingForm(
          storedPOSTypes,
          selectedPOSMappingFields,
          pos_mappings_override_v2,
          comboId
        );
        dispatch({
          type: GET_MULTI_POS_MAPPING_COMBO,
          defaultPOSMappingsForm: defaultMappings,
          overridePOSMappingsForm: overrideMappings,
          originalPOSMappingOverride: pos_mappings_override_v2,
        });
        dispatch({
          type: 'IS_LOADING_MULTI_POS_SELECTED_ITEM',
          isLoading: false,
        });
      })
      .catch();
  };
}

export function getMultiPOSOffers() {
  return function (dispatch) {
    const url = `v2/offers?page_number=${1}&page_size=${500}`;
    return api
      .get(url)
      .then((response) => {
        const offers = response.data.data.map((offer) => {
          return {
            id: offer.offer_id,
            name: offer.label,
          };
        });

        dispatch({
          type: GET_MULTI_POS_ITEMS,
          posItems: offers,
        });
      })
      .catch();
  };
}

export function getMultiPOSOffer(offerId) {
  return function (dispatch, getState) {
    let url = `v2/offers/${offerId}?include=pos_mappings_v2,pos_mappings_override_v2`;
    dispatch({ type: 'IS_LOADING_MULTI_POS_SELECTED_ITEM', isLoading: true });
    return api
      .get(url)
      .then((response) => {
        const { posTypes, selectedPOSMappingFields } =
          getState().multiPOSReducer;
        const { pos_mappings_v2, pos_mappings_override_v2 } =
          response.data.data;
        const storedPOSTypes =
          posTypes?.length > 0
            ? posTypes
            : JSON.parse(localStorage.getItem('multiPOSTypes'));
        const defaultMappings = buildPOSMappingForm(
          storedPOSTypes,
          selectedPOSMappingFields,
          pos_mappings_v2,
          offerId
        );
        const overrideMappings = buildPOSMappingForm(
          storedPOSTypes,
          selectedPOSMappingFields,
          pos_mappings_override_v2,
          offerId
        );
        dispatch({
          type: GET_MULTI_POS_MAPPING_COMBO,
          defaultPOSMappingsForm: defaultMappings,
          overridePOSMappingsForm: overrideMappings,
          originalPOSMappingOverride: pos_mappings_override_v2,
        });
        dispatch({
          type: 'IS_LOADING_MULTI_POS_SELECTED_ITEM',
          isLoading: false,
        });
      })
      .catch();
  };
}

export function getMultiPOSCoupons() {
  return function (dispatch) {
    const url = `/coupons?page_number=${1}&page_size=${500}`;
    return api
      .get(url)
      .then((response) => {
        const coupons = response.data.data.map((coupon) => {
          return {
            id: coupon.coupon_details.coupon_id,
            name: coupon.coupon_details.label,
          };
        });

        dispatch({
          type: GET_MULTI_POS_ITEMS,
          posItems: coupons,
        });
      })
      .catch();
  };
}

export function getMultiPOSCoupon(couponId) {
  return function (dispatch, getState) {
    let url = `/coupons/${couponId}?include=pos_mappings_v2,pos_mappings_override_v2`;
    dispatch({ type: 'IS_LOADING_MULTI_POS_SELECTED_ITEM', isLoading: true });
    return api
      .get(url)
      .then((response) => {
        const { posTypes, selectedPOSMappingFields } =
          getState().multiPOSReducer;
        const { pos_mappings_v2, pos_mappings_override_v2 } =
          response.data.data;
        const storedPOSTypes =
          posTypes?.length > 0
            ? posTypes
            : JSON.parse(localStorage.getItem('multiPOSTypes'));
        const defaultMappings = buildPOSMappingForm(
          storedPOSTypes,
          selectedPOSMappingFields,
          pos_mappings_v2,
          couponId
        );
        const overrideMappings = buildPOSMappingForm(
          storedPOSTypes,
          selectedPOSMappingFields,
          pos_mappings_override_v2,
          couponId
        );
        dispatch({
          type: GET_MULTI_POS_MAPPING_COMBO,
          defaultPOSMappingsForm: defaultMappings,
          overridePOSMappingsForm: overrideMappings,
          originalPOSMappingOverride: pos_mappings_override_v2,
        });
        dispatch({
          type: 'IS_LOADING_MULTI_POS_SELECTED_ITEM',
          isLoading: false,
        });
      })
      .catch();
  };
}

export function addMultiPOSMapping(
  posMappings,
  category,
  categoryId,
  posType,
  itemId
) {
  return async function (dispatch, getState) {
    const { id } = getState().multiPOSReducer.selectedSubitem || {};
    try {
      const appMetaData = JSON.parse(localStorage.getItem('appMetaData'));
      const payloads = getAddPayload(
        category,
        posMappings,
        posType,
        appMetaData.app_id
      );

      let promise;
      if (category === 'item' || category === 'products') {
        promise = api.post(
          getAddUpdateURL(
            { categoryType: category, categoryId, subcategoryId: itemId },
            posType
          ),
          payloads
        );
      } else {
        promise = Promise.allSettled(
          payloads?.map((data) =>
            api.post(
              getAddUpdateURL({ categoryType: category, categoryId }, posType),
              getPurePayload(data)
            )
          )
        );
      }

      await promise;
      dispatch(getDetail(category, categoryId, id));
      dispatch(
        createAlert({
          type: 'success',
          message: 'POS values were saved successfully.',
        })
      );
    } catch (error) {
      dispatch(
        createAlert({
          type: 'error',
          message:
            formatErrorMessage(error) ||
            'An error occurred trying to save POS values.',
          dangerouslySetInnerHTML: true,
        })
      );
    }
  };
}

export function updateMultiPOSMapping(
  posMappings,
  category,
  categoryId,
  posType,
  itemId
) {
  return function (dispatch, getState) {
    const { id } = getState().multiPOSReducer.selectedSubitem || {};
    const getPayload = (mapping) => {
      const { id, ...rest } = mapping;
      return getPurePayload(rest);
    };
    return Promise.allSettled(
      posMappings.map((data) =>
        api.patch(
          getAddUpdateURL(
            { categoryType: category, categoryId, subcategoryId: itemId },
            posType,
            data.id
          ),
          getUpdatePayload(category, getPayload(data), posType)
        )
      )
    )
      .then(() => {
        dispatch(getDetail(category, categoryId, id));
        dispatch(
          createAlert({
            type: 'success',
            message: 'POS values were saved successfully.',
          })
        );
      })
      .catch((error) =>
        dispatch(
          createAlert({
            type: 'error',
            message:
              formatErrorMessage(error) ||
              'An error occurred trying to saving POS values.',
            dangerouslySetInnerHTML: true,
          })
        )
      );
  };
}

/////////////////////////////// Overrides ///////////////////////////////

export function addMultiPOSMappingOverride(
  posMappings,
  business_ids,
  category,
  categoryId,
  posType,
  setScreen,
  itemId
) {
  return async function (dispatch, getState) {
    const { id } = getState().multiPOSReducer.selectedSubitem || {};
    try {
      const appMetaData = JSON.parse(localStorage.getItem('appMetaData'));
      const payloads = getAddOverridePayload(
        business_ids,
        category,
        posMappings,
        posType,
        appMetaData.app_id
      );
      await api.post(
        getOverrideAddUpdateURL(
          { categoryType: category, categoryId, subcategoryId: itemId },
          posType
        ),
        payloads
      );
      dispatch(getDetail(category, categoryId, id));
      dispatch(
        createAlert({
          type: 'success',
          message: 'POS values were saved successfully.',
        })
      );
      setScreen('list');
    } catch (error) {
      dispatch(
        createAlert({
          type: 'error',
          message:
            formatErrorMessage(error) ||
            'An error occurred trying to save POS values.',
          dangerouslySetInnerHTML: true,
        })
      );
    }
  };
}

export function updateMultiPOSMappingOverride(
  posMappings,
  business_id,
  category,
  categoryId,
  posType,
  itemId
) {
  return async function (dispatch, getState) {
    const { id } = getState().multiPOSReducer.selectedSubitem || {};
    try {
      const getPayload = (mapping) => {
        const { id, ...rest } = mapping;
        return getPurePayload(rest);
      };

      await Promise.allSettled(
        posMappings.map((data) => {
          const url = getOverrideAddUpdateURL(
            {
              categoryType: category,
              categoryId,
              subcategoryId: itemId,
              business_id: business_id,
            },
            posType,
            data.id
          );
          return api.patch(url, getPayload(data));
        })
      );

      dispatch(getDetail(category, categoryId, id));
      dispatch(
        createAlert({
          type: 'success',
          message: 'POS values were saved successfully.',
        })
      );
    } catch (error) {
      dispatch(
        createAlert({
          type: 'error',
          message:
            formatErrorMessage(error) ||
            'An error occurred trying to saving POS values.',
          dangerouslySetInnerHTML: true,
        })
      );
    }
  };
}

export function deleteMultiPOSMappingOverride(
  posMappings,
  business_id,
  category,
  categoryId,
  posType
) {
  return async function (dispatch, getState) {
    const { id } = getState().multiPOSReducer.selectedSubitem || {};
    try {
      await Promise.allSettled(
        posMappings.map((data) =>
          api.delete(
            getOverrideDeleteURL(
              {
                categoryType: category,
                categoryId,
                business_id,
                subcategoryId: id,
              },
              posType,
              data.id
            )
          )
        )
      );
      dispatch(getDetail(category, categoryId, id));
      dispatch(
        createAlert({
          type: 'success',
          message: 'POS values were deleted successfully.',
        })
      );
    } catch (error) {
      dispatch(
        createAlert({
          type: 'error',
          message:
            formatErrorMessage(error) ||
            'An error occurred trying to deleting POS values.',
          dangerouslySetInnerHTML: true,
        })
      );
    }
  };
}

function getDetail(category, categoryId, subcategoryId) {
  return function (dispatch, getState) {
    switch (category) {
      case 'products':
        return dispatch(getMultiPOSProduct(categoryId));
      case 'item':
        return dispatch(getMultiPOSItem(categoryId)).then(() => {
          const { posSubItems } = getState().multiPOSReducer;
          const foundSubItem = posSubItems.find(
            (item) => item.id === subcategoryId
          );
          return dispatch(getMultiPOSSubItem(categoryId, foundSubItem?.item));
        });
      case 'combo':
        return dispatch(getMultiPOSCombo(categoryId));
      case 'offer':
        return dispatch(getMultiPOSOffer(categoryId));
      case 'coupon':
        return dispatch(getMultiPOSCoupon(categoryId));
      default:
        break;
    }
  };
}
