import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  getMultiPOSBusinesses,
  handleConfirmContinueModal,
  handlePendingAction,
  updateMultiPOSTab,
  updateSelectedPOSType,
} from '../multiPOSActions';
import { Box, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import { DefaultMappingContainer } from './DefaultMapping/DefaultMappingContainer';
import { OverrideMappingContainer } from './Overrides/OverrideMappingContainer';
import { POSLocationContainer } from './Locations/POSLocationContainer';
import { getPOSPayloadType } from '../utils/constants';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#2440ae',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '17px',
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#2440ae',
      opacity: 1,
    },
    '&$selected': {
      color: '#2440ae',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#2440ae',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`detail-multi-pos-tab-panel-${index}`}
      aria-labelledby={`detail-multi-pos-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `detail-multi-pos-tab-${index}`,
    'aria-controls': `detail-multi-pos-tab-${index}`,
  };
}

const Wrapper = styled.div``;

const POSDetailTab = ({
  selectedPOSType,
  selectedDetailTab,
  updateMultiPOSTab,
  selectedCategory,
  selectedItem,
  selectedSubitem,
  getMultiPOSBusinesses,
  isKeepModalOpen,
  handleConfirmContinueModal,
  handlePendingAction,
}) => {
  useEffect(() => {
    if (selectedItem?.id) {
      getMultiPOSBusinesses(
        selectedPOSType,
        getPOSPayloadType(selectedCategory.name),
        selectedItem?.id,
        selectedSubitem?.id
      );
    }
  }, [
    selectedPOSType,
    selectedCategory,
    selectedItem?.id,
    selectedSubitem?.id,
    selectedDetailTab,
  ]);
  return (
    <Wrapper>
      <Box sx={{ width: '100%' }}>
        <AntTabs
          value={selectedDetailTab}
          onChange={(_, newValue) => {
            if (selectedDetailTab !== newValue) {
              handlePendingAction(() => {
                updateMultiPOSTab(newValue);
              });
            }
          }}
          aria-label='basic tabs example'
        >
          <AntTab label={'POS Mapping'} {...a11yProps(0)} />
          <AntTab label={`locations on ${selectedPOSType}`} {...a11yProps(1)} />
          <AntTab label={'Override Location Mapping'} {...a11yProps(2)} />
        </AntTabs>
        <TabPanel value={selectedDetailTab} index={0}>
          <DefaultMappingContainer />
        </TabPanel>
        <TabPanel value={selectedDetailTab} index={1}>
          <POSLocationContainer />
        </TabPanel>
        <TabPanel value={selectedDetailTab} index={2}>
          <OverrideMappingContainer />
        </TabPanel>
      </Box>
      {isKeepModalOpen && (
        <ConfirmModal
          textAlign='center'
          actionLabel='Continue'
          confirmMessage='You have unsaved changes if you continue your changes will be lost, do you want to continue to other section?'
          onClick={() => handleConfirmContinueModal(true)}
          onClose={() => handleConfirmContinueModal(false)}
        />
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
  selectedItem: state.multiPOSReducer.selectedItem,
  selectedSubitem: state.multiPOSReducer.selectedSubitem,
  selectedDetailTab: state.multiPOSReducer.selectedDetailTab,
  posTypes: state.multiPOSReducer.posTypes,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  isKeepModalOpen: state.multiPOSReducer.isKeepModalOpen,
});

export const POSDetailTabs = connect(mapStateToProps, {
  updateSelectedPOSType,
  updateMultiPOSTab,
  getMultiPOSBusinesses,
  handleConfirmContinueModal,
  handlePendingAction,
})(POSDetailTab);
