import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LoadingBlueLarge } from 'merchant-portal-components';

const Header = styled.div`
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;
const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 15px;
  margin-top: 25px;
`;
const SubTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
`;

const Icon = styled.i`
  margin: 0;
  margin-right: 10px;
  color: #2440ae;
  font-size: 25px;
`;

export const ChargeBackData = () => {
  return (
    <>
      <Header>
        <Icon className='fa fa-info-circle' />
        <span>Please Note</span>
      </Header>
      <Title>
        Chargeback data integration can help streamline chargeback management by
        providing real-time insights into disputes. However, Smooth Commerce
        does not currently receive chargeback data from your payment acquirer.
      </Title>
      <SubTitle>
        To enable this feature, please request your payment acquirer to contact
        our integrations team{' '}
        <a
          href='mailto:integrations@smooth.tech'
          rel='noopener noreferrer'
          onClick={(e) => window.open('mailto:integrations@smooth.tech')}
        >
          (integrations@smooth.tech)
        </a>
        . Once integrated, you will have access to chargeback details directly
        within the Smooth Commerce platform.
      </SubTitle>
    </>
  );
};
