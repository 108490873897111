import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Field, Formik, FieldArray } from 'formik';
import { Popover, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { getFirstColumnTitle } from '../../../../MultiPOSMapping/utils/constants';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';
import {
  addMultiPOSMapping,
  updateIsEditingMultiPOS,
  updateMultiPOSMapping,
} from '../../../../MultiPOSMapping/multiPOSActions';
import { Button } from 'merchant-portal-components';

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `220px repeat(${props.len},1fr) auto`};
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 15px;
`;
const TableRow = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `220px repeat(${props.len},1fr) auto`};
  grid-gap: 20px;
  align-items: flex-start;
  margin-bottom: 10px;
`;
const TableTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
`;
const Subtitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
`;
const POSField = styled.span`
  padding: 20px;
`;
const EditIcon = styled.i`
  cursor: pointer;
`;
const ItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 7px 12px;
`;
const EditTextWrapper = styled.span`
  margin-left: 8px;
`;
const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  font-size: 18px;
`;
const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const ButtonRow = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const ProductMappingsFormComponent = ({
  initialValues,
  selectedPOSMappingFields,
  selectedPOSType,
  isDefault,
  overrideAction,
  addMultiPOSMapping,
  updateMultiPOSMapping,
  isEditing,
  updateIsEditingMultiPOS,
  posItems,
  allVariants,
  selectedDetailTab,
  productId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const fieldsHeader = useMemo(() => {
    return [
      { label: getFirstColumnTitle('Products'), optional: true },
      ...(Array.isArray(selectedPOSMappingFields?.[selectedPOSType])
        ? selectedPOSMappingFields[selectedPOSType].map((field) => ({
            label: field.label,
            optional: field.optional,
          }))
        : []),
    ];
  }, [selectedPOSMappingFields, selectedPOSType]);

  const len = fieldsHeader.length - 1;
  const variantName = useCallback(
    (variantId) => {
      let variant = '';

      const selectedVariant = allVariants.find(
        (variant) => variant.id === variantId
      );
      variant = selectedVariant?.name ?? '';

      return variant;
    },

    [posItems, allVariants]
  );

  const validateField = (value, field) => {
    if (field.optional === false && !value) {
      return `${field.label} is required`;
    }
    return undefined;
  };

  const handleSave = useCallback(
    (values) => {
      const posId = values[0]?.id || null;
      if (isDefault) {
        if (posId) {
          updateMultiPOSMapping(values, 'products', productId, selectedPOSType);
        } else {
          addMultiPOSMapping(values, 'products', productId, selectedPOSType);
        }
      }
    },
    [productId, isDefault, selectedPOSType]
  );

  useEffect(() => {
    const ids = initialValues.map((item) => item.id);
    if (ids.length > 0 && ids.every((id) => id === '')) {
      updateIsEditingMultiPOS(true);
    } else {
      updateIsEditingMultiPOS(false);
    }
  }, [initialValues]);

  if (initialValues.length === 0)
    return (
      <MessageWrapper>No mappings found for this selection</MessageWrapper>
    );
  return (
    <>
      <TableHeader len={len}>
        {fieldsHeader?.map((header, index) => (
          <TableTitle key={`${header?.label}-${index}`}>
            {`${header.label} ${!header.optional ? '*' : ''}`}
          </TableTitle>
        ))}
        {(selectedDetailTab !== 2 ||
          (selectedDetailTab === 2 && overrideAction === 'add')) && (
          <>
            <EditIcon
              className='fa fa-ellipsis-v'
              onMouseEnter={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              fullWidth
            >
              <ItemWrapper
                onClick={() => {
                  updateIsEditingMultiPOS(!isEditing);
                  setAnchorEl(null);
                }}
                onMouseLeave={() => setAnchorEl(null)}
              >
                <EditIcon className='fa fa-pencil' />
                <EditTextWrapper>Edit</EditTextWrapper>
              </ItemWrapper>
            </Popover>
          </>
        )}
      </TableHeader>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSave}
      >
        {({
          values,
          errors,
          resetForm,
          isValid,
          dirty,
          touched,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray
              name=''
              render={(arrayHelpers) => (
                <>
                  {arrayHelpers.form.values?.map((item, index) => (
                    <TableRow key={item.id || index} len={len}>
                      {Object.entries(item)?.map(([key, value], rowIndex) =>
                        rowIndex === 0 ? (
                          <FieldWrapper>
                            <Subtitle key={key}>{variantName(value)}</Subtitle>
                          </FieldWrapper>
                        ) : rowIndex ===
                          Object.entries(item).length - 1 ? null : (
                          <>
                            {!isEditing ? (
                              <SwitchWrapper>
                                {value === true || value === false ? (
                                  <SmallToggleSwitch
                                    disabled={true}
                                    checked={value}
                                  />
                                ) : (
                                  <POSField>{value}</POSField>
                                )}
                              </SwitchWrapper>
                            ) : (
                              <>
                                {value === true ||
                                value === false ||
                                key === 'is_addon' ? (
                                  <SwitchWrapper>
                                    <SmallToggleSwitch
                                      checked={value}
                                      onChange={(e) =>
                                        arrayHelpers.form.setFieldValue(
                                          `[${index}].${key}`,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </SwitchWrapper>
                                ) : (
                                  <>
                                    <Field
                                      name={`[${index}].${key}`}
                                      validate={(value) =>
                                        validateField(value, {
                                          key,
                                          ...selectedPOSMappingFields[
                                            selectedPOSType
                                          ].find((field) => field.key === key),
                                        })
                                      }
                                    >
                                      {({ field }) => (
                                        <TextField
                                          {...field}
                                          variant='outlined'
                                          fullWidth
                                          error={errors?.[index]?.[key]}
                                          helperText={errors?.[index]?.[key]}
                                        />
                                      )}
                                    </Field>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )
                      )}
                    </TableRow>
                  ))}
                </>
              )}
            />
            <ButtonRow>
              <Button
                secondary
                onClick={(e) => {
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button type='submit' disabled={!dirty || !isValid}>
                Save
              </Button>
            </ButtonRow>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
  selectedDetailTab: state.multiPOSReducer.selectedDetailTab,
  selectedItem: state.multiPOSReducer.selectedItem,
  selectedSubitem: state.multiPOSReducer.selectedSubitem,
  selectedPOSMappingFields: state.multiPOSReducer.selectedPOSMappingFields,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  isEditing: state.multiPOSReducer.isEditing,
  posItems: state.multiPOSReducer.posItems,
  allVariants: state.variantsReducer.allVariants,
  productId: state.productsReducer.productInformation.id,
});

export const ProductMappingForm = connect(mapStateToProps, {
  addMultiPOSMapping,
  updateMultiPOSMapping,
  updateIsEditingMultiPOS,
})(React.memo(ProductMappingsFormComponent));
