import api from '../../api';
import {
  REQUEST_SETTLEMENTS,
  RECEIVE_SETTLEMENTS,
  HIDE_TEMPORARY_SETTLEMENT_BANNER,
} from './types';
import renderQueryString from '../../utils/renderQueryString';

export function isTemporarySettlementBannerDisplayed(statue) {
  return {
    type: HIDE_TEMPORARY_SETTLEMENT_BANNER,
    temporaryShowSettlementBanner: statue,
  };
}
function requestSettlements() {
  return {
    type: REQUEST_SETTLEMENTS,
  };
}

export function fetchSettlements(params) {
  return function (dispatch) {
    dispatch(requestSettlements());
    return api
      .get(`/settlements${renderQueryString(params)}`)
      .then((response) => {
        dispatch({ type: RECEIVE_SETTLEMENTS, payload: response.data });
      })
      .catch((error) => {});
  };
}
