import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import _ from 'lodash';
import RenderMissingTable from './missingTable';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Wrapper = styled.div`
  width: 100%;
  @media (max-width: 1313px) {
    width: 100%;
  }
`;
const HeaderWrapper = styled.div`
  padding: 15px 25px 25px 25px;
  display: grid;
  grid-template-columns: 1fr 30px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const Divider = styled.hr`
  border: 1px solid rgba(0, 0, 0, 0.14);
  margin-bottom: 0;
`;
const SectionTitle = styled.span`
  width: 100%;
  margin: 0px 0 12px 0;
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #191818;
  text-align: ${(props) => props.textAlign || 'left'};
`;
const SectionSubtitle = styled.div`
  margin-top: 10px;
  width: 100%;
  font-family: Montserrat;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #191818;
`;
const PortalProblemIcon = styled.i`
  color: #ffa500;
  font-size: 25px;
  margin-right: 10px;
  &:hover {
    animation: none;
    cursor: pointer;
  }
`;
const CloseIcon = styled.i`
  font-size: 20px;
  cursor: pointer;
`;
const NotesTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;
const Notes = styled.div`
  display: grid;
  margin: 0 5px;
  font-size: 15px;
  font-weight: normal;
  white-space: pre-wrap;
`;
const MissingPOSMappingContainer = ({
  handleCloseModal,
  missingPOSMappings,
  getMissingCombosPOSMapping,
}) => {
  const {
    missingComboItemPOSMappings,
    missingComboPOSMappings,
    missingItemPOSMappings,
    missingProductPOSMappings,
  } = missingPOSMappings;
  const [value, setValue] = React.useState(0);
  const [availableMissingMapping, setAvailableMissingMapping] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getMissingCombosPOSMapping();
  }, []);
  useEffect(() => {
    let newAvailableMissingMapping = [];
    if (missingProductPOSMappings?.length > 0) {
      const groupedMissingProduct = _.groupBy(
        missingProductPOSMappings,
        'product_id'
      );
      let newMissingProductPOSMapping = [];
      for (var [key, value] of Object.entries(groupedMissingProduct)) {
        let newProduct;
        if (value.length === 1) {
          newProduct = {
            product_id: parseInt(key),
            product_name: value[0].name,
            is_active: value[0].product_is_active,
            variants: [
              {
                variant_id: value[0].variant_id,
                variant_name: value[0].variant_name,
                variant_is_active: value[0].variant_is_active,
              },
            ],
          };
          newMissingProductPOSMapping.push(newProduct);
        } else {
          let newVariants = [];
          value.forEach((variant) => {
            newVariants.push({
              variant_id: variant.variant_id,
              variant_name: variant.variant_name,
              variant_is_active: variant.variant_is_active,
            });
          });
          newProduct = {
            product_id: parseInt(key),
            product_name: value[0].name,
            is_active: value[0].product_is_active,
            variants: newVariants,
          };
          newMissingProductPOSMapping.push(newProduct);
        }
      }
      const mapping = {
        tabTitle: 'Products',
        tabSubtilte: `
            1. Click on the "Link to Issue" to be redirected to the POS mapping page.
            2. POS mapping category and the product that is missing POS mapping has already been pre-selected for you.
            3. You need to enter the missing values of POS mapping fields.
            4. After you have entered all the missing fields, click the SAVE button to save your changes.
            5. To select another missing POS mapping item, click on the yellow bell at the top-right corner again.
                    `,
        mappingData: newMissingProductPOSMapping,
        type: 'product',
      };
      newAvailableMissingMapping.push(mapping);
    }
    if (missingItemPOSMappings?.length > 0) {
      const newGroupedMissingItems = _.groupBy(
        missingItemPOSMappings,
        'option_id'
      );
      let newMissingOptionPOSMapping = [];
      for (var [optionKey, optionValue] of Object.entries(
        newGroupedMissingItems
      )) {
        const groupedSuboptions = _.groupBy(optionValue, 'sub_option_id');
        for (var [subOptionKey, subOptionValue] of Object.entries(
          groupedSuboptions
        )) {
          let newOption = {
            option_id: optionKey,
            option_name: optionValue[0].option_name,
            is_active: optionValue[0].option_is_active,
            suboption_id: '',
            suboption_name: '',
          };
          if (subOptionKey === 'null') {
          } else {
            newOption = {
              option_id: optionKey,
              option_name: optionValue[0].option_name,
              is_active: optionValue[0].option_is_active,
              suboption_id: subOptionKey,
              suboption_name: subOptionValue[0].sub_option_name,
            };
          }
          newMissingOptionPOSMapping.push(newOption);
        }
      }
      const mapping = {
        tabTitle: 'Items',
        tabSubtilte: `
          1. Click on the "Link to Issue" to be redirected to the POS mapping page. 
          2. POS mapping category and the option/sub-option that is missing POS mapping has already been pre-selected for you. 
          3. You need to enter the missing values of POS mapping fields. 
          4. After you have entered all the missing fields, click the SAVE button to save your changes. 
          5. To select another missing POS mapping item, click on the yellow bell at the top-right corner again.
                    `,
        mappingData: newMissingOptionPOSMapping,
        type: 'item',
      };
      newAvailableMissingMapping.push(mapping);
    }
    if (missingComboPOSMappings?.length > 0) {
      const mapping = {
        tabTitle: 'Combos',
        tabSubtilte: `
          1. Click on the "Link to Issue" to be redirected to the POS mapping page. 
          2. POS mapping category and the combo that is missing POS mapping has already been pre-selected for you. 
          3. You need to enter the missing values of POS mapping fields. 
          4. After you have entered all the missing fields, click the SAVE button to save your changes. 
          5. To select another missing POS mapping item, click on the yellow bell at the top-right corner again.
                          `,
        mappingData: missingComboPOSMappings,
        type: 'combo',
      };
      newAvailableMissingMapping.push(mapping);
    }
    if (missingComboItemPOSMappings?.length > 0) {
      const mapping = {
        tabTitle: 'Combo-Items',
        tabSubtilte: `
          1. Click on the "Link to Issue" to be redirected to the POS mapping page. 
          2. POS mapping category and the combo goup that is missing POS mapping has already been pre-selected for you. 
          3. You need to enter the missing values of POS mapping fields. 
          4. After you have entered all the missing fields, click the SAVE button to save your changes. 
          5. To select another missing POS mapping item, click on the yellow bell at the top-right corner again.
                `,
        mappingData: missingComboItemPOSMappings,
        type: 'combo-item',
      };
      newAvailableMissingMapping.push(mapping);
    }
    setAvailableMissingMapping(
      newAvailableMissingMapping.map((mapping, index) => {
        return { ...mapping, value: index };
      })
    );
  }, [
    missingComboItemPOSMappings,
    missingComboPOSMappings,
    missingItemPOSMappings,
    missingProductPOSMappings,
  ]);

  return (
    <Wrapper>
      {availableMissingMapping?.length > 0 && (
        <Box sx={{ width: '100%' }}>
          <FormControl component='fieldset' style={{ marginBottom: '15px' }}>
            <FormLabel component='legend'>
              Select Missing POS Mapping Category
            </FormLabel>
            <RadioGroup
              aria-label='gender'
              name='gender1'
              value={value?.toString()}
              onChange={handleChange}
              row
            >
              {availableMissingMapping.map((mapping) => (
                <FormControlLabel
                  value={mapping.value.toString()}
                  control={<Radio style={{ color: '#243167' }} />}
                  label={mapping.tabTitle}
                />
              ))}
              {/* {availableMissingMapping[1] && (
                <FormControlLabel
                  value={availableMissingMapping[1]?.value.toString()}
                  control={<Radio style={{ color: '#243167' }} />}
                  label={availableMissingMapping[1]?.tabTitle}
                />
              )}
              {availableMissingMapping[2] && (
                <FormControlLabel
                  value={availableMissingMapping[2]?.value.toString()}
                  control={<Radio style={{ color: '#243167' }} />}
                  label={availableMissingMapping[2]?.tabTitle}
                />
              )}
              {availableMissingMapping[3] && (
                <FormControlLabel
                  value={availableMissingMapping[3]?.value.toString()}
                  control={<Radio style={{ color: '#243167' }} />}
                  label={availableMissingMapping[3]?.tabTitle}
                />
              )} */}
            </RadioGroup>
          </FormControl>
          <TabPanel value={value} index={value}>
            <NotesTitle>{`How to fix a missing ${availableMissingMapping[value]?.type} POS mapping:`}</NotesTitle>
            <Notes>{availableMissingMapping[value]?.tabSubtilte}</Notes>
            <div>
              <RenderMissingTable
                data={availableMissingMapping[value]?.mappingData}
                type={availableMissingMapping[value]?.type}
                handleCloseModal={handleCloseModal}
              />
            </div>
          </TabPanel>
        </Box>
      )}
    </Wrapper>
  );
};
export default MissingPOSMappingContainer;
