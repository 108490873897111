import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import _ from 'lodash';
import { connect } from 'react-redux';
import { updateOfferEngineSource } from '../OfferEngineActions';
import OfferSourceSelection from './components/OfferSourceSelection';
import OfferRewardContainer from './components/OfferRewardContainer';
import RequirementContainer from './components/RequirementContainer';
import OfferApplyMultipleContainer from './components/OfferApplyMultipleContainer';

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  .mb25 {
    margin-bottom: 25px;
  }
  .mb25 .MuiSelect-select {
    border: 1px solid #cdcdcd;
    padding: 18px 15px;
    border-radius: 4px;
    min-width: 350px;
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border: 0px !important;
  }
`;

const SlideTwo = ({
  values,
  setFieldValue,
  isSegmentVisible,
  setIsSegmentVisible,
  isConsumerVisible,
  setIsConsumerVisible,
  offerEngineSource,
  offerEngineProducts,
  allProductVariants,
  errors,
}) => {
  const [currentSortedSources, setCurrentSortedSources] = useState([]);
  const [loyaltyHasError, setLoyaltyHasError] = useState(false);
  const [pointPropertyIsEnabled, setPointPropertyIsEnabled] = useState(false);
  const [birthdayPropertyIsEnabled, setBirthdayPropertyIsEnabled] =
    useState(false);
  const [tierPropertyIsEnabled, setTierPropertyIsEnabled] = useState(false);

  // const [isKeepSourceModalOpen, setIsKeepSourceModalOpen] = useState(false);
  const [hasFreeItemAndPoints, setHasFreeItemAndPoints] = useState(false);

  const tierIndex =
    values.conditionValue[0]?.requirements.length > 0
      ? values.conditionValue[0]?.requirements?.findIndex(
          (x) => x['tier'] != undefined
        )
      : 0;

  useEffect(() => {
    const currentTemplateName = values.offer_details.template_name;
    if (currentTemplateName === 'birthday-free-item') {
      setFieldValue('offer_details.template_name', 'free-item');
    } else if (currentTemplateName === 'birthday-discounted-item') {
      const rewardType = values.conditionValue[0].reward.type;
      if (rewardType === 'dollar') {
        setFieldValue('offer_details.template_name', 'dollar-discount');
      } else {
        setFieldValue('offer_details.template_name', 'discounted-item');
      }
    }
    if (values.conditionValue?.length === 0) {
      setFieldValue('offer_details.template_name', '');
    }
    let isPointsActive = false;
    let isBirthdateActive = false;
    let isTireActive = false;
    values.conditionValue.forEach((source, index) => {
      source?.requirements.forEach((req) => {
        if ('points' in req) {
          isPointsActive = true;
        }
        if ('birthdate' in req) {
          isBirthdateActive = true;
        }
        if ('tier' in req) {
          isTireActive = true;
        }
      });

      /// does loyalty have error?
      if (
        values.conditionValue[0]?.requirements.filter((req) => 'tire' in req) &&
        values.conditionValue[0]?.requirements[tierIndex]?.tier.length === 0
      ) {
        setLoyaltyHasError(true);
      } else {
        setLoyaltyHasError(false);
      }
    }, []);
    setPointPropertyIsEnabled(isPointsActive);
    setBirthdayPropertyIsEnabled(isBirthdateActive);
    setTierPropertyIsEnabled(isTireActive);
    // setting sorted sources to display rewards and requirements in a specific order
    const initialConditions = values.conditionValue;
    let sources = initialConditions.map((c) => c.source);
    const sortedSource = _.sortBy(sources, function (element) {
      var rank = {
        mobile: 1,
        web: 2,
      };
      return rank[element];
    });
    setCurrentSortedSources(sortedSource);
    // check if web and mobile are available as client's sources
    // checkRequirementsSililarity(sortedSource);
    // sort apply multiple
    let hasPoints = false;
    values.conditionValue[0]?.requirements.forEach((req) => {
      if (req?.points) {
        hasPoints = true;
      }
    });
    if (hasPoints && values.offer_details.template_name === 'free-item') {
      setFieldValue('offer_details.sort_price', 'DESC');
      setFieldValue('offer_details.apply_multiple', false);
      setHasFreeItemAndPoints(true);
    } else {
      setHasFreeItemAndPoints(false);
    }
  }, [values]);

  const getSourceTitle = (source) => {
    return (
      source?.charAt(0)?.toUpperCase() +
        source?.toLowerCase().substring(1, source.length) || ''
    );
  };

  const getItemIndex = (source) => {
    const selectedIndex = values.conditionValue
      .map((e) => e.source)
      .indexOf(source);
    return selectedIndex;
  };

  return (
    <Wrapper>
      <OfferSourceSelection values={values} setFieldValue={setFieldValue} />
      <OfferRewardContainer
        values={values}
        setFieldValue={setFieldValue}
        getSourceTitle={getSourceTitle}
        getItemIndex={getItemIndex}
        isSegmentVisible={isSegmentVisible}
        setIsSegmentVisible={setIsSegmentVisible}
        isConsumerVisible={isConsumerVisible}
        setIsConsumerVisible={setIsConsumerVisible}
        offerEngineProducts={offerEngineProducts}
        allProductVariants={allProductVariants}
        errors={errors}
        currentSortedSources={currentSortedSources}
      />
      <RequirementContainer
        values={values}
        setFieldValue={setFieldValue}
        getSourceTitle={getSourceTitle}
        getItemIndex={getItemIndex}
        offerEngineSource={offerEngineSource}
        pointPropertyIsEnabled={pointPropertyIsEnabled}
        birthdayPropertyIsEnabled={birthdayPropertyIsEnabled}
        setBirthdayPropertyIsEnabled={setBirthdayPropertyIsEnabled}
        setTierPropertyIsEnabled={setTierPropertyIsEnabled}
        loyaltyHasError={loyaltyHasError}
        tierPropertyIsEnabled={tierPropertyIsEnabled}
        isSegmentVisible={isSegmentVisible}
        setIsSegmentVisible={setIsSegmentVisible}
        isConsumerVisible={isConsumerVisible}
        setIsConsumerVisible={setIsConsumerVisible}
        allProductVariants={allProductVariants}
        currentSortedSources={currentSortedSources}
      />
      <OfferApplyMultipleContainer
        values={values}
        setFieldValue={setFieldValue}
        offerEngineSource={offerEngineSource}
        hasFreeItemAndPoints={hasFreeItemAndPoints}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { updateOfferEngineSource })(SlideTwo);
