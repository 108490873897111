import React, { Fragment, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { Formik, FieldArray } from "formik";
import { Checkbox, Collapse, TextField } from "@material-ui/core";
import ChevronUpIcon from "../../../../../../components/Icon/ChevronUpIcon";
import ChevronDownIcon from "../../../../../../components/Icon/ChevronDownIcon";
import { EditIcon } from "merchant-portal-components";
import { Button, ButtonRow } from "merchant-portal-components";
import RenderProductOptionsFieldArray from "./RenderProductOptionsFieldArray";
import ConfirmCancelModal from "../../components/ConfirmCancelModal";
import OptionsSummary from "./OptionsSummary";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Autocomplete } from "@material-ui/lab";
import RemoveOptionConfirmModal from "../../../../../BusinessLocationDetails/LocationDetailsManager/BusinessWizard/Hours/components/RemoveOptionConfirmModal";
import _, { values } from "lodash";
import { TooltipIcon } from "../../../../../../../src/components/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const CustomCheckbox = withStyles({
  root: {
    color: "#2440AE",
    "&$checked": {
      color: "#2440AE",
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);
const Card = styled.div`
  background: ${(props) => (props.disabled ? "#ebebeb" : "white")};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${(props) => (props.disabled ? "#959595" : "initial")};
`;
const StyledSaveDefSelcsBtn = styled.div`
  margin-right: -7px;
`;
const Wrapper = styled.div`
  width: 100%;
`;

const SortDiv = styled.div`
  padding: 0 40px 25px;
  text-align: right;
`;

const SortWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
`;

const AddOptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 18px;
`;
const StyledDiv = styled.div`
  display: flex;
`;
const StyledIcon = styled.div`
  margin-left: 10px;
`;
const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 18px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr 100px 100px;
  grid-gap: 10px;
  background-color: #ebebeb;
  align-items: center;
  font-weight: 600;
  font-size: 17px;
  padding: 7px 5px;
  /* border-bottom: 1px solid #ebebeb; */
`;
const OptionWrapper = styled.div``;
const OptionRow = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr 100px 100px;
  grid-gap: 10px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  padding: 7px 5px;
  border-bottom: 1px solid #ebebeb;
`;
const ClearButton = styled.span`
  cursor: pointer;
  padding: 5px;
  border-radius: 15px;
  background-color: #ebebeb;
  color: #000;
  text-align: center;
  &:hover {
    color: #fff;
    background-color: #d6d6d6;
  }
  font-size: x-small;
  margin-left: 86px;
  width: max-content;
`;
const Icon = styled.i`
  cursor: pointer;
`;
const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 110px;
  grid-gap: 10px;
  border-bottom: 1px dashed #ebebeb;
  align-items: center;
`;
const ItemName = styled.span`
  font-weight: 500;
  font-size: 14px;
  padding-left: 50px;
`;
const OptionItemTable = styled.div`
  margin: 15px 0;
`;
const QuantityWraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EditOptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 16px;
  grid-gap: 10px;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 600;
`;

const ProductOptionsForm = ({
  setIsSortModalOpen,
  isExpanded,
  setIsExpanded,
  initialValues,
  allOptions,
  setProductOptionsAreEditable,
  productOptionsAreEditable,
  confirmCancelModalIsVisible,
  setConfirmCancelModalIsVisible,
  putProductOptions,
  disabled,
  optionWithDefault,
  putProductOptionsDefaultCustomization,
}) => {
  const currentAssignedOptionsIDs =
    initialValues.productOptions.map((item) => item.product_option_id) || [];
  const notAssignedOptions = allOptions.filter(
    (option) => !currentAssignedOptionsIDs.includes(option.id)
  );
  const [sourceOptionItems, setSourceOptionItems] = useState(
    JSON.stringify(optionWithDefault)
  );
  const [localOptionItems, setLocalOptionItems] = useState();
  const [isOptionsExpanded, setIsOptionsExpanded] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [removeOptionId, setRemoveOptionId] = useState(null);
  const [removeOptionName, setRemoveOptionName] = useState("");
  const [isRemoveConfirmModalOpen, setIsRemoveConfirmModalOpen] =
    useState(false);
  const [disableSaveInitially, setDisableSaveInitially] = useState(null);

  const sortObjectsByPosition = (originalData, positionsData) => {
    const positionMap = {};
    positionsData?.forEach((item) => {
      positionMap[item?.product_option_id] = item?.position;
    });

    return originalData?.sort(
      (a, b) => positionMap[a?.option_id] - positionMap[b?.option_id]
    );
  };
  const updateLocalOptionItems = useCallback(() => {
    const newIsOptionsExpaned =
      optionWithDefault !== null && optionWithDefault.map(() => false);
    const sortedLocalOptnItms = sortObjectsByPosition(
      optionWithDefault,
      initialValues?.productOptions
    );
    setLocalOptionItems(sortedLocalOptnItms);
    setSourceOptionItems(JSON.stringify(optionWithDefault));
    setIsOptionsExpanded(newIsOptionsExpaned);
  }, [optionWithDefault]);
  useEffect(() => {
    updateLocalOptionItems();
  }, [updateLocalOptionItems]);
  useEffect(() => {
    setDisableSaveInitially(true);
  }, []);
  const handleSetDefaultItem = (e, optionIndex, itemIndex, type) => {
    setIsExpanded(true);
    const newOptions = localOptionItems.reduce((acc, currOption, index) => {
      if (index === optionIndex) {
        const newOptionItems = currOption.option_items.reduce(
          (accItems, currItem, i) => {
            if (i === itemIndex) {
              accItems.push(
                type !== "qty"
                  ? {
                      ...currItem,
                      is_default: e.target?.checked || false,
                      quantity: 0,
                    }
                  : {
                      ...currItem,
                      quantity: isNaN(e.target.value)
                        ? 0
                        : parseInt(e.target.value),
                    }
              );
            } else {
              accItems.push({ ...currItem });
            }
            return accItems;
          },
          []
        );
        acc.push({ ...currOption, option_items: newOptionItems });
      } else {
        acc.push({ ...currOption });
      }
      return acc;
    }, []);
    setLocalOptionItems(newOptions);
  };
  const handleAddOptions = () => {
    const newProductOption = [...initialValues.productOptions];
    const maxPosition =
      Math.max(...initialValues.productOptions?.map((item) => item.position)) ||
      0;
    newProductOption.push({
      product_option_id: selectedOption.id,
      position: maxPosition + 1,
    });
    putProductOptions(newProductOption);
  };
  const handleOpenRemoveConfirmModal = (optionId, optionName) => {
    setRemoveOptionId(optionId);
    setRemoveOptionName(optionName);
    setIsRemoveConfirmModalOpen(true);
  };
  const handleCloseRemoveConfirmModal = () => {
    setRemoveOptionId(null);
    setIsRemoveConfirmModalOpen(false);
  };
  const handleRemoveOption = () => {
    const newProductOption = [...initialValues.productOptions];
    const index = newProductOption.findIndex(
      (item) => item.product_option_id === removeOptionId
    );
    newProductOption.splice(index, 1);
    putProductOptions(newProductOption);
    setIsRemoveConfirmModalOpen(false);
  };
  const checkDefaultCustomization = (values) => {
    let isDisabaled = disableSaveInitially;
    if (values?.length > 0 && !disableSaveInitially) {
      values?.forEach((option) => {
        option?.option_items?.forEach((item) => {
          if (item?.is_default && item?.quantity < 1) {
            isDisabaled = true;
          } else if (!item?.is_default) {
            isDisabaled = false;
          }
        });
      });
    }
    return isDisabaled;
  };
  const handleSubmitDefaultCustomizations = (values) => {
    const optionItems = values.reduce((acc, curr) => {
      if (curr.option_items.length > 0) {
        curr.option_items.forEach((item) => {
          if (item.is_default && item.quantity > 0) {
            acc.push({
              is_default: item.is_default,
              product_option_item_id: item.product_option_item_id,
              quantity: item.quantity,
            });
          }
        });
      }
      return acc;
    }, []);
    putProductOptionsDefaultCustomization(optionItems);
  };
  const expandOption = (index) => {
    const newIsOptionExpanded = [...isOptionsExpanded];
    newIsOptionExpanded[index] = !isOptionsExpanded[index];
    setIsOptionsExpanded(newIsOptionExpanded);
  };
  const clearDefaultItems = (index) => {
    setDisableSaveInitially(false);
    const newLocalOptionItem = [...localOptionItems];
    const newOptionItem = newLocalOptionItem[index].option_items.map((item) => {
      return { ...item, quantity: 0, is_default: false };
    });
    newLocalOptionItem[index].option_items = newOptionItem;
    setLocalOptionItems(newLocalOptionItem);
  };
  return (
    <Card
      disabled={disabled}
      // onClick={() => !disabled && setIsExpanded(!isExpanded)}
    >
      <Formik initialValues={localOptionItems} enableReinitialize={true}>
        {({ values, dirty, setValues }) => (
          <Fragment>
            <TitleAndCollapseButton>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Title>Options - Optional</Title>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  {isExpanded && (
                    <SortDiv>
                      <SortWrapper onClick={() => setIsSortModalOpen(true)}>
                        <i className='far fa-sort-amount-down' /> Reorder
                        Options
                        <Tooltip
                          style={{ marginRight: "auto" }}
                          title='Edit the position of options to change the order that they are presented in to the user'
                        >
                          <span>
                            <TooltipIcon marginLeft />
                          </span>
                        </Tooltip>
                      </SortWrapper>
                    </SortDiv>
                  )}
                  {!isExpanded ? (
                    <ChevronDownIcon
                      disabled={disabled}
                      onClick={() => setIsExpanded(true)}
                    />
                  ) : (
                    <ChevronUpIcon
                      disabled={dirty}
                      onClick={() => setIsExpanded(false)}
                    />
                  )}
                </div>
              </div>
            </TitleAndCollapseButton>
            {!isExpanded && (
              <OptionsSummary
                productOptionsInitialValues={initialValues.productOptions}
                allOptions={allOptions}
              />
            )}
            <Collapse in={isExpanded}>
              <AddOptionWrapper>
                <Autocomplete
                  id='country-select'
                  options={notAssignedOptions}
                  size='small'
                  style={{
                    width: "100%",
                  }}
                  onChange={(e, value) => {
                    setSelectedOption(value);
                  }}
                  getOptionLabel={(option) => `${option.name} - (${option.id})`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select Option'
                      variant='outlined'
                    />
                  )}
                />

                <Button disabled={!selectedOption} onClick={handleAddOptions}>
                  Add Option
                </Button>
              </AddOptionWrapper>
              <OptionItemTable>
                <HeaderRow>
                  <div></div>
                  <span>Option/Item/SubItem</span>
                  <CenterWrapper>Is Default?</CenterWrapper>
                  <CenterWrapper>Quantity</CenterWrapper>
                </HeaderRow>
                {values?.length > 0 &&
                  values.map((option, optionIndex) => (
                    <OptionWrapper key={`option-${option?.option_id}`}>
                      <OptionRow>
                        <Icon
                          className={
                            isOptionsExpanded[optionIndex]
                              ? "fa fa-minus-circle"
                              : "fa fa-plus-circle"
                          }
                          onClick={() => expandOption(optionIndex)}
                        ></Icon>
                        <StyledDiv>
                          {option.option_name}
                          <StyledIcon>
                            <Icon
                              className='fal fa-trash-alt'
                              onClick={() =>
                                handleOpenRemoveConfirmModal(
                                  option?.option_id,
                                  option.option_name
                                )
                              }
                            ></Icon>
                          </StyledIcon>
                        </StyledDiv>

                        <ClearButton
                          onClick={() => clearDefaultItems(optionIndex)}
                        >
                          Clear Default Selections
                        </ClearButton>
                      </OptionRow>
                      <Collapse in={isOptionsExpanded[optionIndex]}>
                        {option.option_items.map((item, itemIndex) => (
                          <ItemRow
                            key={`${item.name}-${item.product_option_item_id}`}
                          >
                            <ItemName>
                              {item.parent_name
                                ? `${item.name} - (${item.parent_name})`
                                : `${item.name}`}
                            </ItemName>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CustomCheckbox
                                checked={item?.is_default}
                                onChange={(e) => {
                                  setDisableSaveInitially(false);
                                  handleSetDefaultItem(
                                    e,
                                    optionIndex,
                                    itemIndex
                                  );
                                }}
                              />
                            </div>
                            <QuantityWraper>
                              {item.is_default ? (
                                <TextField
                                  type='number'
                                  value={item.quantity}
                                  variant='outlined'
                                  size='small'
                                  style={{ textAlign: "center", width: "70px" }}
                                  onChange={(e) => {
                                    if (e.target.value == 0) {
                                      setTimeout(() => {
                                        handleSetDefaultItem(
                                          false,
                                          optionIndex,
                                          itemIndex
                                        );
                                      }, 1500);
                                    }
                                    setDisableSaveInitially(false);
                                    handleSetDefaultItem(
                                      e,
                                      optionIndex,
                                      itemIndex,
                                      "qty"
                                    );
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      min: 0,
                                      max: item.maximum_pick,
                                    },
                                  }}
                                  error={parseInt(item.quantity) < 1}
                                  helperText={item.quantitys}
                                />
                              ) : (
                                <span style={{ textAlign: "center" }}>
                                  {item.quantity}
                                </span>
                              )}
                            </QuantityWraper>
                          </ItemRow>
                        ))}
                      </Collapse>
                    </OptionWrapper>
                  ))}
              </OptionItemTable>
              <ButtonRow justifyContent='space-between'>
                <Button
                  secondary
                  onClick={(e) =>
                    handleSetDefaultItem(e, optionIndex, itemIndex)
                  }
                >
                  Cancel
                </Button>
                <StyledSaveDefSelcsBtn>
                  <Button
                    disabled={checkDefaultCustomization(values)}
                    onClick={() => {
                      handleSubmitDefaultCustomizations(values);
                      setIsExpanded(false);
                    }}
                  >
                    Save Default Selections
                  </Button>
                </StyledSaveDefSelcsBtn>
              </ButtonRow>
            </Collapse>
            {confirmCancelModalIsVisible && (
              <ConfirmCancelModal
                onCancel={() => setConfirmCancelModalIsVisible(false)}
                onConfirm={() => {
                  setValues(JSON.parse(sourceOptionItems));
                  setConfirmCancelModalIsVisible(false);
                  setIsExpanded(false);
                }}
              />
            )}
          </Fragment>
        )}
      </Formik>
      {isRemoveConfirmModalOpen && (
        <RemoveOptionConfirmModal
          isOpen={isRemoveConfirmModalOpen}
          handleClose={handleCloseRemoveConfirmModal}
          handleRemoveOption={handleRemoveOption}
          optionName={removeOptionName}
        />
      )}
    </Card>
  );
};
export default ProductOptionsForm;
