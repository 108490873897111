import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  updateSelectedMultiPOSItem,
  getMultiPOSProduct,
} from '../multiPOSActions';
import styled from 'styled-components';
import { AppBar, Typography } from '@material-ui/core';
import { Button, FlatButton } from 'merchant-portal-components';

const SaveWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 10px;
  align-items: center;
  padding-left: 20px;
  padding-right: 50px;
`;

const SaveStickyBarComponent = ({
  handleDiscard,
  handleSave,
  selectedCategory,
}) => {
  const saveTitle = useMemo(() => {
    switch (selectedCategory.id) {
      case 0:
        return 'Product Mapping';
      case 1:
        return 'Items Mapping';
      case 2:
        return 'Combos Mapping';
      case 3:
        return 'Offers Mapping';
      case 4:
        return 'Credit Code Mapping';
      default:
        break;
    }
  }, [selectedCategory]);
  if (!selectedCategory) return null;
  return (
    <AppBar
      position='fixed'
      color='#fff'
      style={{
        top: 58,
        padding: '8px 0',
      }}
      enableColorOnDark
    >
      <SaveWrapper>
        <Typography
          variant='h6'
          color='primary'
          style={{
            textTransform: 'capitalize',
            alignItems: 'center',
            fontWeight: 600,
            width: '100%',
            paddingLeft: 310,
          }}
        >
          {saveTitle}
        </Typography>
        <FlatButton
          onClick={handleDiscard}
          style={{ width: 110, background: 'transparent' }}
        >
          Discard
        </FlatButton>
        <Button onClick={handleSave} style={{ width: 110 }}>
          Save
        </Button>
      </SaveWrapper>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
  selectedItem: state.multiPOSReducer.selectedItem,
  posItems: state.multiPOSReducer.posItems,
});
export const SaveStickyBar = connect(mapStateToProps, {
  updateSelectedMultiPOSItem,
  getMultiPOSProduct,
})(SaveStickyBarComponent);
