import React from 'react';
import { connect } from 'react-redux';
import { updateOfferEngineSource } from '../../OfferEngineActions';
import styled from 'styled-components';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import Card from '../../../../components/Card/Card';
import { useContext } from 'react';
import { CustomizationContext } from '../../../../utils/ClientCustomizationsProvider';

const Subtitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #191818;
`;
const SubHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 15px;
`;

const OfferTypeSelection = ({
  values,
  setFieldValue,
  setPosMappingStringValue,
  rewardOptions,
}) => {
  const { isOfferPremiumClient } = useContext(CustomizationContext);
  return (
    <>
      <Subtitle>Offer Type</Subtitle>
      <Card>
        <SubHead> Select offer type</SubHead>
        <FormControl>
          <Select
            className='mb25'
            labelId='select-reward-label'
            id='select-reward'
            value={values.offer_details.template_name || 'Select Offer Type'}
            placeholder='Select an Offer Type'
            label=''
            disabled={values.conditionValue?.length === 0}
            onChange={(e) => {
              if (e.target.value === 'first-time') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'dollar');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                  setFieldValue('offer_details.apply_multiple', false);
                  setFieldValue('offer_details.is_carryover', false);
                  setFieldValue('offer_details.sort_price', 'DESC');
                });
              } else if (e.target.value === 'free-item') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'dollar');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                  setFieldValue('offer_details.apply_multiple', false);
                  setFieldValue('offer_details.sort_price', 'DESC');
                });
              } else if (e.target.value === 'discounted-item') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'discount');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                });
              } else if (e.target.value === 'dollar-discount') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'dollar');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                });
              } else if (e.target.value === 'bonus-points') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'points');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                  setFieldValue('offer_details.apply_multiple', false);
                  setFieldValue('offer_details.sort_price', 'DESC');
                });
              } else if (e.target.value === 'points-multiplier') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(
                    `conditionValue[${i}].reward.type`,
                    'points-multiplier'
                  );
                  setFieldValue(`conditionValue[${i}].reward.amount`, 2);
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                  setFieldValue('offer_details.apply_multiple', false);
                  setFieldValue('offer_details.sort_price', 'DESC');
                });
              } else if (e.target.value === 'carryover-loyalty') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'dollar');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                  setFieldValue('offer_details.is_carryover', true);
                  setFieldValue('offer_details.apply_multiple', false);
                  setFieldValue('offer_details.sort_price', 'DESC');
                });
              } else {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward`, null);
                });
              }
              setPosMappingStringValue('');
              setFieldValue('offer_details.template_name', e.target.value);
            }}
          >
            {rewardOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={
                  !isOfferPremiumClient &&
                  (option.value === 'points-multiplier' ||
                    option.value === 'bonus-points')
                }
              >
                {option.label}{' '}
                {!isOfferPremiumClient &&
                (option.value === 'points-multiplier' ||
                  option.value === 'bonus-points')
                  ? '(Premium feature)'
                  : ''}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { updateOfferEngineSource })(
  OfferTypeSelection
);
