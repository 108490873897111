import React, { useCallback } from 'react';
import { updateOfferEngineSource } from '../../OfferEngineActions';
import styled from 'styled-components';
import Card from '../../../../components/Card/Card';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import MiniToggleSwitch from '../../../../components/ToggleSwitchSmall/ToggleSwitchSmall';
import { connect } from 'react-redux';
const Subtitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #191818;
`;
const SubHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 15px;
`;
const CheckBoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px auto;
  align-items: center;
`;
const OfferSourceSelection = ({
  values,
  setFieldValue,
  offerEngineSource,
  updateOfferEngineSource,
}) => {
  const handleActivateSource = useCallback(
    (e, source) => {
      const isActivated = e.target.checked;
      const newIndex = values.conditionValue?.findIndex(
        (s) => s.source?.toLowerCase() === source?.toLowerCase()
      );
      setFieldValue(`conditionValue[${newIndex}].is_active`, isActivated);
      const modifiedOfferEngineSource = offerEngineSource?.map((s) => {
        if (s.source === source) {
          return {
            ...s,
            is_active: isActivated,
          };
        } else {
          return { ...s };
        }
      });
      updateOfferEngineSource(modifiedOfferEngineSource);
    },
    [offerEngineSource, updateOfferEngineSource]
  );

  const handleSelectSource = useCallback(
    (event) => {
      const isSourceEnabled = event.target.checked;
      const sourceType = event.target.name?.toLowerCase();
      console.log('sourceType', sourceType);
      const modifiedOfferEngineSource = offerEngineSource?.map((source) => {
        if (source.source === sourceType) {
          return {
            ...source,
            is_selected: isSourceEnabled,
            is_active: isSourceEnabled,
          };
        } else {
          return { ...source };
        }
      });
      updateOfferEngineSource(modifiedOfferEngineSource);
      console.log('isSourceEnabled', isSourceEnabled);
      let newConditions = [...values.conditionValue];
      const selectedIndex = values.conditionValue
        .map((e) => e.source)
        .indexOf(sourceType);
      //////////////////
      if (isSourceEnabled) {
        let currentReward;
        let currentFixedRequirements = [];
        if (newConditions.length > 0) {
          if (values.conditionValue[0]?.requirements.length > 0) {
            values.conditionValue[0]?.requirements.forEach((req) => {
              if ('points' in req) currentFixedRequirements.push(req);
              if ('birthdate' in req) currentFixedRequirements.push(req);
              if ('tier' in req) currentFixedRequirements.push(req);
            });
          }
          currentReward = values.conditionValue[0]?.reward;
        }
        const newMobileSource = {
          source: sourceType,
          is_active: true,
          requirements: currentFixedRequirements,
          reward: currentReward || {
            type: 'dollar',
            amount: 10,
            products: [],
          },
        };
        newConditions.push(newMobileSource);
      } else {
        newConditions.splice(selectedIndex, 1);
      }
      setFieldValue('conditionValue', newConditions);

      //////////////////
      //   if (sourceType == 'mobile') {
      //   } else if (sourceType == 'web') {
      //     if (isSourceEnabled) {
      //       let currentReward;
      //       let currentFixedRequirements = [];
      //       if (newConditions.length > 0) {
      //         if (values.conditionValue[0]?.requirements.length > 0) {
      //           values.conditionValue[0]?.requirements.forEach((req) => {
      //             if ('points' in req) currentFixedRequirements.push(req);
      //             if ('birthdate' in req) currentFixedRequirements.push(req);
      //             if ('tier' in req) currentFixedRequirements.push(req);
      //           });
      //         }
      //         currentReward = values.conditionValue[0]?.reward;
      //       }
      //       const newWebSource = {
      //         source: 'web',
      //         is_active: true,
      //         requirements: currentFixedRequirements,
      //         reward: currentReward || {
      //           type: 'dollar',
      //           amount: 10,
      //           products: [],
      //         },
      //       };
      //       newConditions.push(newWebSource);
      //     } else {
      //       newConditions.splice(webIndex, 1);
      //     }
      //     setFieldValue('conditionValue', newConditions);
      //   } else {
      //     const filteredSource = offerEngineSource?.filter(
      //       (source) => !['web', 'mobile'].includes(source)
      //     )[0];
      //     if (isSourceEnabled) {
      //       let currentReward;
      //       let currentFixedRequirements = [];
      //       if (newConditions.length > 0) {
      //         if (values.conditionValue[0]?.requirements.length > 0) {
      //           values.conditionValue[0]?.requirements.forEach((req) => {
      //             if ('points' in req) currentFixedRequirements.push(req);
      //             if ('birthdate' in req) currentFixedRequirements.push(req);
      //             if ('tier' in req) currentFixedRequirements.push(req);
      //           });
      //         }
      //         currentReward = values.conditionValue[0]?.reward;
      //       }
      //       const newScanSource = {
      //         source: filteredSource,
      //         is_active: true,
      //         requirements: currentFixedRequirements,
      //         reward: currentReward || {
      //           type: 'dollar',
      //           amount: 10,
      //           products: [],
      //         },
      //       };
      //       newConditions.push(newScanSource);
      //     } else {
      //       newConditions.splice(scanIndex, 1);
      //     }
      //     setFieldValue('conditionValue', newConditions);
      //   }
    },
    [offerEngineSource, updateOfferEngineSource]
  );

  return (
    <>
      <Subtitle>Offer Source</Subtitle>
      <Card>
        <SubHead>Which source(s) does the offer apply to?</SubHead>
        <FormGroup>
          {offerEngineSource?.map((source, index) => (
            <CheckBoxContainer key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={source.source}
                    checked={source.is_selected}
                    onChange={handleSelectSource}
                    color='primary'
                  />
                }
                label={source?.source?.toUpperCase()}
              />
              <span>Active</span>
              <MiniToggleSwitch
                checked={source.is_active}
                onChange={(e) => {
                  handleActivateSource(e, source.source);
                }}
                disabled={!source.is_selected}
              />
            </CheckBoxContainer>
          ))}
        </FormGroup>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  offerEngineSource: state.OfferEngineReducer.offerEngineSource,
});

export default connect(mapStateToProps, { updateOfferEngineSource })(
  OfferSourceSelection
);
