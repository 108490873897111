import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { POSSelectionContainer } from './POSSelection/POSSelectionContainer';
import { POSDetailsContainer } from './POSDetails/POSDetailsContainer';
import {
  getMultiPOSBusinesses,
  getMultiPOSCombo,
  getMultiPOSCombos,
  getMultiPOSCoupon,
  getMultiPOSCoupons,
  getMultiPOSItem,
  getMultiPOSItems,
  getMultiPOSMappingFields,
  getMultiPOSOffer,
  getMultiPOSOffers,
  getMultiPOSProduct,
  getMultiPOSProducts,
  resetMultiPOSState,
  restOptions,
  restSubItems,
  updateSelectedMultiPOSCategory,
  updateSelectedMultiPOSItem,
} from './multiPOSActions';
import { useMultiPOS } from '../../../hooks/use-multi-pos';
import { renderMultiMappingOptions } from './utils/constants';
const Wrapper = styled.div`
  position: relative;
`;
const Title = styled.div`
  font-size: 32px;
  color: #191818;
  letter-spacing: 0.04px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 27px;
`;
const MultiPOS = ({
  selectedPOSType,
  resetMultiPOSState,
  history,
  selectedCategory,
  updateSelectedMultiPOSCategory,
  getMultiPOSMappingFields,
  getMultiPOSProducts,
  getMultiPOSItems,
  getMultiPOSCombos,
  getMultiPOSOffers,
  getMultiPOSCoupons,
  restOptions,
  updateSelectedMultiPOSItem,
  getMultiPOSProduct,
  getMultiPOSItem,
  getMultiPOSCombo,
  getMultiPOSOffer,
  getMultiPOSCoupon,
  getMultiPOSBusinesses,
  restSubItems,
}) => {
  const { handleCategorySelection, handleItemSelection } = useMultiPOS();

  useEffect(() => {
    const {
      type,
      product_id,
      product_name,
      option_id,
      option_name,
      combo_id,
      combo_name,
    } = history?.location?.state || {};
    if (!type) return;
    const id = product_id || option_id || combo_id;
    const name = product_name || option_name || combo_name;
    const selectedItem = {
      id,
      name,
    };
    const options = renderMultiMappingOptions();
    const deepLinkingPOS = async () => {
      let selectedCategory = options.find((option) =>
        option.name.toLowerCase().includes(type)
      );
      await handleCategorySelection(
        selectedCategory,
        updateSelectedMultiPOSCategory,
        getMultiPOSMappingFields,
        getMultiPOSProducts,
        getMultiPOSItems,
        getMultiPOSCombos,
        getMultiPOSOffers,
        getMultiPOSCoupons,
        restOptions
      );
      await handleItemSelection(
        selectedItem,
        selectedCategory,
        selectedPOSType,
        null,
        updateSelectedMultiPOSItem,
        getMultiPOSProduct,
        getMultiPOSItem,
        getMultiPOSCombo,
        getMultiPOSOffer,
        getMultiPOSBusinesses,
        restSubItems,
        getMultiPOSCoupon
      );
      history.replace({
        ...history.location,
        state: {},
      });
    };
    deepLinkingPOS();
  }, [
    history?.location?.state,
    handleCategorySelection,
    // selectedCategory?.id,
    updateSelectedMultiPOSCategory,
    getMultiPOSMappingFields,
    getMultiPOSProducts,
    getMultiPOSItems,
    getMultiPOSCombos,
    getMultiPOSOffers,
    getMultiPOSCoupons,
    restOptions,
    updateSelectedMultiPOSItem,
    getMultiPOSProduct,
    getMultiPOSItem,
    getMultiPOSCombo,
    getMultiPOSOffer,
    getMultiPOSCoupon,
    restSubItems,
    selectedPOSType,
    getMultiPOSBusinesses,
  ]);

  useEffect(() => {
    return () => {
      resetMultiPOSState();
    };
  }, [resetMultiPOSState]);
  return (
    <Wrapper>
      <Title>Multi POS Mapping</Title>
      <POSSelectionContainer history={history} />
      <POSDetailsContainer />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
});

export const MultiPOSContainer = connect(mapStateToProps, {
  resetMultiPOSState,
  updateSelectedMultiPOSCategory,
  getMultiPOSMappingFields,
  getMultiPOSProducts,
  getMultiPOSItems,
  getMultiPOSCombos,
  getMultiPOSOffers,
  getMultiPOSCoupons,
  restOptions,
  updateSelectedMultiPOSItem,
  getMultiPOSProduct,
  getMultiPOSItem,
  getMultiPOSCombo,
  getMultiPOSOffer,
  getMultiPOSCoupon,
  getMultiPOSBusinesses,
  restSubItems,
})(MultiPOS);
