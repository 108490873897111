import React from 'react';
import { Modal } from 'merchant-portal-components';
import { ButtonRow, Button } from 'merchant-portal-components';
import { ModalBody } from '../../components/FormElements/FormElements.js';
import styled from 'styled-components';

const ModalHeader = styled.div`
  display: grid;
  grid-template-columns: auto 20px;
  padding: ${(props) =>
    props.titleIsHidden ? '20px 20px 0px' : '24px 24px 10px'};
  font-size: 20px;
`;

const ModalFooter = styled.div`
  padding: 24px;
`;

const ConfirmModal = ({
  modalTitle,
  confirmMessage,
  actionLabel,
  onClose,
  onCancel,
  onClick,
  titleIsHidden,
  textAlign,
}) => {
  actionLabel = actionLabel || 'Confirm';
  onCancel = onCancel || onClose;
  return (
    <Modal borderRadius>
      <ModalHeader titleIsHidden={titleIsHidden}>
        {!titleIsHidden && (
          <>
            <div>{modalTitle}</div>
            <div
              onClick={onClose}
              style={{ cursor: 'pointer', justifySelf: 'center' }}
            >
              <i class='fa fa-times' />
            </div>
          </>
        )}
      </ModalHeader>

      <ModalBody padding={'24px'} medium>
        <p style={{ textAlign: textAlign || 'left' }}>{confirmMessage}</p>
      </ModalBody>
      <ModalFooter>
        <ButtonRow
          justifyContent={textAlign === 'center' ? textAlign : 'space-between'}
        >
          <Button
            small
            type='button'
            secondary
            onClick={(e) => {
              onCancel && onCancel();
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Cancel
          </Button>
          <Button
            small
            type='button'
            onClick={(e) => {
              onClick && onClick();
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {actionLabel}
          </Button>
        </ButtonRow>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
