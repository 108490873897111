import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const NoDataMessageWrapper = styled.div`
  min-width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
`;

const NoDataMessage = ({ type, searchString, selectedFilter }) => {
  let messageToShow = '';
  const ifOnActiveTab = searchString
    ? `No active ${type} found for '${searchString}'. Try checking the Inactive and Archive tabs for more results.`
    : `No active ${type} found. Try checking the Inactive and Archive tabs for more results.`;

  const ifOnInactiveTab = searchString
    ? `No inactive ${type}  found for '${searchString}'. Try checking the Active and Archive tabs for more results.`
    : `No inactive ${type} found. Try checking the Active or Archive tabs for more results.`;

  const ifOnArchivedTab = searchString
    ? `No archived ${type} found for '${searchString}'. Try checking the Active and Inactive tabs for more results.`
    : `No archived ${type} found. Try checking the Active or Inactive tabs for more results.`;

  switch (selectedFilter) {
    case 'active':
      messageToShow = ifOnActiveTab;
      break;
    case 'inactive':
      messageToShow = ifOnInactiveTab;
      break;
    case 'archived':
      messageToShow = ifOnArchivedTab;
      break;
    default:
      messageToShow = ifOnActiveTab;
  }
  return <NoDataMessageWrapper>{messageToShow}</NoDataMessageWrapper>;
};

export default NoDataMessage;
