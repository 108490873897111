import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import Pagination from '../../../../../components/Pagination/Pagination';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import {
  getMultiPOSBusinesses,
  updateMultiPOSRequestParams,
} from '../../multiPOSActions';
import { paginate } from '../../../../../components/FilterModal/paginate';
import { getPOSPayloadType } from '../../utils/constants';

const LocationTable = styled.div`
  padding: 15px;
`;

const LocationRow = styled.div`
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 0;
`;

const Location = styled.div``;

const OverrideTag = styled.span`
  background-color: #2440ae;
  color: #fff;
  margin-left: 10px;
  font-size: 11px;
  padding: 2px 5px;
  border-radius: 5px;
`;

const POSLocationContainerComponent = ({
  selectedCategory,
  selectedItem,
  posBusinesses,
  getMultiPOSBusinesses,
  selectedPOSType,
  requestParams,
  updateMultiPOSRequestParams,
  selectedSubitem,
}) => {
  const [searchString, setSearchString] = useState('');
  const [filteredLength, setFilteredLength] = useState(0);

  useEffect(() => {
    getMultiPOSBusinesses(
      selectedPOSType,
      getPOSPayloadType(selectedCategory.name),
      selectedItem.id,
      selectedSubitem?.id
    );
  }, [
    getMultiPOSBusinesses,
    selectedPOSType,
    selectedItem.id,
    selectedCategory.name,
  ]);

  const handleSearchChange = useCallback(
    (e) => {
      setSearchString(e.target.value);
      updateMultiPOSRequestParams({ ...requestParams, page_number: 1 });
    },
    [updateMultiPOSRequestParams, requestParams]
  );

  const businesses = useMemo(() => {
    const filteredBusinesses = posBusinesses.filter((posBusiness) =>
      posBusiness.business_name
        .toLowerCase()
        .includes(searchString.toLowerCase())
    );
    setFilteredLength(filteredBusinesses.length);
    return paginate(
      filteredBusinesses,
      requestParams.page_number,
      requestParams.page_size
    );
  }, [
    posBusinesses,
    searchString,
    requestParams.page_number,
    requestParams.page_size,
  ]);
  return (
    <>
      <TextField
        label='Search locations'
        variant='outlined'
        fullWidth
        margin='normal'
        value={searchString}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <i
              className='fal fa-search'
              style={{ color: '#c4c4c4', marginRight: 10 }}
            />
          ),
          endAdornment: (
            <i
              className='fal fa-times'
              style={{ color: '#c4c4c4', cursor: 'pointer' }}
              onClick={() => setSearchString('')}
            />
          ),
        }}
      />
      <LocationTable>
        {businesses.map((posBusiness) => (
          <LocationRow key={posBusiness.id}>
            <Location>
              {posBusiness.business_name}
              {posBusiness.is_overriden && <OverrideTag>Override</OverrideTag>}
            </Location>
          </LocationRow>
        ))}
      </LocationTable>
      <Pagination
        data-test='component-Pagination'
        response={{
          page_number: requestParams.page_number,
          page_size: requestParams.page_size,
          count: filteredLength,
        }}
        requestParams={requestParams}
        updateRequestParams={updateMultiPOSRequestParams}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
  selectedItem: state.multiPOSReducer.selectedItem,
  selectedSubitem: state.multiPOSReducer.selectedSubitem,
  posBusinesses: state.multiPOSReducer.posBusinesses,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  requestParams: state.multiPOSReducer.requestParams,
});

export const POSLocationContainer = connect(mapStateToProps, {
  getMultiPOSBusinesses,
  updateMultiPOSRequestParams,
})(POSLocationContainerComponent);
