export const buildProductPOSMappingForm = (
  mappingFields,
  posMappings,
  prices
) => {
  const variantField = 'product_variant_id';

  const newForm = Object.keys(posMappings).reduce((acc, posType) => {
    const fields = [
      variantField,
      ...mappingFields[posType].map((field) => field.key),
      'id',
    ];

    const newPOSMappingsValues = prices.map((price) => {
      const foundMapping = posMappings?.[posType].find(
        (pos) => pos[variantField] === price[variantField]
      );
      const variantRow = fields.reduce((acc, field) => {
        const defaultValue =
          mappingFields[posType] === true || mappingFields[posType] === false
            ? mappingFields[posType]
            : '';
        if (field === variantField) {
          acc[field] = price[variantField];
        } else {
          acc[field] = foundMapping ? foundMapping[field] : defaultValue;
        }
        return acc;
      }, {});
      return variantRow;
    });
    acc[posType] = newPOSMappingsValues;
    return acc;
  }, {});
  return newForm;
};

export const buildSingleProductPOSMapping = (
  mappingFields,
  posMapping,
  variantField
) => {
  const fields = [variantField, ...mappingFields, 'id'];
  const newPOSMapping = fields.reduce((acc, field) => {
    acc[field] = posMapping[field] || '';
    return acc;
  }, {});

  return newPOSMapping;
};

//// Combo, offer, coupon(credit code) Form
export const buildPOSMappingForm = (
  posTypes,
  mappingFields,
  posMappings,
  discount_id
) => {
  const posTypeNames = Object.keys(posTypes);

  const newForm = posTypeNames?.reduce((acc, posType) => {
    const fields = [
      'discount_id',
      ...mappingFields[posType].map((field) => field.key),
      'id',
    ];
    let newPOSMappingsValues = [];
    if (posMappings?.[posType].length === 0) {
      newPOSMappingsValues = [
        fields.reduce((acc, field) => {
          acc[field] = field === 'discount_id' ? discount_id : '';
          return acc;
        }, {}),
      ];
    } else {
      newPOSMappingsValues = posMappings?.[posType].map((pos) => {
        const variantRow = fields.reduce((acc, field) => {
          acc[field] = pos[field] || '';
          return acc;
        }, {});
        return variantRow;
      });
    }
    acc[posType] = newPOSMappingsValues;

    return acc;
  }, {});
  return newForm;
};
