import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { Button } from 'merchant-portal-components';
import { Collapse } from '@material-ui/core';
import { buildSingleProductPOSMapping } from '../../utils/buildPOSMappingForm';
import { MappingForm } from '../../components/MappingsForm';
import {
  deleteMultiPOSMappingOverride,
  updateIsEditingMultiPOS,
} from '../../multiPOSActions';
import { getPOSPayloadType } from '../../utils/constants';
import ConfirmModal from '../../../../../components/Modal/ConfirmModal';

const LocationWrapper = styled.div`
  margin-bottom: 30px;
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: 15px;
`;

const OverrideWrapper = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #e8e8e8;
`;
const FormWrapper = styled.div`
  margin-top: 30px;
`;
const BusinessWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 20px;
  align-items: center;
`;
const BusinessName = styled.div`
  color: #2440ae;
  font-weight: 600;
  cursor: pointer;
`;

const Icon = styled.i`
  cursor: pointer;
`;

const OverrideListComponent = ({
  selectedPOSMappingFields,
  setScreen,
  selectedPOSType,
  originalPOSMappingOverride,
  updateIsEditingMultiPOS,
  deleteMultiPOSMappingOverride,
  isEditing,
  selectedItem,
  selectedCategory,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOverride, setSelectedOverride] = useState([]);

  const currentPOSTypeFields = useMemo(() => {
    const posFields = selectedPOSMappingFields[selectedPOSType].map(
      (field) => field.key
    );
    return posFields;
  }, [selectedPOSType, selectedPOSMappingFields]);

  const overridePOSMappings = useMemo(() => {
    const selectedPOSOverrides = originalPOSMappingOverride[
      selectedPOSType
    ]?.reduce((acc, pos) => {
      const isBusinessExist = acc.find(
        (override) => override.business_id === pos.business_id
      );
      if (!isBusinessExist) {
        const newOverride = {
          business_id: pos.business_id,
          business_name: pos.business_name,
          mappings: [
            buildSingleProductPOSMapping(
              currentPOSTypeFields,
              pos,
              'product_variant_id'
            ),
          ],
        };
        acc.push(newOverride);
      } else {
        const businessIndex = acc.findIndex(
          (override) => override.business_id === pos.business_id
        );
        acc[businessIndex].mappings.push(
          buildSingleProductPOSMapping(
            currentPOSTypeFields,
            pos,
            'product_variant_id'
          )
        );
      }
      return acc;
    }, []);

    return [...new Set(selectedPOSOverrides)] || [];
  }, [originalPOSMappingOverride, currentPOSTypeFields]);

  const handleSelectPOS = useCallback((override) => {
    setSelectedOverride(override);
    updateIsEditingMultiPOS(false);
  }, []);

  const handleDeleteOverride = useCallback(async () => {
    await deleteMultiPOSMappingOverride(
      selectedOverride.mappings,
      selectedOverride.business_id,
      getPOSPayloadType(selectedCategory.name),
      selectedItem.id,
      selectedPOSType
    );
    setIsOpen(false);
  }, [selectedOverride, selectedPOSType, selectedItem?.id]);
  return (
    <>
      <LocationWrapper>
        <Button onClick={() => setScreen('add')}>
          <i className='fal fa-plus' style={{ marginRight: 5 }} />
          Add location override
        </Button>
      </LocationWrapper>
      {overridePOSMappings.map((overrideMapping, index) => (
        <OverrideWrapper key={`${overrideMapping.business_name}-${index}`}>
          <BusinessWrapper>
            <BusinessName onClick={() => handleSelectPOS(overrideMapping)}>
              {overrideMapping.business_name}
            </BusinessName>
            <Icon
              className='fa fa-pencil'
              onClick={() => {
                setSelectedOverride(overrideMapping);
                updateIsEditingMultiPOS(!isEditing);
              }}
            />
            <Icon
              className='fa fa-trash'
              onClick={(e) => {
                e.stopPropagation;
                setSelectedOverride(overrideMapping);
                setIsOpen(true);
              }}
            />
          </BusinessWrapper>
          <Collapse
            in={overrideMapping?.business_id === selectedOverride?.business_id}
          >
            <FormWrapper>
              <MappingForm
                initialValues={selectedOverride?.mappings ?? []}
                selectedBusinessId={overrideMapping.business_id}
                overrideAction='update'
                setScreen={setScreen}
              />
            </FormWrapper>
          </Collapse>
        </OverrideWrapper>
      ))}
      {isOpen && (
        <ConfirmModal
          textAlign='center'
          actionLabel='Confirm'
          confirmMessage='Are you sure you want to delete?'
          onClick={handleDeleteOverride}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  selectedItem: state.multiPOSReducer.selectedItem,
  originalPOSMappingOverride: state.multiPOSReducer.originalPOSMappingOverride,
  locationList: state.businessLocationsList.businessLocationsOfMerchant,
  selectedPOSMappingFields: state.multiPOSReducer.selectedPOSMappingFields,
  isEditing: state.multiPOSReducer.isEditing,
});

export const OverrideList = connect(mapStateToProps, {
  updateIsEditingMultiPOS,
  deleteMultiPOSMappingOverride,
})(OverrideListComponent);
