import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Card from '../../../../components/Card/Card';
import { Field } from 'formik';
import MiniToggleSwitch from '../../../../components/ToggleSwitchSmall/ToggleSwitchSmall';
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Requirement from '../../components/Requirement';
import { RenderTextField } from '../../../../components/FormElements/FormikElements';
const Subtitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #191818;
`;
const NotAvailable = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const IconDummy = styled.div`
  font-size: 60px;
  color: #ababab;
  text-align: center;
  width: 60px;
  margin-bottom: 15px;
`;
const WrapperInside = styled.div`
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  padding-top: 10px;
  .addProduct {
    background: none;
    border: 0px;
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
  }
`;
const FullWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 65px;
  align-items: center;
  .formSection {
    display: grid;
  }
`;
const NoteText = styled.div`
  font-size: 14px;
  color: #191818;
  margin-bottom: 25px;
`;
const NoteHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 5px;
`;
const NoteHeadNormal = styled.span`
  font-size: 17px;
  color: #191818;
  font-weight: normal;
`;
const Divider = styled.hr`
  border-bottom: 1px solid #ebebeb;
  margin-top: 15px;
  /* margin-bottom: 26px; */
`;
const ActionBox = styled.div`
  display: flex;
  padding-left: 15px;
  max-width: 120px;
`;
const SubtitleBlock = styled.div`
  margin-bottom: 15px;
`;
const SubHeadDummy = styled.div`
  font-size: 14px;
  color: #6f6f76;
  font-weight: normal;
  margin-bottom: 15px;
  max-width: 360px;
  margin: 0 auto;
  text-align: center;
`;
const TierMain = styled.div`
  padding-left: 15px;
`;
const NoteInside = styled.div`
  padding-left: 15px;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const SubNoteInside = styled.div`
  padding-left: 15px;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;
const SubHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 15px;
`;

const RequirementContainer = ({
  values,
  setFieldValue,
  offerEngineSource,
  pointPropertyIsEnabled,
  birthdayPropertyIsEnabled,
  setBirthdayPropertyIsEnabled,
  setTierPropertyIsEnabled,
  loyaltyHasError,
  tierPropertyIsEnabled,
  isSegmentVisible,
  setIsSegmentVisible,
  isConsumerVisible,
  setIsConsumerVisible,
  currentSortedSources,
  getSourceTitle,
  getItemIndex,
  allProductVariants,
  loyaltyTiers,
}) => {
  const pointIndex =
    values.conditionValue[0]?.requirements.length > 0
      ? values.conditionValue[0]?.requirements?.findIndex(
          (x) => x['points'] != undefined
        )
      : 0;

  const birthdateIndex =
    values.conditionValue[0]?.requirements.length > 0
      ? values.conditionValue[0]?.requirements?.findIndex(
          (x) => x['birthdate'] != undefined
        )
      : 0;
  const isTierEnabled = localStorage.getItem('is_tiers_enabled');
  const noSourceSelected = useMemo(() => {
    return offerEngineSource.every((source) => !source.is_selected);
  }, [offerEngineSource]);
  const handleModifiedLoyaltyTiers = (e, tierType) => {
    let newConditionValues = [...values.conditionValue];
    const isChecked = e.target.checked;
    const currentTiers = values.conditionValue[0].requirements[tierIndex].tier;
    if (isChecked) {
      values.conditionValue.forEach((con, index) => {
        newConditionValues[index].requirements[tierIndex].tier.push(tierType);
      });
    } else {
      values.conditionValue.forEach((con, index) => {
        const newTier = con.requirements[tierIndex].tier.filter(
          (ti) => ti !== tierType
        );
        newConditionValues[index].requirements[tierIndex].tier = newTier;
      });
    }
    setFieldValue('conditionValue', newConditionValues);
    setFieldValue(
      'offer_details.apply_multiple',
      values.offer_details.apply_multiple
    );
  };

  const handleToggleLoyaltyRequirements = (e) => {
    let newConditionValues = [...values.conditionValue];
    const isChecked = e.target.checked;
    setTierPropertyIsEnabled((prevState) => !prevState);
    if (isChecked) {
      handlePointsBirthdayTireActivation(e, 'tier');
    } else {
      values.conditionValue.forEach((_, index) => {
        newConditionValues[index].requirements = newConditionValues[
          index
        ]?.requirements.filter((req) => !('tier' in req));
      });
    }
    setFieldValue('conditionValue', newConditionValues);
  };
  const handlePointsBirthdayTireInputChange = (e) => {
    const keyValue = e.target.name;
    const newValue = e.target.value;
    let newConditionValue = [];
    if (keyValue == 'points') {
      values.conditionValue.forEach((condition, conIndex) => {
        let newCondition = { ...condition };
        condition?.requirements.forEach((req, reqIndex) => {
          if ('points' in req) {
            newCondition.requirements[reqIndex].points.point_cost =
              parseFloat(newValue);
          }
        });
        newConditionValue.push(newCondition);
      });
    }
    if (keyValue == 'birthday') {
      values.conditionValue.forEach((condition, conIndex) => {
        let newCondition = { ...condition };
        condition?.requirements.forEach((req, reqIndex) => {
          if ('birthdate' in req) {
            newCondition.requirements[reqIndex].birthdate.days_available =
              parseFloat(newValue);
          }
        });
        newConditionValue.push(newCondition);
      });
    }
    if (keyValue == 'tier') {
      let newConditionValue = [];
      values.conditionValue.forEach((condition) => {
        const newReq = condition?.requirements.filter(
          (req) => !('tier' in req)
        );
        newConditionValue.push({ ...condition, requirements: newReq });
      });
    }
    setFieldValue('conditionValue', newConditionValue);
  };
  const handleCopyRequirements = useCallback(
    (copyFrom, indexItem) => {
      // const newConditionValue = [...values.conditionValue];
      // if (copyFrom === 'web') {
      //   newConditionValue[mobileIndex].requirements =
      //     values.conditionValue[webIndex].requirements;
      // } else {
      //   newConditionValue[webIndex].requirements =
      //     values.conditionValue[mobileIndex].requirements;
      // }
      // setFieldValue('conditionValue', newConditionValue);

      //
      const newConditionValue = [...values.conditionValue];
      const conditionFrom = newConditionValue.find(
        (condition) => condition.source === copyFrom
      );
      if (conditionFrom) {
        newConditionValue[indexItem].requirements = conditionFrom.requirements;
        setFieldValue('conditionValue', newConditionValue);
      }
    },
    [values.conditionValue, setFieldValue]
  );
  const handlePointsBirthdayTireActivation = (event, keyValue) => {
    let newConditionValue = [];
    if (event.target.checked) {
      if (keyValue == 'points') {
        values.conditionValue.forEach((condition) => {
          let newCondition = { ...condition };
          newCondition?.requirements.push({ points: { point_cost: 0 } });
          newConditionValue.push(newCondition);
        });
        // setFieldValue('conditionValue', newConditionValue);
      }
      if (keyValue == 'birthday') {
        values.conditionValue.forEach((condition) => {
          let newCondition = { ...condition };
          newCondition?.requirements.push({ birthdate: { days_available: 7 } });
          newConditionValue.push(newCondition);
        });
        // setFieldValue('conditionValue', newConditionValue);
      }
      if (keyValue == 'tier') {
        values.conditionValue.forEach((condition) => {
          let newCondition = { ...condition };
          newCondition?.requirements.push({ tier: [] });
          newConditionValue.push(newCondition);
        });
      }
      setFieldValue('conditionValue', newConditionValue);
    } else {
      if (keyValue == 'points') {
        values.conditionValue.forEach((condition) => {
          let newCondition = { ...condition };
          const newReq = condition?.requirements.filter(
            (req) => !('points' in req)
          );
          newCondition.requirements = newReq;
          newConditionValue.push(newCondition);
        });
      }
      if (keyValue == 'birthday') {
        values.conditionValue.forEach((condition) => {
          const newReq = condition?.requirements.filter(
            (req) => !('birthdate' in req)
          );
          newConditionValue.push({ ...condition, requirements: newReq });
        });
      }
      if (keyValue == 'tier') {
        let newConditionValue = [];
        values.conditionValue.forEach((condition) => {
          const newReq = condition?.requirements.filter(
            (req) => !('tier' in req)
          );
          newConditionValue.push({ ...condition, requirements: newReq });
        });
      }
    }
    setFieldValue('conditionValue', newConditionValue);
  };
  return (
    <>
      {values.offer_details.template_name !== 'first-time' && (
        <>
          <Subtitle>
            Requirements and Cost <NoteHeadNormal>(Optional)</NoteHeadNormal>
          </Subtitle>
          <Card>
            {noSourceSelected ||
            !values.offer_details.template_name ||
            values.offer_details.template_name === 'Select Offer Type' ? (
              <NotAvailable>
                <div>
                  <IconDummy className='fas fa-coins'></IconDummy>
                </div>
                <SubHeadDummy>
                  You must select at least one offer source and an offer type
                  before you can specify requirements and cost
                </SubHeadDummy>
              </NotAvailable>
            ) : (
              <>
                <WrapperInside>
                  <FullWrapper>
                    <div className='formSection'>
                      <NoteHead>Point cost</NoteHead>
                      <NoteText>Offer has a point cost to redeem</NoteText>
                    </div>
                    <div>
                      <MiniToggleSwitch
                        name={'points'}
                        label=''
                        checked={pointPropertyIsEnabled}
                        onChange={(e) => {
                          handlePointsBirthdayTireActivation(e, 'points');
                          setPointPropertyIsEnabled((prevState) => !prevState);
                        }}
                      />
                    </div>
                  </FullWrapper>
                  {pointPropertyIsEnabled && (
                    <>
                      <NoteInside>
                        How many points does this offer cost to redeem?
                      </NoteInside>
                      <ActionBox>
                        <Field
                          name={'points'}
                          value={
                            values?.conditionValue[0]?.requirements[pointIndex]
                              ?.points?.point_cost
                          }
                          component={RenderTextField}
                          isPrice
                          label='Point cost'
                          onChange={handlePointsBirthdayTireInputChange}
                        />
                      </ActionBox>
                    </>
                  )}
                </WrapperInside>
                {values.offer_details.template_name !== 'carryover-loyalty' && (
                  <WrapperInside hasBlackDivider>
                    <FullWrapper>
                      <div className='formSection'>
                        <NoteHead>Birthday requirements</NoteHead>
                        <NoteText>
                          Customer is eligible for this offer during a specified
                          time from their birthday
                        </NoteText>
                      </div>
                      <div>
                        <MiniToggleSwitch
                          name={'birthday'}
                          label=''
                          checked={birthdayPropertyIsEnabled}
                          onChange={(e) => {
                            handlePointsBirthdayTireActivation(e, 'birthday');
                            setBirthdayPropertyIsEnabled(
                              (prevState) => !prevState
                            );
                          }}
                        />
                      </div>
                    </FullWrapper>
                    {birthdayPropertyIsEnabled && (
                      <>
                        <NoteInside>
                          When is this birthday offer available until?
                        </NoteInside>
                        <SubNoteInside>
                          The offer will begin on the customer’s birthday
                        </SubNoteInside>
                        <ActionBox>
                          <Field
                            name='birthday'
                            value={
                              values?.conditionValue[0]?.requirements[
                                birthdateIndex
                              ]?.birthdate?.days_available
                            }
                            component={RenderTextField}
                            placeholder='E.g. 3'
                            isPrice
                            label=''
                            onChange={handlePointsBirthdayTireInputChange}
                          />
                        </ActionBox>
                      </>
                    )}
                  </WrapperInside>
                )}
                {isTierEnabled === 'true' &&
                  values.offer_details.template_name !==
                    'carryover-loyalty' && (
                    <WrapperInside>
                      <FullWrapper>
                        <div className='formSection'>
                          <NoteHead>Loyalty requirements</NoteHead>
                          <NoteText>
                            Offer is available to customers in specified loyalty
                            tiers
                          </NoteText>
                        </div>
                        <div>
                          <MiniToggleSwitch
                            name={'tier'}
                            label=''
                            checked={tierPropertyIsEnabled}
                            onChange={handleToggleLoyaltyRequirements}
                          ></MiniToggleSwitch>
                        </div>
                      </FullWrapper>
                      {tierPropertyIsEnabled && (
                        <TierMain>
                          {loyaltyTiers.length > 0 &&
                            loyaltyTiers.map((tier, index) => (
                              <div>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name='tier 1'
                                      value={index + 1}
                                      checked={values.conditionValue[0].requirements[
                                        tierIndex
                                      ].tier.includes(index + 1)}
                                      onClick={(e) =>
                                        handleModifiedLoyaltyTiers(e, index + 1)
                                      }
                                    />
                                  }
                                  label={tier.name}
                                />
                              </div>
                            ))}
                        </TierMain>
                      )}
                      {loyaltyHasError && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '12px',
                            marginLeft: '15px',
                          }}
                        >
                          Select at least 1 tier
                        </div>
                      )}
                    </WrapperInside>
                  )}
                <br />
                {values.offer_details.template_name !== 'carryover-loyalty' && (
                  <>
                    {currentSortedSources.map((source, index) => (
                      <Requirement
                        source={source}
                        values={values}
                        title={getSourceTitle(source)}
                        subTitle={'Specify as many as you’d like'}
                        setFieldValue={setFieldValue}
                        isSegmentVisible={isSegmentVisible}
                        setIsSegmentVisible={setIsSegmentVisible}
                        isConsumerVisible={isConsumerVisible}
                        setIsConsumerVisible={setIsConsumerVisible}
                        indexItem={getItemIndex(source)}
                        allProductVariants={allProductVariants}
                        isLastItemInTheCard={
                          index === currentSortedSources.length - 1
                        }
                        handleCopyRequirements={handleCopyRequirements}
                      />
                    ))}
                  </>
                )}
              </>
            )}
          </Card>
        </>
      )}
      {(values?.offer_details?.template_name === 'first-time' ||
        values?.offer_details?.template_name === 'carryover-loyalty') && (
        <>
          <Subtitle>Dollar Discount Carryover</Subtitle>
          <Card>
            <SubHead>
              If the dollar discount is not used up in the purchase, can it
              carry over to the following purchase?
            </SubHead>
            <RadioGroup
              aria-labelledby='radio-availability-group-label'
              value={
                values.offer_details.template_name === 'carryover-loyalty'
                  ? 'true'
                  : values.offer_details.is_carryover == true
                  ? 'true'
                  : 'false'
              }
              id='radio-availability-group'
              name='radio-availability-group'
              onChange={(event) => {
                setFieldValue(
                  'offer_details.is_carryover',
                  event.target.value == 'true' ? true : false
                );
              }}
            >
              <FormControlLabel
                value='true'
                control={<Radio />}
                label='Yes'
                disabled={
                  values.offer_details.template_name === 'carryover-loyalty'
                }
              />
              <FormControlLabel
                value='false'
                control={<Radio />}
                label='No - discount must be used up in one purchase'
                disabled={
                  values.offer_details.template_name === 'carryover-loyalty'
                }
              />
            </RadioGroup>
          </Card>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  offerEngineSource: state.OfferEngineReducer.offerEngineSource,
  loyaltyTiers: state.OfferEngineReducer.loyaltyTiers,
});

export default connect(mapStateToProps, {})(RequirementContainer);
