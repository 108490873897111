import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import ProductInformationFormContainer from '../ProductInformationForm/containers/ProductInformationFormContainer';
import ProductCategoriesFormContainer from '../ProductCategoriesForm/containers/ProductCategoriesFormContainer';
import ProductVariantsFormContainer from '../ProductVariantsForm/containers/ProductVariantsFormContainer';
import ProductOptionsFormContainer from '../ProductOptionsForm/containers/ProductOptionsFormContainer';
import ProductTagsFormContainer from '../ProductTagsForm/containers/ProductTagsFormContainer';
import ProductTogglesFormContainer from '../ProductTogglesForm/containers/ProductTogglesFormContainer';
import { ChevronLeftIcon } from 'merchant-portal-components';
import ProductPreview from './ProductPreview/ProductPreview';
import ProductBusinessSelectionFormContainer from '../ProductBusinessSelectionForm/containers/ProductBusinessSelectionFormContainer';
import ProductPosMappingFormContainer from '../ProductPosMappingForm/containers/ProductPosMappingFormContainer';
import ProductBusinessesOverridesContainer from '../ProductBusinessesOverridesForm/containers/ProductBusinessesOverridesContainer';
import ProductArchiveContainer from '../ProductArchive/containers/ProductArchiveContainer';
import { CustomizationContext } from '../../../../../utils/ClientCustomizationsProvider';
import { ProductMultiPOSMappingFormContainer } from '../ProductMultiPOSMappingForm/ProductMultiPOSMappingFormContainer';

const ContentBody = styled.div`
  // width: 740px;
  margin: auto;
  display: grid;
  grid-gap: 24px;
`;

const BreadCrumbWrapper = styled.div`
  color: ${(props) => props.theme.darkBlue};
  margin: 30px;
  margin-left: 0;
  @media print {
    display: none;
  }
`;

const BackButton = styled.div`
  width: 176px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px 150px;
  grid-gap: 10px;
  align-items: center;

  &:hover {
    color: #3051d4;
  }
`;

const PageHeader = styled.h2`
  font-weight: 700;
  font-size: 36px;
`;

const ProductWizardIndex = (props) => {
  const { isMultiPOSMappingClient } = useContext(CustomizationContext);
  const {
    resetProductReducerState,
    getProductCategoriesIsRequesting,
    getProductVariantsIsRequesting,
    getProductOptionsIsRequesting,
    productPreviewIsVisible,
    setProductPreviewIsVisible,
    productCustomizerReducer,
    appConfig,
    isRequestingProductPosMappings,
    orderType,
    selectedProduct,
    optionWithDefault,
  } = props;
  const SCAN_AND_GO = '4';

  return (
    <Fragment>
      <BreadCrumbWrapper>
        <BackButton onClick={() => resetProductReducerState()}>
          <ChevronLeftIcon />
          <div>Back to Products</div>
        </BackButton>
        <PageHeader>Product Customizer</PageHeader>
      </BreadCrumbWrapper>
      <ContentBody>
        <ProductInformationFormContainer
          productPreviewIsVisible={productPreviewIsVisible}
          setProductPreviewIsVisible={setProductPreviewIsVisible}
        />

        {!getProductCategoriesIsRequesting && (
          <ProductCategoriesFormContainer />
        )}
        {!getProductVariantsIsRequesting && <ProductVariantsFormContainer />}
        {appConfig &&
          appConfig.pos_integration_enabled &&
          !getProductVariantsIsRequesting &&
          !isRequestingProductPosMappings && (
            <>
              {isMultiPOSMappingClient ? (
                <ProductMultiPOSMappingFormContainer
                  productId={selectedProduct?.id}
                />
              ) : (
                <ProductPosMappingFormContainer />
              )}
            </>
          )}
        {!getProductOptionsIsRequesting &&
          orderType &&
          !orderType.includes(SCAN_AND_GO) && (
            <ProductOptionsFormContainer
              optionWithDefault={optionWithDefault}
            />
          )}
        <ProductTagsFormContainer />
        <ProductBusinessSelectionFormContainer />
        <ProductTogglesFormContainer selectedProduct={selectedProduct} />
        {productPreviewIsVisible && productCustomizerReducer && (
          <ProductPreview
            productCustomizerReducer={productCustomizerReducer}
            onCancel={() => setProductPreviewIsVisible(false)}
          />
        )}
        <ProductBusinessesOverridesContainer />
        <ProductArchiveContainer />
      </ContentBody>
    </Fragment>
  );
};
export default ProductWizardIndex;
