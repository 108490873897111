import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ChevronDownIcon from '../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../components/Icon/ChevronUpIcon';
import { Collapse } from '@material-ui/core';
import {
  getMultiPOSMappingFields,
  getMultiPOSProduct,
  resetMultiPOSState,
} from '../../../MultiPOSMapping/multiPOSActions';
import { ProductPOSDetailsContainer } from './Form/ProductPOSDetailsContainer';
const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 24px 50px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 18px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const ProductMultiPOSMappingFormContainerComponent = ({
  productId,
  selectedProductId,
  defaultPOSMappingsForm,
  getMultiPOSProduct,
  getMultiPOSMappingFields,
  resetMultiPOSState,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    const id = productId || selectedProductId;
    const fetch = async () => {
      await getMultiPOSMappingFields('product');
      if (id) {
        await getMultiPOSProduct(id);
      }
    };
    fetch();
    return () => {
      resetMultiPOSState();
    };
  }, [productId]);

  return (
    <Wrapper>
      <TitleAndCollapseButton>
        <Title>Multi POS Mapping</Title>

        {!isExpanded ? (
          <ChevronDownIcon
            // disabled={disabled}
            onClick={() => setIsExpanded(true)}
          />
        ) : (
          <ChevronUpIcon
            // disabled={dirty}
            onClick={() => setIsExpanded(false)}
          />
        )}
      </TitleAndCollapseButton>
      <Collapse in={isExpanded}>
        <ProductPOSDetailsContainer />
      </Collapse>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  defaultPOSMappingsForm: state.multiPOSReducer.defaultPOSMappingsForm,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  selectedProductId: state.productsReducer.productInformation.id,
});

export const ProductMultiPOSMappingFormContainer = connect(mapStateToProps, {
  getMultiPOSProduct,
  getMultiPOSMappingFields,
  resetMultiPOSState,
})(ProductMultiPOSMappingFormContainerComponent);
