import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { MappingForm } from '../../components/MappingsForm';
import { Select } from 'merchant-portal-components';
import styled from 'styled-components';
import { useMemo } from 'react';

const BackButton = styled.div`
  margin-bottom: 15px;
  font-weight: 600;
  cursor: pointer;
`;
const LocationWrapper = styled.div`
  margin-bottom: 30px;
  max-width: 300px;
`;

const LocationTitle = styled.div`
  margin-bottom: 10px;
`;

const AddOverrideComponent = ({
  setScreen,
  posBusinesses,
  selectedPOSType,
  defaultPOSMappingsForm,
}) => {
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const handleSelectLocation = useCallback((business) => {
    setSelectedBusinesses(business);
  }, []);
  const filteredBusinesses = useMemo(() => {
    return posBusinesses.filter((business) => {
      return !business.is_overriden;
    });
  }, [posBusinesses]);
  return (
    <>
      <LocationWrapper>
        <BackButton onClick={() => setScreen('list')}>
          <i className='fal fa-arrow-left' /> Back to List
        </BackButton>
        <LocationTitle>Select locations you want to override</LocationTitle>
        <Select
          isMulti
          isSearchable
          light
          options={filteredBusinesses}
          getOptionValue={(business) => business.business_id}
          getOptionLabel={(business) => business.business_name}
          value={selectedBusinesses}
          onChange={(newBusinesses) => handleSelectLocation(newBusinesses)}
        />
      </LocationWrapper>
      {selectedBusinesses.length > 0 && (
        <MappingForm
          initialValues={defaultPOSMappingsForm?.[selectedPOSType] || []}
          selectedBusinessIds={selectedBusinesses.map(
            (business) => business.business_id
          )}
          overrideAction='add'
          setScreen={setScreen}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
  posBusinesses: state.multiPOSReducer.posBusinesses,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  defaultPOSMappingsForm: state.multiPOSReducer.defaultPOSMappingsForm,
  originalPOSMappingOverride: state.multiPOSReducer.originalPOSMappingOverride,
});
export const AddOverride = connect(mapStateToProps)(AddOverrideComponent);
