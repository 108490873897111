import React from 'react';
import { connect } from 'react-redux';

import { MappingForm } from '../../components/MappingsForm';

const DefaultMappingContainerComponent = ({
  defaultPOSMappingsForm,
  selectedPOSType,
}) => {
  return (
    <MappingForm
      initialValues={defaultPOSMappingsForm?.[selectedPOSType] || []}
      isDefault
    />
  );
};

const mapStateToProps = (state) => ({
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  defaultPOSMappingsForm: state.multiPOSReducer.defaultPOSMappingsForm,
});

export const DefaultMappingContainer = connect(mapStateToProps)(
  DefaultMappingContainerComponent
);
