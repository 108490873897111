import { useCallback } from 'react';
import { getPOSPayloadType } from '../modules/MenuManagement/MultiPOSMapping/utils/constants';

export const useMultiPOS = () => {
  const handleCategorySelection = useCallback(
    async (
      option,
      updateSelectedMultiPOSCategory,
      getMultiPOSMappingFields,
      getMultiPOSProducts,
      getMultiPOSItems,
      getMultiPOSCombos,
      getMultiPOSOffers,
      getMultiPOSCoupons,
      restOptions
    ) => {
      await restOptions();
      await updateSelectedMultiPOSCategory(option);
      let category = '';
      switch (option.id) {
        case 0:
          category = 'product';
          await getMultiPOSProducts();
          break;
        case 1:
          category = 'item';
          await getMultiPOSItems();
          break;
        case 2:
          category = 'discount';
          await getMultiPOSCombos();
          break;
        case 3:
          category = 'discount';
          await getMultiPOSOffers();
          break;
        case 4:
          category = 'discount';
          await getMultiPOSCoupons();
          break;
        default:
          break;
      }
      await getMultiPOSMappingFields(category);
    },
    []
  );

  const handleItemSelection = useCallback(
    async (
      option,
      selectedCategory,
      selectedPOSType,
      selectedSubitem,
      updateSelectedMultiPOSItem,
      getMultiPOSProduct,
      getMultiPOSItem,
      getMultiPOSCombo,
      getMultiPOSOffer,
      getMultiPOSBusinesses,
      restSubItems,
      getMultiPOSCoupon
    ) => {
      await updateSelectedMultiPOSItem(option);
      switch (selectedCategory?.id) {
        case 0:
          await getMultiPOSProduct(option.id);
          await getMultiPOSBusinesses(
            selectedPOSType,
            getPOSPayloadType(selectedCategory.name),
            option.id,
            selectedSubitem?.id
          );
          break;
        case 1:
          await restSubItems();
          await getMultiPOSItem(option.id);
          break;
        case 2:
          await getMultiPOSCombo(option.id);
          await getMultiPOSBusinesses(
            selectedPOSType,
            getPOSPayloadType(selectedCategory.name),
            option.id,
            selectedSubitem?.id
          );
          break;
        case 3:
          await getMultiPOSOffer(option.id);
          await getMultiPOSBusinesses(
            selectedPOSType,
            getPOSPayloadType(selectedCategory.name),
            option.id,
            selectedSubitem?.id
          );
          break;
        case 4:
          await getMultiPOSCoupon(option.id);
          await getMultiPOSBusinesses(
            selectedPOSType,
            getPOSPayloadType(selectedCategory.name),
            option.id,
            selectedSubitem?.id
          );
          break;
        default:
          break;
      }
    },
    []
  );

  return { handleCategorySelection, handleItemSelection };
};
