import api from '../../api';
import renderQueryString from '../../utils/renderQueryString';
import moment from 'moment';
import ReactGA from 'react-ga';
// new
export function resetAllReports() {
  return function (dispatch) {
    dispatch({
      type: 'RESET_ALL_REPORTS',
    });
  };
}
export function updateIsExportModalOpen(isExportModalOpen) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_IS_EXPORT_MODAL_OPEN',
      isExportModalOpen,
    });
  };
}
export function updateReportsRequestParams(params) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_REPORTS_REQUEST_PARAMS',
      params: params,
    });
  };
}

export function generateExportReport(params) {
  return function (dispatch) {
    const startDate = moment(params.start_datetime).set({
      hour: 0,
      minute: 0,
      second: 0,
    });

    const endDate = moment(params.end_datetime).set({
      hour: 23,
      minute: 59,
      second: 59,
    });
    const modifiedParams = {
      ...params,
      start_datetime: startDate.format('YYYY-MM-DD HH:mm:ss'),
      end_datetime: endDate.format('YYYY-MM-DD HH:mm:ss'),
    };
    if (modifiedParams.business_ids?.length === 0) {
      delete modifiedParams.business_ids;
    }
    return api
      .post(`/transactions/exports${renderQueryString(modifiedParams)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        dispatch({
          type: 'UPDATE_IS_EXPORT_MODAL_OPEN',
          isExportModalOpen: true,
        });
      })
      .catch((error) => {
        ReactGA.event({
          category: 'Unsuccessful Response',
          action: 'Getting Transactions',
          label: 'Unsuccessfully Getting Transactions',
        });
        // console.log(error);
      });
  };
}

export function getTransactionReport(params) {
  return function (dispatch) {
    const startTime = moment();
    const startDate = moment(params.start_datetime).set({
      hour: 0,
      minute: 0,
      second: 0,
    });

    const endDate = moment(params.end_datetime).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    const modifiedParams = {
      ...params,
      start_datetime: startDate.format('YYYY-MM-DD HH:mm:ss'),
      end_datetime: endDate.format('YYYY-MM-DD HH:mm:ss'),
    };
    const appIdentifier = localStorage.getItem('appIdentifier');
    return api
      .get(
        `/transactions${renderQueryString({
          ...modifiedParams,
          end_datetime:
            appIdentifier === 'mary_browns'
              ? startDate
                  .set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                  })
                  .format('YYYY-MM-DD HH:mm:ss')
              : modifiedParams.end_datetime,
        })}`,
        {
          ignoreInterceptors: true,
        }
      )
      .then((response) => {
        const endTime = moment();
        const responseTime = endTime.diff(startTime, 'millisecond');
        ReactGA.timing({
          category: 'Response Time',
          variable: 'Fetching',
          value: responseTime,
          label: 'Getting Transactions Response Time',
        });
        ReactGA.event({
          category: 'Successful Response',
          action: 'Getting Transactions',
          label: 'Successfully Getting Transactions',
        });
        dispatch({
          type: 'GET_ALL_TRANSACTION_REPORT_SUCCESSFUL',
          allTransactions: response.data.data,
        });
      })
      .catch((error) => {
        ReactGA.event({
          category: 'Unsuccessful Response',
          action: 'Getting Transactions',
          label: 'Unsuccessfully Getting Transactions',
        });
        // console.log(error);
      });
  };
}
export function clearTransactionReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_TRANSACTION_REPORT',
    });
  };
}
export function getOfferReport(params, setMessage) {
  const modifiedParams = {
    ...params,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
  };
  delete modifiedParams.start_datetime;
  delete modifiedParams.end_datetime;
  if ('business_ids' in params) {
    Object.assign(modifiedParams, {
      business_ids: params.business_ids,
    });
  }
  return function (dispatch) {
    return api
      .get(`report/offer${renderQueryString(modifiedParams)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        if (response.data?.data?.length === 0 && setMessage) {
          setMessage('No Data Found');
        }
        dispatch({
          type: 'GET_ALL_OFFER_REPORT_SUCCESSFUL',
          allOffers: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearOfferReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_OFFER_REPORT',
    });
  };
}
export function getCouponReport(params, setMessage) {
  const modifiedParams = {
    ...params,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
  };
  delete modifiedParams.start_datetime;
  delete modifiedParams.end_datetime;
  if ('business_ids' in params) {
    Object.assign(modifiedParams, {
      business_ids: params.business_ids,
    });
  }
  return function (dispatch) {
    return api
      .get(`/report/coupon${renderQueryString(modifiedParams)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        if (response.data?.data?.length === 0) {
          setMessage('No Data Found');
        }
        dispatch({
          type: 'GET_ALL_COUPON_REPORT_SUCCESSFUL',
          allCoupons: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearCouponReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_COUPON_REPORT',
    });
  };
}
export function getLoyaltyReport(params) {
  const modifiedParams = {
    page_number: params.page_number,
    page_size: params.page_size,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
  };
  return function (dispatch) {
    return api
      .get(
        `/report/loyalty?page_number=${modifiedParams.page_number}&page_size=${modifiedParams.page_size}&start_date=${modifiedParams.start_date}&end_date=${modifiedParams.end_date}&sort_by=business_name&sort_direction=asc`,
        {
          ignoreInterceptors: true,
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_ALL_LOYALTY_REPORT_SUCCESSFUL',
          allLoyalties: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearLoyaltyReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_LOYALTY_REPORT',
    });
  };
}

export function getProductReport(params) {
  const modifiedParams = {
    page_number: params.page_number,
    page_size: params.page_size,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
    sort_by: 'product_name',
    sort_direction: 'asc',
  };
  if ('business_ids' in params) {
    Object.assign(modifiedParams, {
      business_ids: params.business_ids,
    });
  }
  return function (dispatch) {
    return api
      .get(`/report/product${renderQueryString(modifiedParams)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        dispatch({
          type: 'GET_ALL_PRODUCT_REPORT_SUCCESSFUL',
          allProducts: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearProductReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_PRODUCT_REPORT',
    });
  };
}

export function getComboReport(params) {
  const modifiedParams = {
    page_number: params.page_number,
    page_size: params.page_size,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
    // sort_by: 'product_name',
    // sort_direction: 'asc',
  };
  if ('business_ids' in params) {
    Object.assign(modifiedParams, {
      business_ids: params.business_ids,
    });
  }
  return function (dispatch) {
    return api
      .get(`/report/combo${renderQueryString(modifiedParams)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        dispatch({
          type: 'GET_ALL_COMBO_REPORT_SUCCESSFUL',
          allCombos: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearComboReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_COMBO_REPORT',
    });
  };
}
export function getLocationReport(params) {
  const modifiedParams = {
    page_number: params.page_number,
    page_size: params.page_size,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
    sort_by: 'business_name',
    sort_direction: 'asc',
  };
  if ('business_ids' in params) {
    Object.assign(modifiedParams, {
      business_ids: params.business_ids,
    });
  }
  return function (dispatch) {
    return api
      .get(
        `/report/business/order-summary${renderQueryString(modifiedParams)}`,
        {
          ignoreInterceptors: true,
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_ALL_LOCATION_REPORT_SUCCESSFUL',
          allLocationOrders: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearLocationReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_LOCATION_REPORT',
    });
  };
}
export function getCustomerReport(
  params = {
    page_number: 1,
    page_size: 2000,
    sort_direction: 'asc',
  }
) {
  return function (dispatch, getState) {
    const curentCustomersList = getState().ReportTableReducer.allCustomers;
    return api
      .get(`/report/customer${renderQueryString(params)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        dispatch({
          type: 'GET_ALL_CUSTOMER_REPORT_SUCCESSFUL',
          allCustomers: curentCustomersList.concat(response.data.data),
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearCustomerReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_CUSTOMER_REPORT',
    });
  };
}

export function getExportReports(
  params = {
    page_number: 1,
    page_size: 50,
    type: 'transaction',
  }
) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_IS_EXPORT_REPORT_LOADING',
      isExportReportLoading: true,
    });
    return api
      .get(`/reports${renderQueryString(params)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        const modifiedExports = response.data.data.map((exportItem) => ({
          ...exportItem,
          id: parseInt(exportItem.id),
        }));
        dispatch({
          type: 'GET_ALL_EXPORT_REPORT_SUCCESSFUL',
          allExports: _.orderBy(modifiedExports, ['id'], ['desc']),
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}

export function getReportDownloadLink(reportId) {
  return function (dispatch, getState) {
    const currentExportURLs = getState().ReportTableReducer.allExportURLs;
    return api
      .get(`/reports/${reportId}/download`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        const modifiedExportURLs = currentExportURLs.map((exportItem) => ({
          ...exportItem,
          url:
            exportItem.id === reportId
              ? response.data.data.url
              : exportItem.url,
        }));
        dispatch({
          type: 'GET_REPORT_DOWNLOAD_LINK_SUCCESSFUL',
          allExportURLs: modifiedExportURLs,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
