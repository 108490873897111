import React, { useState } from 'react';
import { connect } from 'react-redux';

import { OverrideList } from './OverrideList';
import { AddOverride } from './AddOverride';

const OverrideMappingContainerComponent = ({}) => {
  const [screen, setScreen] = useState('list');

  return (
    <>
      {screen === 'list' ? (
        <OverrideList setScreen={setScreen} />
      ) : (
        <AddOverride setScreen={setScreen} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  originalPOSMappingOverride: state.multiPOSReducer.originalPOSMappingOverride,
  locationList: state.businessLocationsList.businessLocationsOfMerchant,
});
export const OverrideMappingContainer = connect(mapStateToProps)(
  OverrideMappingContainerComponent
);
