import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Card from '../../../../components/Card/Card';
import RewardDetails from '../../components/RewardDetail';
import OfferTypeSelection from './OfferTypeSelection';
const NotAvailable = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const IconDummy = styled.div`
  font-size: 60px;
  color: #ababab;
  text-align: center;
  width: 60px;
  margin-bottom: 15px;
`;
const SubHeadDummy = styled.div`
  font-size: 14px;
  color: #6f6f76;
  font-weight: normal;
  margin-bottom: 15px;
  max-width: 360px;
  margin: 0 auto;
  text-align: center;
`;
const SubHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 15px;
`;

const rewardOptions = [
  { value: 'Select Offer Type', label: 'Select Offer Type' },
  { value: 'first-time', label: 'First-time' },
  { value: 'free-item', label: 'Free products' },
  { value: 'discounted-item', label: 'Percentage discount' },
  { value: 'dollar-discount', label: 'Dollar discount' },
  { value: 'bonus-points', label: 'Bonus loyalty points' },
  { value: 'points-multiplier', label: 'Points Multiplier' },
  { value: 'carryover-loyalty', label: 'Carry-over' },
];

const OfferRewardContainer = ({
  values,
  setFieldValue,
  getSourceTitle,
  getItemIndex,
  isSegmentVisible,
  setIsSegmentVisible,
  isConsumerVisible,
  setIsConsumerVisible,
  offerEngineProducts,
  allProductVariants,
  errors,
  currentSortedSources,
  offerEngineSource,
}) => {
  const [posMappingStringValue, setPosMappingStringValue] = useState('');

  const noSourceSelected = useMemo(() => {
    return offerEngineSource.every((source) => !source.is_selected);
  }, [offerEngineSource]);

  const handleCopyRewards = useCallback(
    (copyFrom, indexItem) => {
      const newConditionValue = [...values.conditionValue];
      const conditionFrom = newConditionValue.find(
        (condition) => condition.source === copyFrom
      );
      if (conditionFrom) {
        newConditionValue[indexItem].reward = conditionFrom.reward;
        setFieldValue('conditionValue', newConditionValue);
      }
    },
    [values.conditionValue, setFieldValue]
  );
  return (
    <>
      <OfferTypeSelection
        values={values}
        setFieldValue={setFieldValue}
        setPosMappingStringValue={setPosMappingStringValue}
        rewardOptions={rewardOptions}
      />
      <Card>
        {(noSourceSelected ||
          !values.offer_details.template_name ||
          values.offer_details.template_name === 'Select Offer Type') && (
          <NotAvailable>
            <div>
              <IconDummy className='fas fa-coins'></IconDummy>
            </div>
            <SubHeadDummy>
              You must select at least one offer source and an offer type before
              you can specify offer details
            </SubHeadDummy>
          </NotAvailable>
        )}
        {values.offer_details.template_name &&
          values.offer_details.template_name !== 'Select Offer Type' && (
            <>
              <SubHead>Offer Details</SubHead>
              {currentSortedSources.map((source, index) => (
                <RewardDetails
                  source={source}
                  title={getSourceTitle(source)}
                  values={values}
                  setFieldValue={setFieldValue}
                  isSegmentVisible={isSegmentVisible}
                  setIsSegmentVisible={setIsSegmentVisible}
                  isConsumerVisible={isConsumerVisible}
                  setIsConsumerVisible={setIsConsumerVisible}
                  indexItem={getItemIndex(source)}
                  rewardOptions={rewardOptions}
                  offerEngineProducts={offerEngineProducts}
                  allProductVariants={allProductVariants}
                  errors={errors}
                  posMappingStringValue={posMappingStringValue}
                  setPosMappingStringValue={setPosMappingStringValue}
                  isLastItemInTheCard={
                    index === currentSortedSources.length - 1
                  }
                  handleCopyRewards={handleCopyRewards}
                />
              ))}
            </>
          )}
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  offerEngineSource: state.OfferEngineReducer.offerEngineSource,
  offerEngineProducts: state.OfferEngineReducer.offerEngineProducts,
  allProductVariants: state.productsReducer.allProductVariants,
});

export default connect(mapStateToProps, {})(OfferRewardContainer);
