import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Card from '../../../../components/Card/Card';
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';

const Subtitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #191818;
`;
const SubHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 15px;
`;
const SubHeadDummy = styled.div`
  font-size: 14px;
  color: #6f6f76;
  font-weight: normal;
  margin-bottom: 15px;
  max-width: 360px;
  margin: 0 auto;
  text-align: center;
`;
const IconDummy = styled.div`
  font-size: 60px;
  color: #ababab;
  text-align: center;
  width: 60px;
  margin-bottom: 15px;
`;
const NotAvailable = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const OfferApplyMultipleContainer = ({
  values,
  setFieldValue,
  offerEngineSource,
  hasFreeItemAndPoints,
}) => {
  const noSourceSelected = useMemo(() => {
    return offerEngineSource.every((source) => !source.is_selected);
  }, [offerEngineSource]);
  return (
    <>
      <Subtitle>Apply Multiple</Subtitle>
      <Card>
        {noSourceSelected ||
        !values.offer_details.template_name ||
        values.offer_details.template_name === 'Select Offer Type' ? (
          <NotAvailable>
            <div>
              <IconDummy className='fas fa-coins'></IconDummy>
            </div>
            <SubHeadDummy>
              You must select at least one offer source and an offer type before
              you can apply multiple
            </SubHeadDummy>
          </NotAvailable>
        ) : (
          <>
            <SubHead>
              If the customer has more than one eligible product in their
              basket, should the offer apply to just one selected product or all
              selected products?
            </SubHead>
            <RadioGroup
              aria-labelledby='radio-availability-group-label'
              value={
                values.offer_details.apply_multiple == true ? 'true' : 'false'
              }
              id='radio-availability-group'
              name='radio-availability-group'
              onChange={(event) => {
                setFieldValue(
                  'offer_details.apply_multiple',
                  event.target.value == 'true' ? true : false
                );
              }}
            >
              <FormControlLabel
                value='false'
                control={<Radio />}
                label='One'
                disabled={
                  values.offer_details.template_name === 'first-time' ||
                  values.offer_details.template_name === 'carryover-loyalty' ||
                  hasFreeItemAndPoints
                }
              />
              {values.offer_details.apply_multiple == false && (
                <div>
                  <FormControl>
                    <Select
                      className='mb25'
                      labelId='select-audience-label'
                      id='select-audience'
                      value={values.offer_details.sort_price}
                      label='Age'
                      onChange={(event) => {
                        setFieldValue(
                          'offer_details.sort_price',
                          event.target.value
                        );
                      }}
                      disabled={
                        values.offer_details.template_name === 'first-time' ||
                        values.offer_details.template_name ===
                          'carryover-loyalty' ||
                        hasFreeItemAndPoints
                      }
                    >
                      <MenuItem value='DESC'>
                        Highest-priced eligible product in basket
                      </MenuItem>
                      <MenuItem value='ASC'>
                        Lowest-priced eligible product in basket
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
              <FormControlLabel
                value='true'
                control={<Radio />}
                label='All'
                disabled={
                  values.offer_details.template_name === 'first-time' ||
                  values.offer_details.template_name === 'carryover-loyalty' ||
                  hasFreeItemAndPoints
                }
              />
            </RadioGroup>
          </>
        )}
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  offerEngineSource: state.OfferEngineReducer.offerEngineSource,
});

export default connect(mapStateToProps, {})(OfferApplyMultipleContainer);
