import {
  ROLE_SUPER_ADMIN,
  ROLE_ADMIN,
  ROLE_BUSINESS_ADMIN,
  ROLE_MARKETING_ADMIN,
  ROLE_FINANCE_ADMIN,
  ROLE_BUSINESS_SMOOTHPAY,
  ROLE_OPERATOR,
  ROLE_LOYALTY_ADMIN,
} from '../../utils/rolesRepository';

import {
  WEB_ORDERING_RAPID_DEPLOYMENT,
  WEB_ORDERING_ESSENTIALS,
  WEB_ORDERING_PLUS,
  MOBILE_APPS_ESSENTIALS,
  NO_IMAGE_CLIENT,
  NO_MOBILE_MESSAGES_CLIENT,
  NO_POS_MAPPING_CLIENT,
  NO_LOYALTY_CLIENT,
  NO_COMBOS_CLIENT,
} from './utils/clientPackageRepo';

export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fal fa-tachometer-alt',
      authorization: [
        ROLE_SUPER_ADMIN,
        ROLE_ADMIN,
        ROLE_MARKETING_ADMIN,
        ROLE_FINANCE_ADMIN,
        ROLE_LOYALTY_ADMIN,
      ],
      children: [
        {
          name: 'Users',
          url: '/users-dashboard',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_MARKETING_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'Transactions',
          url: '/transactions-dashboard',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_MARKETING_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'Top Locations',
          url: '/top-locations-dashboard',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_MARKETING_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_LOYALTY_ADMIN,
          ],
        },
      ],
    },

    {
      name: 'Engagement',
      url: '/admin',
      icon: 'fal fa-chart-line',
      authorization: [
        ROLE_SUPER_ADMIN,
        ROLE_ADMIN,
        ROLE_MARKETING_ADMIN,
        ROLE_LOYALTY_ADMIN,
      ],
      children: [
        {
          name: 'Mobile Messages',
          url: '/mobile-messages',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            WEB_ORDERING_PLUS,
            MOBILE_APPS_ESSENTIALS,
          ],
          hideDisplay: [NO_MOBILE_MESSAGES_CLIENT],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MARKETING_ADMIN],
        },
        {
          name: 'Offers',
          url: '/offers',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            MOBILE_APPS_ESSENTIALS,
          ],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MARKETING_ADMIN],
          hideDisplay: ['NEW_OFFER'],
        },
        {
          name: 'Offer Management',
          url: '/OfferEngine',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            // ROLE_BUSINESS_ADMIN,
            ROLE_MARKETING_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_BUSINESS_SMOOTHPAY,
            ROLE_LOYALTY_ADMIN,
          ],
          icon: 'fal fa-user-circle',
          hideDisplay: ['OLD_OFFER'],
        },
        {
          name: 'Credit Codes',
          url: '/credit-code-management',
          icon: '',
          disableForTiers: [MOBILE_APPS_ESSENTIALS],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MARKETING_ADMIN],
        },
        {
          name: 'Segments',
          url: '/segments',
          icon: 'fal fa-share-alt',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            MOBILE_APPS_ESSENTIALS,
          ],
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_MARKETING_ADMIN,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'Media',
          url: '/media',
          icon: 'fal fa-image',
          hideDisplay: [NO_IMAGE_CLIENT],
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_MARKETING_ADMIN,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'App Feed',
          url: '/app-feed',
          icon: 'fal fa-bullhorn',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            WEB_ORDERING_PLUS,
          ],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MARKETING_ADMIN],
        },
        {
          name: 'Layout Assignment',
          url: '/layout-assignment',
          icon: 'fal fa-bullhorn',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            WEB_ORDERING_PLUS,
          ],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MARKETING_ADMIN],
        },
      ],
      notOnSidebar: [
        {
          name: 'One Time Messages',
          url: '/mobile-messages/one-time-messages',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            WEB_ORDERING_PLUS,
            MOBILE_APPS_ESSENTIALS,
          ],
          hideDisplay: [NO_MOBILE_MESSAGES_CLIENT],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MARKETING_ADMIN],
        },
        {
          name: 'Automated Messages',
          url: '/mobile-messages/automated-messages',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            WEB_ORDERING_PLUS,
            MOBILE_APPS_ESSENTIALS,
          ],
          hideDisplay: [NO_MOBILE_MESSAGES_CLIENT],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MARKETING_ADMIN],
        },
        {
          name: 'Automated Messages',
          url: '/mobile-messages/automated-messages/:name',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            WEB_ORDERING_PLUS,
            MOBILE_APPS_ESSENTIALS,
          ],
          hideDisplay: [NO_MOBILE_MESSAGES_CLIENT],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MARKETING_ADMIN],
        },
      ],
    },

    {
      name: 'Menu Management',
      url: '/menu-management',
      icon: 'fal fa-list-alt',
      authorization: [
        ROLE_SUPER_ADMIN,
        ROLE_ADMIN,
        ROLE_OPERATOR,
        ROLE_LOYALTY_ADMIN,
      ],
      children: [
        {
          name: 'Categories',
          url: '/categories',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_OPERATOR],
        },
        {
          name: 'Variants',
          url: '/variants',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_OPERATOR],
        },
        {
          name: 'Options',
          url: '/options',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_OPERATOR],
        },
        {
          name: 'Products',
          url: '/products',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_OPERATOR],
        },
        {
          name: 'Option Price',
          url: '/option-price',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_OPERATOR],
        },
        {
          name: 'Combos',
          url: '/combos',
          disableForTiers: [NO_COMBOS_CLIENT],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_OPERATOR],
        },
        {
          name: 'POS Mapping',
          url: '/pos-mapping',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            MOBILE_APPS_ESSENTIALS,
          ],
          hideDisplay: [NO_POS_MAPPING_CLIENT],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
        },
        {
          name: 'Multi POS Mapping',
          url: '/multi-pos-mapping',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            MOBILE_APPS_ESSENTIALS,
          ],
          hideDisplay: [NO_POS_MAPPING_CLIENT],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
        },
        {
          name: 'Upsell',
          url: '/upsell',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            MOBILE_APPS_ESSENTIALS,
          ],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_OPERATOR],
        },
      ],
    },
    {
      name: 'Order Management',
      url: '/order-management',
      authorization: [
        ROLE_SUPER_ADMIN,
        ROLE_ADMIN,
        ROLE_MARKETING_ADMIN,
        ROLE_FINANCE_ADMIN,
        ROLE_BUSINESS_SMOOTHPAY,
      ],
      icon: 'fal fa-receipt',
    },
    {
      name: 'Customer Service',
      url: '/customer-service',
      icon: 'fal fa-user-edit',
      authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
      children: [
        {
          name: 'Order History',
          url: '/order-history',
          icon: 'fal fa-shopping-cart',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
        },
        {
          name: 'Customer Feedback',
          url: '/customer-feedback',
          icon: 'fal fa-shopping-cart',
          disableForTiers: [],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
        },
        {
          name: 'Customers',
          url: '/customers',
          icon: 'fal fa-user-edit',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
        },
      ],
      notOnSidebar: [
        {
          name: 'Customer Details',
          url: '/customers',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
        },
        {
          name: 'Order Details',
          url: '/order-history',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
        },
      ],
    },

    {
      name: 'Reports',
      url: '/finance',
      icon: 'fal fa-money-bill-alt',
      authorization: [
        ROLE_SUPER_ADMIN,
        ROLE_ADMIN,
        ROLE_BUSINESS_ADMIN,
        ROLE_FINANCE_ADMIN,
        ROLE_BUSINESS_SMOOTHPAY,
        ROLE_LOYALTY_ADMIN,
      ],
      children: [
        {
          name: 'Summary Report',
          url: '/summary-report',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_BUSINESS_ADMIN,
            ROLE_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_BUSINESS_SMOOTHPAY,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'Transaction Report',
          url: '/transaction-report',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_BUSINESS_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_BUSINESS_SMOOTHPAY,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'Offer Report',
          url: '/offer-report',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_BUSINESS_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_BUSINESS_SMOOTHPAY,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'Credit Code Report',
          url: '/credit-code-report',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_BUSINESS_SMOOTHPAY,
          ],
        },
        {
          name: 'Loyalty Report',
          url: '/loyalty-report',
          disableForTiers: [NO_LOYALTY_CLIENT],
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'Product Report',
          url: '/product-performance-report',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_BUSINESS_ADMIN,
          ],
        },
        {
          name: 'Combo Report',
          url: '/combo-performance-report',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_BUSINESS_ADMIN,
          ],
        },
        {
          name: 'Location Report',
          url: '/location-transaction-report',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_BUSINESS_ADMIN,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'Customer Report',
          url: '/customer-report',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_BUSINESS_ADMIN,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'Export Reports',
          url: '/export-report',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_BUSINESS_ADMIN,
            ROLE_LOYALTY_ADMIN,
          ],
        },
      ],
      notOnSidebar: [
        {
          name: 'Batch Details',
          url: '/settlements/:id',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_FINANCE_ADMIN,
            ROLE_BUSINESS_SMOOTHPAY,
          ],
        },
      ],
    },
    {
      name: 'Settings',
      url: '/settings',
      icon: 'fal fa-cog',
      authorization: [
        ROLE_SUPER_ADMIN,
        ROLE_ADMIN,
        ROLE_BUSINESS_ADMIN,
        ROLE_LOYALTY_ADMIN,
      ],
      children: [
        {
          name: 'Tags',
          url: '/tags',
          icon: 'fal fa-tag',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
          ],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
        },
        {
          name: 'Service Fee',
          url: '/fees',
          icon: 'fal fa-tag',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
          ],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
        },
        {
          name: 'Locations',
          url: '/locations',
          icon: 'fal fa-building',
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_BUSINESS_ADMIN,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'Archived locations',
          url: '/archivedlocations',
          icon: 'fal fa-building',
          dontShow: true,
          authorization: [
            ROLE_SUPER_ADMIN,
            ROLE_ADMIN,
            ROLE_BUSINESS_ADMIN,
            ROLE_LOYALTY_ADMIN,
          ],
        },
        {
          name: 'Portal Users',
          url: '/roles',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
        },
        {
          name: 'Order Tablet Users',
          url: '/orderTabletUsers',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
        },
        // {
        //   name: 'App Detail',
        //   url: '/app-detail',
        //   authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN]
        // }
      ],
      notOnSidebar: [
        {
          name: 'Locations Detail',
          url: '/locations/:businessLocationId',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
        },
        {
          name: 'View Delivery Zones',
          url: '/locations/:businessLocationId/delivery-zones/view/',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
        },
        {
          name: 'Create Delivery Zones',
          url: '/locations/:businessLocationId/delivery-zones/create/',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
        },
        {
          name: 'Edit Delivery Zone',
          url: '/locations/:businessLocationId/delivery-zones/edit/:deliveryZoneId',
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_LOYALTY_ADMIN],
        },
      ],
    },
    {
      name: 'Security',
      url: '/security',
      icon: 'fal fa-shield',
      authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
      children: [
        {
          name: 'Chargebacks',
          url: '/chargebacks',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
          ],
          authorization: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
        },
      ],
    },

    {
      name: 'Profile',
      url: '/password',
      authorization: [
        ROLE_SUPER_ADMIN,
        ROLE_ADMIN,
        ROLE_BUSINESS_ADMIN,
        ROLE_MARKETING_ADMIN,
        ROLE_FINANCE_ADMIN,
        ROLE_BUSINESS_SMOOTHPAY,
        ROLE_LOYALTY_ADMIN,
      ],
      icon: 'fal fa-user-circle',
    },
    // {
    //   name: 'Super Admin',
    //   url: '/super-admin',
    //   authorization: [ROLE_SUPER_ADMIN],
    //   icon: 'fal fa-user-circle',
    //   children: [
    //     {
    //       name: 'App Configurations',
    //       url: '/app-detail',
    //       authorization: [ROLE_SUPER_ADMIN],
    //     },
    //     {
    //       name: 'App Integrations',
    //       url: '/app-integration',
    //       authorization: [ROLE_SUPER_ADMIN],
    //     },
    //   ],
    // },
  ],
};
