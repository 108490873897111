import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { updateSelectedPOSType } from '../../../../MultiPOSMapping/multiPOSActions';
import { ProductMappingForm } from './ProductMappingsForm';
const Wrapper = styled.div`
  margin-top: 10px;
`;
const TabBar = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
`;
const CustomTab = styled.div`
  padding: 10px 20px;
  border-radius: 10px 10px 0 0;
  background-color: ${(props) => (props.active ? '#2440ae' : '#fff')};
  color: ${(props) => (props.active ? '#fff' : '#000')};
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 0;
`;
const TabPanel = styled.div`
  padding: 0 15px;
  border-radius: 15px;
  background-color: #ffffff;
  min-height: 200px;
  width: 100%;
`;

const ProductPOSDetails = ({
  posTypes,
  selectedPOSType,
  defaultPOSMappingsForm,
  updateSelectedPOSType,
}) => {
  const storedPOSTypes =
    posTypes ?? JSON.parse(localStorage.getItem('multiPOSTypes'));

  return (
    <Wrapper>
      <TabBar>
        {Object.keys(storedPOSTypes).map((posType) => (
          <CustomTab
            key={posType.id}
            active={selectedPOSType === posType}
            onClick={() => {
              updateSelectedPOSType(posType);
            }}
          >
            {posType} {storedPOSTypes[posType].is_default && '(Default)'}
          </CustomTab>
        ))}
      </TabBar>
      <TabPanel>
        <ProductMappingForm
          initialValues={defaultPOSMappingsForm?.[selectedPOSType] || []}
          isDefault
        />
      </TabPanel>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  posTypes: state.multiPOSReducer.posTypes,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  defaultPOSMappingsForm: state.multiPOSReducer.defaultPOSMappingsForm,
});

export const ProductPOSDetailsContainer = connect(mapStateToProps, {
  updateSelectedPOSType,
})(ProductPOSDetails);
