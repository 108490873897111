import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Select } from 'merchant-portal-components';
import {
  updateSelectedMultiPOSItem,
  getMultiPOSProduct,
  getMultiPOSCombo,
  getMultiPOSOffer,
  getMultiPOSCoupon,
  getMultiPOSItem,
  getMultiPOSBusinesses,
  restSubItems,
  handlePendingAction,
} from '../multiPOSActions';
import { getPOSPayloadType } from '../utils/constants';
import { useMultiPOS } from '../../../../hooks/use-multi-pos';
const ItemSelectComponent = ({
  posItems,
  selectedItem,
  selectedCategory,
  updateSelectedMultiPOSItem,
  getMultiPOSProduct,
  getMultiPOSItem,
  getMultiPOSCombo,
  getMultiPOSOffer,
  getMultiPOSCoupon,
  getMultiPOSBusinesses,
  selectedSubitem,
  selectedPOSType,
  restSubItems,
  handlePendingAction,
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const { handleItemSelection } = useMultiPOS();
  const placeholder = useMemo(() => {
    switch (selectedCategory?.id) {
      case 0:
        return 'Select Product';
      case 1:
        return 'Select Option';
      case 2:
        return 'Select Combo';
      case 3:
        return 'Select Offer';
      case 4:
        return 'Select Credit Code';
      default:
        return '';
    }
  }, [selectedCategory?.id]);

  return (
    <Select
      open={isSelectOpen}
      options={posItems}
      getOptionLabel={(option) => `${option.name} - ${option.id}`}
      getOptionValue={(option) => option.id}
      onOpen={(e) => {
        e.stopPropagation();
        handlePendingAction(() => {
          setIsSelectOpen(true);
        });
      }}
      onChange={(option) => {
        handleItemSelection(
          option,
          selectedCategory,
          selectedPOSType,
          selectedSubitem,
          updateSelectedMultiPOSItem,
          getMultiPOSProduct,
          getMultiPOSItem,
          getMultiPOSCombo,
          getMultiPOSOffer,
          getMultiPOSBusinesses,
          restSubItems,
          getMultiPOSCoupon
        );
      }}
      value={selectedItem}
      valueKey='id'
      required
      placeholder={placeholder}
      isDisabled={posItems?.length === 0}
      displayEmpty
    />
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
  selectedItem: state.multiPOSReducer.selectedItem,
  selectedSubitem: state.multiPOSReducer.selectedSubitem,
  posItems: state.multiPOSReducer.posItems,
});
export const ItemSelect = connect(mapStateToProps, {
  updateSelectedMultiPOSItem,
  getMultiPOSProduct,
  getMultiPOSCombo,
  getMultiPOSOffer,
  getMultiPOSCoupon,
  getMultiPOSItem,
  getMultiPOSBusinesses,
  restSubItems,
  handlePendingAction,
})(ItemSelectComponent);
