import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CategorySelect } from '../components/CategorySelect';
import { ItemSelect } from '../components/ItemSelect';
import { SubitemSelect } from '../components/SubitemSelect';
import { useEffect } from 'react';
import { updateSelectedMultiPOSCategory } from '../multiPOSActions';
import { renderMultiMappingOptions } from '../utils/constants';
const Wrapper = styled.div`
  border-radius: 15px;
  padding: 30px 20px;
  background-color: #ffffff;
`;
const Title = styled.div`
  font-size: 18px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 15px;
`;
const SelectionWrapper = styled.div`
  min-width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
`;
const POSSelection = ({ selectedCategory, updateSelectedMultiPOSCategory }) => {
  return (
    <Wrapper>
      <Title>Which part of the menu would you like to map?</Title>
      <SelectionWrapper>
        <CategorySelect history={history} />
        <ItemSelect />
        {selectedCategory?.id === 1 && <SubitemSelect />}
      </SelectionWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
});
export const POSSelectionContainer = connect(mapStateToProps, {
  updateSelectedMultiPOSCategory,
})(POSSelection);
