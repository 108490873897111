import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as actions from '../productsActions';
import * as selectors from '../productsSelectors';
import ProductList from './components/ProductList';
import { LoadingBlueLarge } from 'merchant-portal-components';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import { Button, Icon } from 'merchant-portal-components';
import { TextField, InputAdornment } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import fetchOnParamChange from '../../../HOCs/fetchOnParamChange';
import { sort } from '../../../../utils/sort/sort';
import _ from 'lodash';
import ReactGA from 'react-ga';
import { NoItemsFound } from '../../NoItemsFound';
import { InfoIcon } from '../../InfoIcon';

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
`;
const StyledTab = styled.div`
  margin: 10px;
  margin-left: 50px;
  display: flex;
  z-index: 50;
`;

const StyledSpanCD = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  background-color: #2440ae;
  color: white;
  padding-left: ${(props) => (props.isSD == true ? '5px' : '3.2px')};
  padding-right: ${(props) => (props.isSD == true ? '5px' : '3.2px')};
  padding-top: ${(props) => (props.isSD == true ? '2px' : '3.2px')};
  padding-bottom: ${(props) => (props.isSD == true ? '2px' : '3.2px')};
  font-size: 13.5px;
`;

const StyledNmbrSpan = styled.span`
  display: block;
  margin-top: 16%;
`;
const BtnTxtContnr = styled.div`
  display: inline-flex;
`;
const TxtContr = styled.div`
  margin-top: 6px;
  font-size: 18px;
  margin-right: 6px;
  color: #6f6f76;
`;

const StyledTabBtn = styled.button`
  width: 160px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isActive == false ? 'lightblue' : '#2440ae'};
  color: ${(props) => (props.isActive == false ? '#000' : '#fff')};
  background-color: ${(props) =>
    props.isActive == false ? '#fff' : '#E6EBFF'};
  color: ${(props) => (props.isActive == false ? '#DCDCDC' : '#2440ae')};
  font-weight: ${(props) => (props.isActive == false ? '' : '')};
  padding: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  z-index: 50;
`;
const TopRowTableCard = styled.div`
  padding: 15px 15px 0px;
`;
const ProductManager = ({
  archivedProducts,
  getArchivedProducts,
  allProducts,
  toggleProduct,
  searchString,
  setSearchString,
  requestParams,
  getProducts,
  initiateEditProductWizard,
  initiateCreateProduct,
  setSelectedProduct,
}) => {
  const [selectedFilter, setSelectedFilter] = useState('active');

  useEffect(() => {
    getArchivedProducts(requestParams);
  }, []);

  const productDisplayList = useMemo(() => {
    if (!allProducts || !archivedProducts) return [];
    switch (selectedFilter) {
      case 'active':
        return allProducts?.filter((each) => each.is_active == true);
      case 'inactive':
        return allProducts?.filter((each) => each.is_active == false);
      case 'archived':
        return archivedProducts;
      default:
        return allProducts;
    }
  }, [allProducts, archivedProducts, selectedFilter]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname, undefined, 'Product List');
  }, []);

  const toggleProductAvailability = (product) => {
    toggleProduct(product.id, !product.is_active);
  };

  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    setSearchString(searchString);
  };

  const handleClearSearch = () => {
    setSearchString('');
  };
  if (!allProducts) return <LoadingBlueLarge />;
  return (
    <>
      <BreadCrumb />
      <Wrapper>
        <TopRowTableCard>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '4fr 1fr',
              gridGap: '20px',
              justifyContent: 'space-between',
              margin: '37px 37px 0 37px',
            }}
          >
            <TextField
              type='text'
              style={{ width: '100%' }}
              variant='outlined'
              value={searchString}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icon className='fal fa-search' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='start'>
                    <Icon
                      className='fas fa-times-circle'
                      style={{ color: '#193053' }}
                      onClick={handleClearSearch}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              placeholder='Search by product name'
              disabled={!allProducts}
            />

            <Button
              style={{ fontSize: '16px', width: '100%' }}
              onClick={() => initiateCreateProduct()}
            >
              Create a Product
            </Button>
          </div>
        </TopRowTableCard>
        <br />
        {true && (
          <StyledTab>
            <div>
              <StyledTabBtn
                isActive={selectedFilter === 'active'}
                onClick={() => {
                  setSelectedFilter('active');
                }}
              >
                <BtnTxtContnr>
                  <TxtContr>Active</TxtContr> &nbsp;{' '}
                  <StyledSpanCD
                    isSD={
                      allProducts.filter((ech) => ech.is_active == true)
                        .length < 10
                    }
                  >
                    <StyledNmbrSpan>
                      {
                        allProducts.filter((ech) => ech.is_active == true)
                          .length
                      }
                    </StyledNmbrSpan>
                  </StyledSpanCD>
                </BtnTxtContnr>
              </StyledTabBtn>
            </div>
            <div>
              <StyledTabBtn
                isActive={selectedFilter === 'inactive'}
                onClick={() => {
                  setSelectedFilter('inactive');
                }}
              >
                <BtnTxtContnr>
                  <TxtContr>Inactive</TxtContr> &nbsp;{' '}
                  <StyledSpanCD
                    isSD={
                      !(
                        allProducts.filter((ech) => ech.is_active == false)
                          .length >= 10
                      )
                    }
                  >
                    <StyledNmbrSpan>
                      {
                        allProducts.filter((ech) => ech.is_active == false)
                          .length
                      }
                    </StyledNmbrSpan>
                  </StyledSpanCD>
                </BtnTxtContnr>
              </StyledTabBtn>
            </div>
            <div>
              <StyledTabBtn
                isActive={selectedFilter === 'archived'}
                onClick={() => {
                  setSelectedFilter('archived');
                }}
              >
                <BtnTxtContnr>
                  <TxtContr>Archived</TxtContr> &nbsp;{' '}
                  <StyledSpanCD isSD={archivedProducts.length < 10}>
                    <StyledNmbrSpan>{archivedProducts.length}</StyledNmbrSpan>
                  </StyledSpanCD>
                </BtnTxtContnr>
              </StyledTabBtn>
            </div>
            <InfoIcon includeArchive />
          </StyledTab>
        )}

        <ProductList
          selectedFilter={selectedFilter}
          products={productDisplayList}
          handleSearch={handleSearch}
          toggleProductAvailability={toggleProductAvailability}
          initiateEditProductWizard={initiateEditProductWizard}
          initiateCreateProduct={initiateCreateProduct}
          requestParams={requestParams}
          allProductsLength={2}
          searchString={searchString}
          handleClearSearch={handleClearSearch}
          getProducts={getProducts}
          setSelectedProduct={setSelectedProduct}
        />
      </Wrapper>
    </>
  );
};

function mapStateToProps(state) {
  return {
    requestingToggleProductIds:
      selectors.selectRequestingToggleProductIds(state),
    requestingGetProducts: state.productsReducer.requestingGetProducts,
    requestParams: state.productsReducer.requestParams,
    archivedProducts: state.productsReducer.archivedProducts,
  };
}

export default connect(mapStateToProps, actions)(ProductManager);
