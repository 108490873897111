import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Select } from 'merchant-portal-components';
import {
  updateSelectedMultiPOSItem,
  getMultiPOSProduct,
  getMultiPOSCombo,
  getMultiPOSOffer,
  getMultiPOSCoupon,
  getMultiPOSItem,
  getMultiPOSSubItem,
  getMultiPOSBusinesses,
} from '../multiPOSActions';
import { getPOSPayloadType } from '../utils/constants';
const SubitemSelectComponent = ({
  selectedCategory,
  selectedItem,
  selectedSubitem,
  posSubItems,
  getMultiPOSSubItem,
  selectedPOSType,
  getMultiPOSBusinesses,
}) => {
  const handleItemSelection = useCallback(
    (option) => {
      const { item } = option;
      getMultiPOSSubItem(selectedItem.id, item);
      getMultiPOSBusinesses(
        selectedPOSType,
        getPOSPayloadType(selectedCategory.name),
        selectedItem.id,
        option.id
      );
    },
    [selectedItem]
  );
  return (
    <Select
      options={posSubItems}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      onChange={(option) => {
        handleItemSelection(option);
      }}
      value={selectedSubitem}
      valueKey='id'
      required
      placeholder='Select item'
      isDisabled={posSubItems?.length === 0}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
  posSubItems: state.multiPOSReducer.posSubItems,
  selectedItem: state.multiPOSReducer.selectedItem,
  selectedSubitem: state.multiPOSReducer.selectedSubitem,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
});
export const SubitemSelect = connect(mapStateToProps, {
  updateSelectedMultiPOSItem,
  getMultiPOSProduct,
  getMultiPOSCombo,
  getMultiPOSOffer,
  getMultiPOSCoupon,
  getMultiPOSItem,
  getMultiPOSSubItem,
  getMultiPOSBusinesses,
})(SubitemSelectComponent);
