export const GET_MULTI_POS_ITEMS = 'GET_MULTI_POS_ITEMS';
export const GET_MULTI_POS_MAPPING_FIELDS = 'GET_MULTI_POS_MAPPING_FIELDS';
export const GET_MULTI_POS_MAPPING_PRODUCT = 'GET_MULTI_POS_MAPPING_PRODUCT';
export const GET_MULTI_POS_MAPPING_ITEM = 'GET_MULTI_POS_MAPPING_ITEM';
export const GET_MULTI_POS_MAPPING_SUBITEM = 'GET_MULTI_POS_MAPPING_SUBITEM';
export const GET_MULTI_POS_MAPPING_COMBO = 'GET_MULTI_POS_MAPPING_COMBO';
export const GET_MULTI_POS_BUSINESSES = 'GET_MULTI_POS_BUSINESSES';
export const UPDATE_MULTI_POS_SELECTED_CATEGORY =
  'UPDATE_MULTI_POS_SELECTED_CATEGORY';
export const UPDATE_MULTI_POS_SELECTED_ITEM = 'UPDATE_MULTI_POS_SELECTED_ITEM';
export const UPDATE_MULTI_POS_TYPES = 'UPDATE_MULTI_POS_TYPES';
export const UPDATE_SELECTED_POS_TYPE_TAB = 'UPDATE_SELECTED_POS_TYPE_TAB';
export const UPDATE_MULTI_POS_REQUEST_PARAMS =
  'UPDATE_MULTI_POS_REQUEST_PARAMS';
export const UPDATE_MULTI_POS_DETAIL_TAB = 'UPDATE_MULTI_POS_DETAIL_TAB';
export const UPDATE_IS_EDITING_MULTI_POS = 'UPDATE_IS_EDITING_MULTI_POS';
export const RESET_CATEGORY_OPTION = 'RESET_CATEGORY_OPTION';
export const RESET_CATEGORY_SUBITEM = 'RESET_CATEGORY_SUBITEM';
