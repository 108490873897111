import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { handlePendingAction, updateSelectedPOSType } from '../multiPOSActions';
import { POSDetailTabs } from './POSDetails';
const Wrapper = styled.div`
  margin-top: 20px;
`;
const TabBar = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 25px;
`;
const CustomTab = styled.div`
  padding: 10px 20px;
  border-radius: 10px 10px 0 0;
  background-color: ${(props) => (props.active ? '#2440ae' : '#d6d4d4')};
  color: ${(props) => (props.active ? '#fff' : '#adacac')};
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 0;
`;
const TabPanel = styled.div`
  border-radius: 15px;
  padding: 30px 20px;
  background-color: #ffffff;
  min-height: 200px;
  width: 100%;
`;

const POSDetails = ({
  selectedCategory,
  selectedItem,
  selectedSubitem,
  posTypes,
  selectedPOSType,
  updateSelectedPOSType,
  handlePendingAction,
}) => {
  const storedPOSTypes =
    posTypes ?? JSON.parse(localStorage.getItem('multiPOSTypes'));
  if (
    (!selectedItem && selectedCategory?.id !== 1) ||
    (!selectedSubitem && selectedCategory?.id === 1) ||
    !storedPOSTypes
  )
    return null;
  return (
    <Wrapper>
      <TabBar>
        {Object.keys(storedPOSTypes).map((posType) => (
          <CustomTab
            key={posType.id}
            active={selectedPOSType === posType}
            onClick={() => {
              handlePendingAction(() => {
                updateSelectedPOSType(posType);
              });
            }}
          >
            {posType?.toUpperCase()}
            {storedPOSTypes[posType].is_default && '(Default)'}
          </CustomTab>
        ))}
      </TabBar>
      <TabPanel>
        <POSDetailTabs
          currentPOSType={Object.keys(storedPOSTypes)[selectedPOSType]}
        />
      </TabPanel>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.multiPOSReducer.selectedCategory,
  selectedItem: state.multiPOSReducer.selectedItem,
  selectedSubitem: state.multiPOSReducer.selectedSubitem,
  posTypes: state.multiPOSReducer.posTypes,
  selectedPOSType: state.multiPOSReducer.selectedPOSType,
});

export const POSDetailsContainer = connect(mapStateToProps, {
  updateSelectedPOSType,
  handlePendingAction,
})(POSDetails);
