import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Field, FieldArray } from 'formik';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import {
  RenderSelect,
  RenderTextField,
} from '../../../components/FormElements/FormikElements';
import { SmallToggleSwitch } from '../../../components/ToggleSwitch/ToggleSwitch';
import { MiniToggleSwitch } from '../../../components/ToggleSwitchSmall/ToggleSwitchSmall';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import FilterButton from '../../../components/FilterModal/FilterButton';
import FilterProductVariantsModal from '../../../components/FilterModal/FilterProductVariantsModal';
import { Button } from 'merchant-portal-components';
import _ from 'lodash';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      minWidth: '100%',
    },
  },
}));
const Wrapper = styled.div`
  display: grid;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: ${(props) =>
    props.isLastItem ? 'none' : '2px solid #000000'};
  .mb25 {
    margin-bottom: 25px;
  }
`;
const ProductMain = styled.div`
  display: ${(props) => (props.isNotGrid ? 'block' : 'grid')};
  grid-template-columns: 250px 1fr 65px;
  grid-gap: 30px;
  padding: 10px 0px;
  border-bottom: 1px solid #ebebeb;

  .buttonDelete {
    background: none;
    border: 0px;
  }
  .MuiInputBase-input::placeholder {
    color: #000;
    opacity: 1;
  }
`;
const WrapperInner = styled.div`
  display: grid;
  align-items: center;
  margin-left: 30px;
`;
const Subtitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #191818;
`;
const FullWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 65px;
  align-items: center;

  .formSection {
    display: grid;
  }
`;
const NoteText = styled.div`
  font-size: 14px;
  color: #191818;
  margin-bottom: 10px;
`;
const NoteHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 5px;
`;
const NoteHeadNormal = styled.span`
  font-size: 17px;
  color: #191818;
  font-weight: normal;
`;
const SubHead = styled.div``;
const CopyButtonWrapper = styled.div`
  margin: 10px 12px;
  display: flex;
  justify-content: flex-end;
`;

const SegmentWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  margin-bottom: 20px;

  .react-select__control {
    background-color: white;
    border-radius: 6px;
  }

  .react-select__single-value {
    font-weight: 600;
    font-size: 14px;
  }

  .react-select__multi-value {
    background-color: #e6e6e6;
    border-radius: 15px;
    padding: 1px 3px;
    font-weight: 400;
    font-size: 14px;
  }
`;
const WrapperInside = styled.div`
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  padding-top: 10px;

  .addProduct {
    background: none;
    border: 0px;
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
  }
`;
const WrapperInsideHead = styled.div`
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 15px;
  margin: 15px 0;
`;
const NoteTextBold = styled.div`
  font-size: 14px;
  color: #191818;
  margin-bottom: 10px;
  font-weight: 600;
`;
const TitleMain = styled.div`
  font-size: 21px;
  font-weight: 600;
  color: #191818;
`;
const SubtitleBlock = styled.div`
  margin-bottom: 15px;
`;
const NoteInside = styled.div`
  padding-left: 15px;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const ActionBox = styled.div`
  display: flex;
  padding-left: 15px;
  max-width: 120px;
`;

const SubnoteInside = styled.div`
  padding-left: 15px;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;
const WrapperInnerMain = styled.div`
  padding-left: 15px;
`;
const TierMain = styled.div`
  padding-left: 15px;
`;
const ErrorSpan = styled.div`
  color: red;
  font-size: 12px;
`;
const Requirement = ({
  title,
  values,
  setFieldValue,
  indexItem,
  subTitle,
  allProductVariants,
  isLastItemInTheCard,
  handleCopyRequirements,
  source,
}) => {
  const productIndex =
    values.conditionValue[indexItem]?.requirements.length > 0
      ? values.conditionValue[indexItem]?.requirements?.findIndex(
          (x) => x['product'] != undefined
        )
      : 0;

  const [isOpen, setIsOpen] = useState(false);
  const [formKey, setFormKey] = useState('');
  const [isAllProductsSelected, setIsAllProductsSelected] = useState(false);
  // const [pointPropertyIsEnabled, setPointPropertyIsEnabled] = useState(false);
  const [productPropertyIsEnabled, setProductPropertyIsEnabled] =
    useState(false);

  const [posMappingStringValue, setPosMappingStringValue] = useState('');

  const isSourcePOS = !['mobile', 'web'].includes(source);

  const updateInitials = useCallback(() => {
    let productsIsEnabled = false;
    values.conditionValue[indexItem]?.requirements.forEach((req) => {
      if ('product' in req) {
        productsIsEnabled = true;
      }
    });
    setProductPropertyIsEnabled(productsIsEnabled);
  }, [values.conditionValue[indexItem]?.requirements]);
  useEffect(() => {
    updateInitials();
  }, [updateInitials]);
  useEffect(() => {
    if (
      values.conditionValue[indexItem]?.requirements.length > 0 &&
      !['web', 'mobile'].includes(values.conditionValue[indexItem]?.source)
    ) {
      let posMappingString = JSON.stringify(
        values.conditionValue[indexItem]?.requirements[
          productIndex
        ]?.product?.products.map((pro) => pro.product_id)
      );
      posMappingString = posMappingString?.substring(
        1,
        posMappingString.length - 1
      );
      posMappingString = posMappingString?.replaceAll('"', '');
      setPosMappingStringValue(posMappingString);
    }
  }, [indexItem, productIndex]);
  useEffect(() => {
    let count = 0;
    allProductVariants?.forEach((product) => {
      if (product.prices?.length > 0) {
        product.prices.forEach((variant) => count++);
      } else {
        count++;
      }
    });
    const mappedProductVariants =
      values.conditionValue[indexItem]?.requirements[productIndex]?.product
        ?.products;

    if (count === mappedProductVariants?.length) {
      setIsAllProductsSelected(true);
    } else {
      setIsAllProductsSelected(false);
    }
  }, [
    values.conditionValue[indexItem]?.requirements[productIndex]?.product
      ?.products,
    allProductVariants,
  ]);

  let findIndexPoints =
    values.conditionValue[indexItem]?.requirements.length > 0
      ? values.conditionValue[indexItem].requirements?.findIndex(
          (p) => p.attr1 == 'points'
        )
      : 0;
  //ok
  const handleChange = (event, keyValue) => {
    if (event.target.checked) {
      let newConditionValue = [...values.conditionValue];
      newConditionValue[indexItem].requirements.push({
        product: {
          products: [],
          condition: 'AND',
        },
      });
      setFieldValue('conditionValue', newConditionValue);
    } else {
      let newConditionValue = [...values.conditionValue];
      findIndexPoints = values.conditionValue[indexItem].requirements.findIndex(
        (req) => 'product' in req
      );
      newConditionValue[indexItem].requirements = values.conditionValue[
        indexItem
      ].requirements.filter((req) => !('product' in req));
      setFieldValue('conditionValue', newConditionValue);
    }
  };

  const handleOpenProductVariantsModal = (key) => {
    setFormKey(key);
    setIsOpen(true);
  };
  const handleChangePOSIDs = (e, setFieldValue, indexItem, productIndex) => {
    const field = e.target.name;
    const value = e.target.value;
    let formatedFiledValue = [];
    let filedValue = value.split(',');
    filedValue.forEach((item) => {
      formatedFiledValue.push({ product_id: item });
      // if (!isNaN(item) && !isNaN(parseFloat(item))) {
      // }
    });

    const hasConditionField =
      values.conditionValue[indexItem]?.requirements[productIndex]?.product
        ?.condition;
    setFieldValue(field, formatedFiledValue);
    setPosMappingStringValue(value);

    if (
      !hasConditionField &&
      values.conditionValue[indexItem]?.requirements[productIndex]?.product
        ?.products.length > 0
    ) {
      const consitionField = `conditionValue[${indexItem}].requirements[${productIndex}].product.condition`;
      setFieldValue(consitionField, 'AND');
    }
  };
  return (
    <Wrapper isLastItem={isLastItemInTheCard}>
      <FullWrapper>
        <div>
          <TitleMain>{title}</TitleMain>
          <SubtitleBlock>{subTitle}</SubtitleBlock>
        </div>
      </FullWrapper>
      <WrapperInside>
        <FullWrapper>
          <div className='formSection'>
            <NoteHead>Purchase requirements</NoteHead>
            <NoteText>
              Customer has to purchase product(s) to redeem this offer
            </NoteText>
          </div>
          <div>
            <MiniToggleSwitch
              name={'product'}
              label=''
              checked={productPropertyIsEnabled}
              onChange={(e) => {
                handleChange(e, 'product');
                setProductPropertyIsEnabled((prevState) => !prevState);
              }}
            ></MiniToggleSwitch>
          </div>
        </FullWrapper>

        {productPropertyIsEnabled &&
          ['mobile', 'web'].includes(
            values.conditionValue[indexItem]?.source
          ) && (
            <>
              {!isSourcePOS && (
                <CopyButtonWrapper>
                  <Button
                    onClick={() =>
                      handleCopyRequirements(
                        title === 'Web' ? 'mobile' : 'web',
                        indexItem
                      )
                    }
                  >
                    {values.conditionValue[indexItem]?.source === 'web'
                      ? 'Copy From Mobile'
                      : 'Copy From Web'}
                  </Button>
                </CopyButtonWrapper>
              )}
              <FilterButton
                message={
                  isAllProductsSelected
                    ? 'All Product Variants Selected'
                    : `Selected Product Variants (${values.conditionValue[indexItem]?.requirements[productIndex]?.product?.products.length})`
                }
                height='55px'
                setIsOpen={() =>
                  handleOpenProductVariantsModal(
                    `conditionValue[${indexItem}].requirements[${productIndex}].product.products`
                  )
                }
              />
              {isOpen && (
                <FilterProductVariantsModal
                  placeholder='Enter Product/Variants Name'
                  searchLabel='Search by Product/Variants Name'
                  filteredProducts={
                    values.conditionValue[indexItem]?.requirements[productIndex]
                      ?.product?.products
                  }
                  setFilteredProducts={setFieldValue}
                  isAllProductsSelected={isAllProductsSelected}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  formField={formKey}
                  emptyIsEqualToAll={false}
                  isEmptyAccepted={false}
                />
              )}
            </>
          )}
        {isSourcePOS && productPropertyIsEnabled && (
          <>
            <TextField
              multiline
              placeholder='Type POS IDs'
              name={`conditionValue[${indexItem}].requirements[${productIndex}].product.products`}
              value={posMappingStringValue}
              onChange={(e) =>
                handleChangePOSIDs(e, setFieldValue, indexItem, productIndex)
              }
              variant='outlined'
              style={{ width: '100%' }}
            />
            {values.conditionValue[indexItem]?.requirements[productIndex]
              ?.product?.products.length === 0 && (
              <ErrorSpan>Required</ErrorSpan>
            )}
          </>
        )}
        {values.conditionValue[indexItem]?.requirements[productIndex]?.product
          ?.products.length > 0 && (
          <WrapperInsideHead>
            <NoteTextBold>
              How many of the required products does the customer need to
              purchase to be eligible for the offer?
            </NoteTextBold>

            <RadioGroup
              aria-labelledby='radio-condition-group-label'
              value={
                values.conditionValue[indexItem].requirements[productIndex]
                  .product.condition
              }
              id='radio-condition-group'
              name='radio-condition-group'
              onChange={(event) => {
                setFieldValue(
                  `conditionValue[${indexItem}].requirements[${productIndex}].product.condition`,
                  event.target.value
                );
              }}
            >
              <FormControlLabel
                value='AND'
                control={<Radio />}
                label='All required products'
              />
              <FormControlLabel
                value='OR'
                control={<Radio />}
                label='At least one required product'
              />
            </RadioGroup>
          </WrapperInsideHead>
        )}
      </WrapperInside>
    </Wrapper>
  );
};
export default Requirement;
