import { getPurePayload } from '../../../../utils/purePayload';

export const renderMultiMappingOptions = () => {
  return [
    {
      id: 0,
      name: 'Products',
    },
    {
      id: 1,
      name: 'Items/Sub-Items',
    },
    {
      id: 2,
      name: 'Combos',
    },
    // {
    //   id: 5,
    //   name: 'Combo items group',
    // },
    {
      id: 3,
      name: 'Offers',
    },
    {
      id: 4,
      name: 'Credit Codes',
    },
  ];
};

export const getFirstColumnTitle = (categoryType) => {
  switch (categoryType) {
    case 'Products':
    case 'Items/Sub-Items':
      return 'Variant Name';
    case 'Combos':
      return 'Combo Name';
    case 'Offers':
      return 'Offer Name';
    case 'Credit Codes':
      return 'Codes';
    default:
      return '';
  }
};

export const getPOSPayloadType = (categoryType) => {
  switch (categoryType) {
    case 'Products':
      return 'products';
    case 'Items/Sub-Items':
      return 'item';
    case 'Combos':
      return 'combo';
    case 'Offers':
      return 'offer';
    case 'Credit Codes':
      return 'coupon';
    default:
      return '';
  }
};

export const getLocationsURL = (posType, categoryType, categoryId, itemId) => {
  switch (categoryType) {
    case 'products':
      return `/menu/products/${categoryId}/pos-mapping/provider/${posType}/businesses`;
    case 'item':
      return `/menu/options/${categoryId}/items/${itemId}/pos-mapping/provider/${posType}/businesses`;
    case 'combo':
    case 'offer':
    case 'coupon':
      return `/discount-mapping/${categoryId}/${categoryType}/provider/${posType}/businesses`;
    default:
      return '';
  }
};

export const getAddUpdateURL = (category, posType, posMappingId) => {
  const { categoryType, categoryId, subcategoryId } = category;
  let url = '';
  switch (categoryType) {
    case 'products':
      url = `/menu/${categoryType}/${categoryId}/pos-mapping/provider/${posType}`;
      if (posMappingId) {
        url = `/menu/${categoryType}/${categoryId}/pos-mapping/${posMappingId}/provider/${posType}`;
      }
      return url;
    case 'item':
      url = `/menu/options/${categoryId}/items/${subcategoryId}/pos-mapping/provider/${posType}`;
      if (posMappingId) {
        url = `/menu/options/${categoryId}/items/${subcategoryId}/pos-mapping/${posMappingId}/provider/${posType}`;
      }
      return url;
    case 'combo':
    case 'offer':
    case 'coupon':
      url = `/discount-mapping`;
      if (posMappingId) {
        url = `/discount-mapping/${categoryId}/${categoryType}`;
      }
      return url;
    case 'coupon':
      return '';
    default:
      return '';
  }
};

export const getAddPayload = (category, posMappings, posType, appId) => {
  switch (category) {
    case 'products':
      const posMappingPayload = posMappings?.map((mapping) => {
        const { id, ...rest } = mapping || {};
        return rest;
      });
      return { mappings: getPurePayload(posMappingPayload) };
    case 'item':
      const posMappingItemPayload = posMappings?.map((mapping) => {
        const { id, ...rest } = mapping || {};
        return getPurePayload(rest);
      });
      return { mappings: posMappingItemPayload };
    case 'combo':
      const comboMappings = posMappings?.map((mapping) => {
        const { id, ...rest } = mapping || {};
        return { ...rest, type: 'combo', pos_type: posType, app_id: appId };
      });
      return comboMappings;
    case 'offer':
      const offerMappings = posMappings?.map((mapping) => {
        const { id, ...rest } = mapping || {};
        return { ...rest, type: 'offer', pos_type: posType, app_id: appId };
      });
      return offerMappings;
    case 'coupon':
      const couponMappings = posMappings?.map((mapping) => {
        const { id, ...rest } = mapping || {};
        return { ...rest, type: 'coupon', pos_type: posType, app_id: appId };
      });
      return couponMappings;
    default:
      return '';
  }
};

export const getUpdatePayload = (category, payload, posType, appId) => {
  switch (category) {
    case 'products':
    case 'item':
      return payload;
    case 'combo':
    case 'offer':
    case 'coupon':
      return { ...payload, pos_type: posType, app_id: appId };
    default:
      return '';
  }
};

/////////////////// Overrides //////////////////////

export const getOverrideAddUpdateURL = (category, posType, posMappingId) => {
  const { categoryType, categoryId, subcategoryId, business_id } = category;
  let url = '';
  switch (categoryType) {
    case 'products':
      url = `/menu/${categoryType}/${categoryId}/pos-mapping/provider/${posType}/override`;
      if (posMappingId) {
        url = `/menu/${categoryType}/${categoryId}/pos-mapping/provider/${posType}/business/${business_id}/override/${posMappingId}`;
      }
      return url;
    case 'item':
      url = `/menu/options/${categoryId}/items/${subcategoryId}/pos-mapping/provider/${posType}/override`;
      if (posMappingId) {
        url = `/menu/options/${categoryId}/items/${subcategoryId}/pos-mapping/provider/${posType}/business/${business_id}/override/${posMappingId}`;
      }
      return url;
    case 'combo':
    case 'offer':
    case 'coupon':
      url = `/discount-mapping/${categoryId}/${categoryType}/provider/${posType}/override`;
      if (posMappingId) {
        url = `/discount-mapping/${categoryId}/${categoryType}/provider/${posType}/business/${business_id}/override`;
      }
      return url;
    default:
      return '';
  }
};

export const getOverrideDeleteURL = (category, posType, posMappingId) => {
  const { categoryType, categoryId, business_id, subcategoryId } = category;
  switch (categoryType) {
    case 'products':
      return `/menu/${categoryType}/${categoryId}/pos-mapping/provider/${posType}/business/${business_id}/override/${posMappingId}`;
    case 'item':
      return `/menu/options/${categoryId}/items/${subcategoryId}/pos-mapping/provider/${posType}/business/${business_id}/override/${posMappingId}`;
    case 'combo':
    case 'offer':
    case 'coupon':
      return `/discount-mapping/${categoryId}/${categoryType}/provider/${posType}/business/${business_id}/override`;
    default:
      return '';
  }
};

export const getAddOverridePayload = (business_ids, category, posMappings) => {
  switch (category) {
    case 'products':
      const posMappingPayload = posMappings?.map((mapping) => {
        const { id, ...rest } = mapping || {};
        return getPurePayload(rest);
      });
      return { business_ids, mappings: posMappingPayload };
    case 'item':
      const itemPosMappingPayload = posMappings?.map((mapping) => {
        const { id, ...rest } = mapping || {};
        return getPurePayload(rest);
      });
      return { business_ids, mappings: itemPosMappingPayload };
    case 'combo':
    case 'offer':
    case 'coupon':
      const { id, discount_id, ...rest } = posMappings?.[0] || {};
      return { business_ids, mappings: getPurePayload(rest) };
    default:
      return '';
  }
};
