import React, { useContext } from 'react';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { CustomizationContext } from '../../utils/ClientCustomizationsProvider';

const TableWrapper = styled.div`
  /* overflow-x: auto; */
  padding-bottom: 20px;
`;
const TdPadding = styled(Td)`
  width: ${(props) => props.width || 'auto'};
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: ${(props) => (props.isHeader ? 600 : 'normal')};
  font-size: ${(props) => (props.isHeader ? '16px' : 'normal')};
`;
const ProductName = styled.div`
  font-weight: 600;
`;
const VariantName = styled.div`
  font-size: 12px;
`;
const LinkButton = styled.div`
  font-size: 14px;
  color: #108ee9;
  cursor: pointer;
`;

const TdPaddingCapitalized = styled(Td)`
  text-transform: capitalize;
`;
const renderHeaders = (type) => {
  if (type === 'product') {
    return (
      <TrHead>
        <TdPadding align='center' width='130px' isHeader>
          Product ID
        </TdPadding>
        <TdPadding align='Left' width='450px' isHeader>
          Product Name
        </TdPadding>
        <TdPadding align='center' width='110px' isHeader>
          Is Active
        </TdPadding>
        <TdPadding align='center' isHeader>
          Link
        </TdPadding>
      </TrHead>
    );
  } else if (type === 'item') {
    return (
      <TrHead>
        <TdPadding align='center' width='130px' isHeader>
          Option ID
        </TdPadding>
        <TdPadding align='Left' width='450px' isHeader>
          Option / Sub-Option Name
        </TdPadding>
        <TdPadding align='center' width='110px' isHeader>
          Is Active
        </TdPadding>
        <TdPadding align='center' isHeader>
          Link
        </TdPadding>
      </TrHead>
    );
  } else if (type === 'combo') {
    return (
      <TrHead>
        <TdPadding align='center' width='130px' isHeader>
          Combo ID
        </TdPadding>
        <TdPadding align='Left' width='450px' isHeader>
          Combo Name
        </TdPadding>
        <TdPadding align='center' width='110px' isHeader>
          Is Active
        </TdPadding>
        <TdPadding align='center' isHeader>
          Link
        </TdPadding>
      </TrHead>
    );
  } else if (type === 'combo-item') {
    return (
      <TrHead>
        <TdPadding align='center' width='130px' isHeader>
          Combo Item Group ID
        </TdPadding>
        <TdPadding align='Left' width='450px' isHeader>
          Combo Item Group Name / Combo Name
        </TdPadding>
        <TdPadding align='center' width='110px' isHeader>
          Is Active
        </TdPadding>
        <TdPadding align='center' isHeader>
          Link
        </TdPadding>
      </TrHead>
    );
  }
};

const renderRows = (data, type, handleLinkToIssue) => {
  if (type === 'product') {
    return (
      <TBody striped>
        {data.map((item, index) => (
          <Tr key={index}>
            <TdPadding align='center' width='130px'>
              {item.product_id}
            </TdPadding>
            <TdPadding align='left' width='450px'>
              <ProductName>{item.product_name}</ProductName>
              {item.variants.map((variant) => (
                <VariantName>
                  {`Variant: ${variant.variant_name} - ${variant.variant_id}`}
                </VariantName>
              ))}
            </TdPadding>
            <TdPadding align='center' width='110px'>
              {item.is_active ? 'ACTIVE' : 'INACTIVE'}
            </TdPadding>
            <TdPadding align='center'>
              <LinkButton onClick={() => handleLinkToIssue(item)}>
                Link to issue
              </LinkButton>
            </TdPadding>
          </Tr>
        ))}
      </TBody>
    );
  } else if (type === 'item') {
    return (
      <TBody striped>
        {data.map((item, index) => (
          <Tr key={index}>
            <TdPadding align='center' width='130px'>
              {item.option_id}
            </TdPadding>
            <TdPadding align='left' width='450px'>
              {item.suboption_id
                ? `${item.option_name} / ${item.suboption_name} (${item.suboption_id})`
                : `${item.option_name}`}
            </TdPadding>
            <TdPadding align='center' width='110px'>
              {item.is_active ? 'ACTIVE' : 'INACTIVE'}
            </TdPadding>
            <TdPadding align='center'>
              <LinkButton onClick={() => handleLinkToIssue(item)}>
                Link to issue
              </LinkButton>
            </TdPadding>
          </Tr>
        ))}
      </TBody>
    );
  } else if (type === 'combo') {
    return (
      <TBody striped>
        {data.map((item, index) => (
          <Tr key={index}>
            <TdPadding align='center' width='130px'>
              {item.combo_id}
            </TdPadding>
            <TdPadding align='left' width='450px'>
              {item.combo_name}
            </TdPadding>
            <TdPadding align='center' width='110px'>
              {item.is_active ? 'ACTIVE' : 'INACTIVE'}
            </TdPadding>
            <TdPadding align='center'>
              <LinkButton onClick={() => handleLinkToIssue(item)}>
                Link to issue
              </LinkButton>
            </TdPadding>
          </Tr>
        ))}
      </TBody>
    );
  } else if (type === 'combo-item') {
    return (
      <TBody striped>
        {data.map((item, index) => (
          <Tr key={index}>
            <TdPadding align='center' width='130px'>
              {item.combo_item_group_id}
            </TdPadding>
            <TdPadding align='left' width='450px'>
              {`${item.combo_item_group_name} (${item.combo_name})`}
            </TdPadding>
            <TdPadding align='center' width='110px'>
              {item.combo_item_group_is_active ? 'ACTIVE' : 'INACTIVE'}
            </TdPadding>
            <TdPadding align='center'>
              <LinkButton onClick={() => handleLinkToIssue(item)}>
                Link to issue
              </LinkButton>
            </TdPadding>
          </Tr>
        ))}
      </TBody>
    );
  }
};
const RenderMissingTable = ({ data, type, history, handleCloseModal }) => {
  const { isMultiPOSMappingClient } = useContext(CustomizationContext);
  let path = '/pos-mapping';
  if (isMultiPOSMappingClient) {
    path = '/multi-pos-mapping';
  }
  const handleLinkToIssue = (item) => {
    if (type === 'product') {
      history.push({
        pathname: path,
        state: { ...item, type: type },
      });
    }
    if (type === 'item') {
      history.push({
        pathname: path,
        state: { ...item, type: type },
      });
    }
    if (type === 'combo') {
      history.push({
        pathname: path,
        state: { ...item, type: type },
      });
    }
    if (type === 'combo-item') {
      history.push({
        pathname: path,
        state: { ...item, type: type },
      });
    }
    handleCloseModal();
  };
  return (
    <TableWrapper>
      <Table>
        {renderHeaders(type)}
        {renderRows(data, type, handleLinkToIssue)}
      </Table>
    </TableWrapper>
  );
};

export default withRouter(RenderMissingTable);
