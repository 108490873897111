import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { ChargeBackData } from './Details/ChargeBackData';
import { ChargeBackOverview } from './Details/Overview';

const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  border-radius: 20px;
  margin-left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
  margin-top: 30px;
  box-shadow: 0px 0px 4px #d6cece;
  padding: 20px 30px;
  width: 100%;
`;

const TabsWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const TabButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #dadada;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  outline: none;
  transition: all 0.3s;
  background-color: ${(props) => (props.isActive ? '#E6EBFF' : '#ffffff')};
  width: ${(props) => props.width ?? 'auto'};
  &:hover {
    background-color: #e6ebff;
  }
`;
const Icon = styled.i`
  margin: 0;
  margin-left: 10px;
  color: #2440ae;
  font-size: 25px;
`;

const ChargbackTabs = ({ selectedTab, handleSelectTab }) => {
  return (
    <TabsWrapper>
      <TabButton
        isActive={selectedTab === 'chargeback'}
        width='240px'
        onClick={() => handleSelectTab('chargeback')}
      >
        Chargeback Data
        {selectedTab === 'chargeback' && (
          <Icon className='fa fa-check-circle' />
        )}
      </TabButton>
      <TabButton
        width='170px'
        isActive={selectedTab === 'overview'}
        onClick={() => handleSelectTab('overview')}
      >
        FAQs
        {selectedTab === 'overview' && <Icon className='fa fa-check-circle' />}
      </TabButton>
    </TabsWrapper>
  );
};

const ChargebacksContainer = ({}) => {
  const [selectedTab, setSelectedTab] = useState('chargeback');
  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <div>
      <BreadCrumb pageName='Chargebacks' />
      <ChargbackTabs
        selectedTab={selectedTab}
        handleSelectTab={handleSelectTab}
      />
      <Wrapper>
        {selectedTab === 'overview' ? (
          <ChargeBackOverview />
        ) : (
          <ChargeBackData />
        )}
      </Wrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  businesses: state.businessLocationsList.businessLocationsOfMerchant,
  accounts: state.rolesReducer.accounts,
  authUserRedux: state.profile.authUserRedux,
});

export default connect(mapStateToProps, {})(ChargebacksContainer);
