import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import PaginationControl from '../Pagination/PaginationControl';
import { TooltipIcon } from '../Icon';
import { Tooltip, Checkbox } from '@material-ui/core';
import _ from 'lodash';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const Wrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 100%;
`;

const PaginationWrapper = styled.div`
  padding: 25px 37px;
`;

const SortDiv = styled.div`
  padding: 0 40px 25px;
  text-align: right;
`;

const SortWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
`;
const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  /* max-width: 1200px; */
`;
const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`;
const THead = styled.thead`
  font-family: Montserrat;
  width: 100%;
  font-size: 15px;
  height: 80px;
`;
const TBody = styled.tbody`
  width: 100%;
  font-size: 14px;
`;
const TrHead = styled.tr`
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
`;
const Th = styled.th`
  padding: 10px;
  text-align: left;
  word-wrap: break-word;
`;
const Tr = styled.tr`
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e1e1e1;
  background-color: ${(props) => (props.hasBack ? '#f7f7fa' : 'transparent')};
  &:hover {
    background-color: #f1f1fa;
    cursor: pointer;
  }
`;
const InfoIcon = styled.i`
  /* color: #0349b1; */
`;
const Td = styled.td`
  text-align: left;
  padding-left: 10px;
  word-wrap: break-word;
`;
const Image = styled.img`
  width: 100%;
  background-color: #d3d3d3;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    width: '148px',
    marginRight: '15px;',
    backgroundColor: '#e6ebff',
    color: '#2440ae',
    height: '43px',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#d9e0ff',
    },
    '&:disabled': {
      backgroundColor: '#eff2ff',
      color: '#9facdf',
    },
  },
}));
function paginate(items, PageNumber, pageSize) {
  const startIndex = (PageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
}
const GenericTable = (props) => {
  const {
    dataList,
    columnHeaders,
    columnHeaderIsSortableList,
    columnNames,
    columnTypes,
    columnWidth,
    toggle,
    searchField,
    requestParams,
    handleClickRow,
    setIsSortModalOpen,
    requestParamsType,
    handleCheckboxSelect,
    exportHeaders,
    exprotData,
    exportFileName,
    totalCheckbox,
    setTotalCheckbox,
    checkboxList,
    setCheckboxList,
    setExportingData,
    isCustomerReport,
    updateAction,
    updateRequestParams,
    columnHeadersInfoIconMessages,
  } = props;
  const classes = useStyles();
  const [sortItem, setSortItem] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchText, setSearchText] = useState('');

  const [pageLength, setPageLength] = useState(1);
  const [currentList, setCurrentList] = useState([]);

  const activeList = () => {
    // filtering
    const filteredList =
      searchText === ''
        ? dataList
        : dataList.filter((item) =>
            item[searchField].toLowerCase().includes(searchText)
          );
    // sorting
    const sortedList = _.orderBy(filteredList, sortItem, sortOrder);
    // paginating
    const paginatedList = paginate(
      sortedList,
      requestParams.page_number,
      requestParams.page_size
    );
    setPageLength(filteredList.length);
    if (updateAction) {
      setCurrentList(dataList);
    } else {
      // local pagination control
      setCurrentList(paginatedList);
    }
    if (setExportingData) {
      setExportingData(paginatedList);
    }
  };
  useEffect(() => {
    if (columnTypes.includes('checkbox')) {
      const initialCheckboxList = dataList.map((data) => {
        return false;
      });
      setCheckboxList(initialCheckboxList);
      setTotalCheckbox(false);
    }
    activeList();
  }, [dataList]);
  useEffect(() => {
    activeList();
  }, [requestParams]);
  const getColumnData = (i) => {
    let filteredList = currentList.filter((item, index) => index === i)[0];
    let list = [];
    columnNames.forEach((item) => {
      if (item === 'checkbox') {
        list.push('checkbox');
      } else {
        list.push(filteredList[item]);
      }
    });
    return list;
  };
  const uniqueRowIndex = (rowIndex) => {
    const startIndex =
      (requestParams.page_number - 1) * parseInt(requestParams.page_size);
    const uniqueIndex =
      dataList.length > parseInt(requestParams.page_size)
        ? startIndex + rowIndex
        : rowIndex;
    return uniqueIndex;
  };
  const handleSelectCheckbox = (rowIndex) => {
    const uniqeRowIndex = uniqueRowIndex(rowIndex);
    let currentCheckboxList = [];
    checkboxList.forEach((item) => {
      currentCheckboxList.push(item);
    });
    if (rowIndex !== -1) {
      const currentStatus = checkboxList[uniqeRowIndex];
      currentCheckboxList[uniqeRowIndex] = !currentStatus;
      let countChecked = 0;
      currentCheckboxList.forEach((item) => {
        if (item) {
          countChecked += 1;
        }
      });
      if (countChecked === checkboxList.length) {
        setTotalCheckbox(true);
      } else {
        setTotalCheckbox(false);
      }
    } else {
      currentCheckboxList = [];
      setTotalCheckbox((prevState) => !prevState);
      if (!totalCheckbox) {
        checkboxList.forEach(() => {
          currentCheckboxList.push(true);
        });
      } else {
        checkboxList.forEach(() => {
          currentCheckboxList.push(false);
        });
      }
    }
    setCheckboxList(currentCheckboxList);
    handleCheckboxSelect(currentCheckboxList);
    activeList();
  };
  const generateField = (value, colIndex, rowIndex) => {
    let currentPageChckboxList = [];
    if (dataList.length > parseInt(requestParams.page_size)) {
      const startIndex =
        (requestParams.page_number - 1) * parseInt(requestParams.page_size);
      checkboxList.forEach((status, index) => {
        if (
          index >= startIndex &&
          index <= startIndex + parseInt(requestParams.page_size) - 1
        ) {
          currentPageChckboxList.push(status);
        }
      });
    } else {
      currentPageChckboxList = checkboxList;
    }
    let type = columnTypes[colIndex];
    switch (type) {
      case 'checkbox':
        return (
          <Checkbox
            checked={currentPageChckboxList[rowIndex]}
            onChange={() => handleSelectCheckbox(rowIndex)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            color='primary'
          />
        );
      case 'url':
        return <Image src={value} alt={''} />;
      case 'money':
        return '$' + value.toFixed(2);
      case 'percent':
        return value + '%';
      case 'string':
        return value;
      case 'datetime':
        return moment(value).format('MM/DD/YYYY hh:mm A');
      case 'date':
        return moment(value).format('MM/DD/YYYY');
      case 'download':
        return value.isDownloadButtonEnabled && value.downloadButton;
      case 'toggle':
        return (
          <Switch
            checked={value}
            onChange={() => toggle(currentList[rowIndex], !value)}
            onClick={(e) => e.stopPropagation()}
            value='checkedB'
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        );
      default:
        return value;
    }
  };
  useEffect(() => {
    activeList();
  }, [sortOrder, sortItem]);
  const handleSorting = (index) => {
    if (!columnHeaderIsSortableList[index]) {
      return;
    }
    setSortItem(columnNames[index]);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const displaySortIcons = (index) => {
    if (sortItem === columnNames[index]) {
      return (
        <span className='fa-stack fa-1x'>
          {sortOrder === 'asc' && (
            <i className='fa fa-sort-up fa-stack-1x' aria-hidden='true' />
          )}
          {sortOrder === 'desc' && (
            <i className='fa fa-sort-down fa-stack-1x' aria-hidden='true' />
          )}
        </span>
      );
    } else {
      return (
        <span className='fa-stack fa-1x'>
          <i className='fa fa-sort-up fa-stack-1x' aria-hidden='true' />
          <i className='fa fa-sort-down fa-stack-1x' aria-hidden='true' />
        </span>
      );
    }
  };
  return (
    <Wrapper>
      {setIsSortModalOpen !== undefined && pageLength > 1 && (
        <SortDiv>
          <SortWrapper onClick={() => setIsSortModalOpen(true)}>
            <i className='far fa-sort-amount-down' /> Reorder Combos
            <Tooltip
              style={{ marginRight: 'auto' }}
              title='Edit the position of combos to change the order that they are presented in to the user'
            >
              <span>
                <TooltipIcon marginLeft />
              </span>
            </Tooltip>
          </SortWrapper>
        </SortDiv>
      )}
      <TableWrapper>
        <Table>
          <THead>
            <TrHead>
              {columnHeaders.map((headerTitle, index) => (
                <Th
                  // width={columnWidth[index]}
                  key={index}
                  onClick={() => handleSorting(index)}
                  style={{
                    textAlign: headerTitle !== 'export' ? 'left' : 'right',
                    width: columnWidth[index],
                    position: index === 0 ? 'sticky' : 'static',
                    // position: index === 0 ? '-webkit-sticky' : 'static',
                    left: index === 0 ? 0 : 'none',
                    zIndex: index === 0 ? 2 : 0,
                    backgroundColor: 'white',
                  }}
                >
                  {headerTitle === 'checkbox' ? (
                    <Checkbox
                      checked={totalCheckbox}
                      onChange={() => handleSelectCheckbox(-1)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      color='primary'
                    />
                  ) : headerTitle === 'export' ? (
                    <CSVLink
                      data={exprotData || []}
                      headers={exportHeaders}
                      filename={`${exportFileName}.csv`}
                      disabled={!checkboxList?.includes(true)}
                    >
                      <Button
                        className={classes.button}
                        disabled={!checkboxList?.includes(true)}
                      >
                        Export as CSV
                      </Button>
                    </CSVLink>
                  ) : (
                    headerTitle
                  )}
                  {columnHeaderIsSortableList[index] && (
                    <Tooltip title='sort'>{displaySortIcons(index)}</Tooltip>
                  )}
                  {columnHeadersInfoIconMessages &&
                    columnHeadersInfoIconMessages[index] && (
                      <Tooltip title={columnHeadersInfoIconMessages[index]}>
                        <InfoIcon className='fal fa-info-circle'></InfoIcon>
                      </Tooltip>
                    )}
                </Th>
              ))}
            </TrHead>
          </THead>
          <TBody>
            {currentList.map((item, rowIndex) => (
              <Tr key={rowIndex} hasBack={checkboxList[rowIndex]}>
                {getColumnData(rowIndex).map((val, colIndex) =>
                  val === 'checkbox' ? (
                    <Td key={colIndex} width={columnWidth[colIndex]}>
                      {generateField(val, colIndex, rowIndex)}
                    </Td>
                  ) : (
                    <Td
                      key={colIndex}
                      style={{
                        width: columnWidth[colIndex],
                        position: colIndex === 0 ? 'sticky' : 'static',
                        // position: colIndex === 0 ? '-webkit-sticky' : 'static',
                        left: colIndex === 0 ? 0 : 'none',
                        zIndex: colIndex === 0 ? 2 : 0,
                        backgroundColor: 'white',
                      }}
                      // width={columnWidth[colIndex]}
                      onClick={() => handleClickRow?.(currentList[rowIndex])}
                    >
                      {generateField(val, colIndex, rowIndex)}
                    </Td>
                  )
                )}
              </Tr>
            ))}
          </TBody>
        </Table>
      </TableWrapper>
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...currentList,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: pageLength,
          }}
          requestParams={requestParams}
          type={requestParamsType}
          isCustomerReport={isCustomerReport}
          updateAction={updateAction || null}
          updateRequestParams={updateRequestParams || null}
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

export default GenericTable;
